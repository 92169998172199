import { useNavigate } from "react-router-dom";
import { Puzzle } from "../data/puzzles";
import DualSongControl from "./DualSongControl";
import HamburgerMenu from "./HamburgerMenu";
import LoginStatusBar from "./LoginStatusBar";

/*
TopNavbar contains a hamburger menu, dual song control, and auth login
*/
interface TopNavbarProps {
  puzzle: Puzzle | null;
  playing: boolean;
  setPlaying: (playing: boolean) => void;
  blurred: boolean;
  resetGame?: () => void;
}
const TopNavbar = ({
  puzzle,
  playing,
  setPlaying,
  blurred,
  resetGame,
}: TopNavbarProps) => {
  const navigate = useNavigate();

  const menuItems = [
    [
      { label: "Play", onClick: () => navigate("/rumble") },
      { label: "About", onClick: () => navigate("/rumble/about") },
    ],
    [
      ...(resetGame
        ? [{ label: "End game in progress", onClick: resetGame }]
        : []),
      { label: "Play Pindrop Daily", onClick: () => navigate("/daily") },
    ],
  ];

  return (
    <div className="flex flex-row w-full justify-between">
      <div className="pt-6 pl-2 md:pl-8 w-1/12 sm:w-1/5">
        <HamburgerMenu items={menuItems} size="xl" />
      </div>
      <div className="w-10/12 sm:w-3/5">
        {puzzle && (
          <DualSongControl
            puzzle={puzzle}
            playing={playing}
            setPlaying={setPlaying}
            blurred={blurred}
          />
        )}
      </div>
      <div className="pt-4 md:pr-6 w-1/12 sm:w-1/5 flex justify-end items-start">
        <LoginStatusBar />
      </div>
    </div>
  );
};

export default TopNavbar;
