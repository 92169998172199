import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import UserIcon from "./Icon/UserIcon";
import Button from "./basic/Button";
import AuthModal from "./AuthModal";
import { useUsername } from "../state/UsernameContext";

/*
Component to show a user's logged in / not logged in status.
When clicked, opens LoginModal. Also checks for URL query param to show modal.
*/
const LoginStatusBar = () => {
  const { username, setUsername } = useUsername();
  const [showLoginModal, setShowLoginModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("showLogin")) {
      setShowLoginModal(true);
    }
  }, [location.search]);

  const handleCloseModal = () => {
    setShowLoginModal(false);
    const params = new URLSearchParams(location.search);
    if (params.get("showLogin")) {
      params.delete("showLogin");
    }
  };

  return (
    <>
      <Button
        color="transparent"
        onClick={() => setShowLoginModal(true)}
        className="p-0"
      >
        <div className="flex items-center text-gray-500">
          <div className="hidden sm:block mr-2">{username || "Log in"}</div>
          <UserIcon size="xl" />
        </div>
      </Button>
      <AuthModal
        isOpen={showLoginModal}
        onClose={handleCloseModal}
        username={username}
        setUsername={setUsername}
      />
    </>
  );
};

export default LoginStatusBar;
