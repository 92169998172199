import { useEffect, useState } from "react";
import {
  LeaderboardStats,
  getLeaderboard,
} from "../../../services/perfect_scores";
import Spinner from "../../../components/Spinner";
import FundraiserThermometer from "./FundraiserThermometer";
import { TotalRaised } from "./PlayerStats";
import { completionsToDollars } from "./utils";
import { useUsername } from "../../../state/UsernameContext";
import Button from "../../../components/basic/Button";
import { Link } from "react-router-dom";
import ContinentBreakdown, {
  artistIdsToContinentBreakdownStats,
} from "./ContinentBreakdown";

const GlobalLeaderboard = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardStats | null>(null);
  const { username } = useUsername();

  const fetchLeaderboard = async () => {
    try {
      const leaderboard = await getLeaderboard();
      setLeaderboard(leaderboard);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  if (!leaderboard) {
    return (
      <div className="flex flex-col space-y-2 pt-4 items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2 items-center">
      <TotalRaised numCompletions={leaderboard.artists_completed.length} />
      <div className="py-4">
        <FundraiserThermometer value={leaderboard.artists_completed.length} />
      </div>
      {/* Explanatory blurb */}
      <div className="flex flex-col space-y-2 sm:flex-row items-center sm:space-x-4 sm:space-y-0 px-2 pb-6 sm:pt-4">
        <div className="flex flex-col items-start space-y-1">
          <div className="text-sm sm:text-base text-gray-400">
            Drop a pin exactly on the artist's city to get a perfect score.
          </div>
          <div className="text-sm sm:text-base text-gray-400">
            More perfect scores = more money raised for{" "}
            <a
              href="https://www.globalartslive.org/content/mission-impact"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-white"
            >
              <b>Global Arts Live.</b>
            </a>
          </div>
          <div className="text-sm sm:text-base text-gray-400">
            All players work together to perfect every artist!
          </div>
        </div>
        <div className="flex flex-col items-center space-y-2">
          {!username && (
            <Link to="/rumble?showLogin=true">
              <Button color="light">Log in to participate</Button>
            </Link>
          )}
          <Link to="/rumble/about">
            <Button color="dark">Learn more</Button>
          </Link>
        </div>
      </div>
      <div className="flex flex-col items-center space-y-4 md:flex-row md:space-x-4 md:space-y-0 md:items-start">
        <ContinentScores leaderboard={leaderboard} />
        <LeaderboardTable leaderboard={leaderboard} />
      </div>
    </div>
  );
};

export const ContinentScores = ({
  leaderboard,
}: {
  leaderboard: LeaderboardStats;
}) => {
  return (
    <div className="flex flex-col items-center w-80 sm:w-[500px]">
      {/* <div className="text-2xl mb-2">Region Breakdown</div> */}
      <ContinentBreakdown
        continentBreakdown={artistIdsToContinentBreakdownStats(
          leaderboard.artists_completed
        )}
      />
    </div>
  );
};

export const LeaderboardTable = ({
  leaderboard,
}: {
  leaderboard: LeaderboardStats;
}) => {
  const { username } = useUsername();

  return (
    <div className="flex flex-col items-center w-80 sm:w-[500px]">
      <div className="text-2xl mb-2">Leaderboard</div>
      <table>
        <tbody>
          {leaderboard.leaderboard.map((player, index) => (
            <tr
              key={player.username}
              className={player.username === username ? "font-semibold" : ""}
            >
              <td
                className={`w-8 ${
                  player.username === username ? "text-white" : "text-gray-400"
                }`}
              >
                {index + 1}
              </td>
              <td
                className={`min-w-48 ${
                  player.username === username ? "text-white" : "text-gray-400"
                }`}
              >
                {player.username}
              </td>
              <td>
                <span className="mr-2">{player.num_completed} perfect</span>
                <span className="text-gray-400 text-xs">
                  (${completionsToDollars(player.num_completed).toFixed(2)}{" "}
                  raised)
                </span>
              </td>
            </tr>
          ))}
          {/* If user does not show up in top 10, show an additional row below for them */}
          {username &&
            leaderboard.user_leaderboard &&
            leaderboard.leaderboard.every(
              (player) => player.username !== username
            ) && (
              <>
                <tr>
                  <td className="w-8 text-gray-400"></td>
                  <td className="text-gray-400">...</td>
                  <td className="text-gray-400"></td>
                </tr>
                <tr>
                  <td className="w-8 text-gray-400">
                    {leaderboard.user_leaderboard.rank || "-"}
                  </td>
                  <td className="text-gray-400">
                    {leaderboard.user_leaderboard.username}
                  </td>
                  <td className="text-gray-400">
                    <span className="mr-2">
                      {leaderboard.user_leaderboard.num_completed} perfect
                    </span>
                    <span className="text-gray-400 text-xs">
                      ($
                      {completionsToDollars(
                        leaderboard.user_leaderboard.num_completed
                      ).toFixed(2)}{" "}
                      raised)
                    </span>
                  </td>
                </tr>
              </>
            )}
        </tbody>
      </table>
    </div>
  );
};

export default GlobalLeaderboard;
