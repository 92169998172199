import { Puzzle } from "../../data/puzzles";
import ShareButton from "./ShareButton";
import BioPanel from "./BioPanel";
import TrackInfo from "./TrackInfo";
import ArtistLinks from "./ArtistLinks";
import Modal from "../basic/Modal";
import NeonText, { NeonColor } from "../basic/NeonText";
import { GameStatus } from "../../pages/DailyGame";
import Divider from "../basic/Divider";
import Button from "../basic/Button";
import GuessDistributionChart from "./GuessDistributionChart";
import { MAX_GUESSES } from "../../constants";
import hiddenGemsLogo from "../../img/hidden_gems_logo_animated.gif";
import NickShouldersItineraryInfo from "./NickShouldersItineraryInfo";

/*
Changes compared to normal AnswerModal component:
- Show Hidden Gems logo next to location
- Show itinerary info above Game record & share button
*/

interface HiddenGemsDemoAnswerModalProps {
  puzzle: Puzzle;
  isOpen: boolean;
  onClose: () => void;
  emojiStory?: string;
  numGuesses?: number;
  gameStatus?: GameStatus;
  scoreDistribution?: number[];
}
const HiddenGemsDemoAnswerModal = ({
  puzzle,
  isOpen,
  onClose,
  emojiStory,
  numGuesses,
  gameStatus,
  scoreDistribution,
}: HiddenGemsDemoAnswerModalProps) => {
  const img = puzzle.artist_image;
  const name = puzzle.artist_name;
  const location = puzzle.artist_location;

  // give user a score of MAX_GUESSES + 1 if they lost
  let adjustedUserGuesses = 0;
  if (numGuesses && gameStatus && scoreDistribution) {
    adjustedUserGuesses =
      gameStatus === GameStatus.WON ? numGuesses : MAX_GUESSES + 1;
  }

  let scoreDistroWithUser: number[] = [];
  if (scoreDistribution) {
    scoreDistroWithUser = [...scoreDistribution];
    scoreDistroWithUser[adjustedUserGuesses - 1] += 1; // Add the user's score to graph (1-indexed)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="lg:max-w-4xl">
      <div className="flex flex-col space-y-4 p-4 items-center">
        {/* Artist name */}
        <NeonText color={NeonColor.ORANGE} className="text-3xl">
          {name}
        </NeonText>
        {/* Artist location */}
        <div className="flex flex-row items-center">
          <div className="text-2xl font-medium">{location}</div>
          <img
            src={hiddenGemsLogo}
            alt="Hidden Gems logo"
            width={100}
            height={100}
            className="ml-2"
          />
        </div>
        <NickShouldersItineraryInfo />
        {emojiStory && gameStatus && numGuesses && scoreDistribution && (
          <div className="flex flex-col space-y-4 items-center">
            {/* Game record & share button */}
            <div className="flex flex-row items-center space-x-4 sm:space-x-8">
              <div className="text-xl">
                {gameStatus === GameStatus.LOST
                  ? "Hard puzzle today?"
                  : numGuesses === 1
                  ? "Hole in one 🎯"
                  : `in ${numGuesses} attempts`}
              </div>
              <ShareButton puzzle={puzzle} emojiStory={emojiStory} />
            </div>
            {/* Today's leaderboard */}
            <div className="h-36">
              <GuessDistributionChart
                scoreDistribution={scoreDistroWithUser}
                userScore={adjustedUserGuesses}
                title="Today's leaderboard"
              />
            </div>
          </div>
        )}
        <div
          className="flex flex-col space-y-4 items-center
  md:flex-row md:space-x-8 md:space-y-0 md:items-start md:pt-4
"
        >
          {/* Artist image */}
          <div
            className="rounded-lg overflow-hidden max-w-64 md:max-w-none
    md:basis-1/2 lg:basis-2/5 md:sticky md:top-16"
          >
            <img
              src={img}
              alt={name}
              className="w-full"
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="flex flex-col space-y-4 items-center md:basis-1/2 lg:basis-3/5">
            {/* Artist links */}
            <ArtistLinks puzzle={puzzle} />
            <Divider />
            {/* Track info */}
            <TrackInfo puzzle={puzzle} />
            <Divider />
            {/* Bio */}
            <BioPanel puzzle={puzzle} />
            {/* Close button */}
            <Button onClick={onClose} color="dark">
              Back
            </Button>
            {/* Come back tomorrow */}
            {gameStatus && (
              <div className="text-sm pt-4">
                Come back tomorrow for a new puzzle!
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HiddenGemsDemoAnswerModal;
