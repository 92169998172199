import { useEffect, useMemo, useState } from "react";
import {
  Puzzle,
  fetchRandomHiddenGemsPuzzle,
  fetchRandomPuzzle,
} from "../../data/puzzles";
import { GameMode, registerVisit } from "../../services/user_tracking";
import Spinner from "../../components/Spinner";
import Cookies from "js-cookie";
import { CoordinatePair } from "../../components/GuessCityPanel/location";
import { NeonColor } from "../../components/basic/NeonText";
import { v4 as uuidv4 } from "uuid";
import {
  currentPuzzleFromCookieGeoGameRecord,
  playerRecordsFromCookieGeoGameRecord,
  playerRecordsToCookiePlayerRecords,
} from "../../services/cookies";
import SpotifyWebApi from "spotify-web-api-js";
import TopNavbar from "../../components/TopNavbar";
import PlayGeoGame from "./PlayGeoGame";
import SetPlayersStep from "./SetPlayers";
import FundraiserChallenge from "./FundraiserChallenge";
import { Route, Routes } from "react-router-dom";
import About from "./About";

export const TOTAL_RUMBLE_ROUNDS = 5;

export const PLAYER_IDX_TO_COLOR = [
  NeonColor.PINK,
  NeonColor.BLUE,
  NeonColor.YELLOW,
  NeonColor.ORANGE,
];

/* Records - cookie version has just artist_spotify_id, not full puzzle object */
interface BaseRoundRecord {
  guess: CoordinatePair;
  distance: number;
  points: number;
}

export interface GeoGameRoundRecord extends BaseRoundRecord {
  puzzle: Puzzle;
}

export interface CookieGeoGameRoundRecord extends BaseRoundRecord {
  artistSpotifyId: string;
}

interface BasePlayerRecords {
  name: string;
}

export interface GeoGamePlayerRecords extends BasePlayerRecords {
  roundRecords: GeoGameRoundRecord[];
}

export interface CookieGeoGamePlayerRecords extends BasePlayerRecords {
  roundRecords: CookieGeoGameRoundRecord[];
}

export interface CookieGeoGameRecord {
  gameId: string;
  currentArtistSpotifyId: string;
  playerRecords: CookieGeoGamePlayerRecords[];
  players: string[];
  roundNumber: number;
}

/*
Master component for entire Rumble segment, including top navbar, all included
pages, etc.
Top level is responsible for most state control. In the future we may want to migrate
this to Redux or similar.
*/
const GeoGame = ({ hiddenGemsMode }: { hiddenGemsMode?: boolean }) => {
  /* Spotify login */
  const [token, setToken] = useState("");
  const [spotifyUser, setSpotifyUser] =
    useState<SpotifyApi.CurrentUsersProfileResponse | null>(null);

  // Check for Spotify token
  const spotify = useMemo(() => new SpotifyWebApi(), []);
  useEffect(() => {
    const hash = window.location.hash;
    let _token = window.localStorage.getItem("token");

    if (!_token && hash) {
      let abc = hash.substring(1).split("&")
        ? hash.substring(1).split("&")
        : [];
      let def = abc.find((elem) => elem.startsWith("access_token"));
      _token = def ? def.split("=")[1] : "";

      console.log(`Spotify token: ${_token}`);

      window.location.hash = "";
      window.localStorage.setItem("token", _token);
    }
    setToken(_token ? _token : "");
    spotify.setAccessToken(_token);
    spotify.getMe().then(
      (user: SpotifyApi.CurrentUsersProfileResponse) => {
        console.log("Spotify User data", user);
        setSpotifyUser(user);
      },
      () => {
        // If the token is expired, it will reject with HTTP 401
        console.log("Spotify token expired");
        setSpotifyUser({} as SpotifyApi.CurrentUsersProfileResponse);
        setToken("");
        window.localStorage.removeItem("token");
      }
    );
  }, [spotify]);

  const [puzzle, setPuzzle] = useState<Puzzle | null>(null);
  const [getPuzzleError, setGetPuzzleError] = useState<null | string>(null);
  const [musicPlaying, setMusicPlaying] = useState(false); // intention to play or not
  const [showIntroModal, setShowIntroModal] = useState(false);
  const [showRefreshedModal, setShowRefreshedModal] = useState(false);

  const [roundNumber, setRoundNumber] = useState(0); // [1, 2, ..., TOTAL_RUMBLE_ROUNDS]
  /* Cookie-based state */
  const [roundRecords, setRoundRecords] = useState<GeoGamePlayerRecords[]>([]);
  const [players, setPlayers] = useState<string[]>([]);
  const [gameId, setGameId] = useState<string>("");

  const resetGame = () => {
    setRoundNumber(0);
    setRoundRecords([]);
    setPlayers([]);
    setGameId(uuidv4());
  };

  const fetchAndSetPuzzle = async () => {
    if (hiddenGemsMode) {
      fetchRandomHiddenGemsPuzzle([])
        .then((dailyPuzzle) => {
          setPuzzle(dailyPuzzle as unknown as Puzzle);
          setGetPuzzleError(null);
        })
        .catch((error) => {
          setGetPuzzleError(
            "The Spotify player service looks like it's down right now. Please try again later."
          );
        });
    } else {
      fetchRandomPuzzle([])
        .then((dailyPuzzle) => {
          setPuzzle(dailyPuzzle as unknown as Puzzle);
          setGetPuzzleError(null);
        })
        .catch((error) => {
          setGetPuzzleError(
            "The Spotify player service looks like it's down right now. Please try again later."
          );
        });
    }
  };

  // Grab first puzzle
  useEffect(() => {
    const gameRecord: CookieGeoGameRecord = JSON.parse(
      Cookies.get("geoGameRecord") || "{}"
    );
    if (!gameRecord?.currentArtistSpotifyId) fetchAndSetPuzzle();
  }, []);

  // Register a new visit on each page load
  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      registerVisit({ game_mode: GameMode.GEO });
  }, []);

  // On first loading page, show either IntroModal or RefreshedModal
  // based on if refreshed_at query param exists and is less than 10 seconds ago
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refreshed_at = urlParams.get("refreshed_at");
    if (refreshed_at && Date.now() - parseInt(refreshed_at) < 10000) {
      setShowRefreshedModal(true);
    } else {
      setShowIntroModal(true);
      // remove any refreshed_at query param
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  // Read cookies - load game-in-progress if exists
  useEffect(() => {
    const gameRecord: CookieGeoGameRecord = JSON.parse(
      Cookies.get("geoGameRecord") || "{}"
    );

    if (gameRecord?.playerRecords) {
      playerRecordsFromCookieGeoGameRecord(gameRecord).then((playerRecords) => {
        setRoundRecords(playerRecords);
      });
    }
    if (gameRecord?.roundNumber) {
      setRoundNumber(gameRecord?.roundNumber || 1);
    }
    if (gameRecord?.currentArtistSpotifyId) {
      currentPuzzleFromCookieGeoGameRecord(gameRecord).then((puzzle) =>
        setPuzzle(puzzle)
      );
    }
    if (gameRecord?.players) {
      setPlayers(gameRecord.players);
    }
    if (gameRecord?.gameId) {
      setGameId(gameRecord.gameId);
    } else {
      setGameId(uuidv4());
    }
  }, []);

  // When game status changes, update game-in-progress cookies
  useEffect(() => {
    if (!puzzle?.artist_name) return;

    const newRecord: CookieGeoGameRecord = {
      currentArtistSpotifyId: puzzle.artist_spotify_id,
      playerRecords: playerRecordsToCookiePlayerRecords(roundRecords),
      roundNumber,
      players,
      gameId,
    };
    Cookies.set("geoGameRecord", JSON.stringify(newRecord));
  }, [puzzle, players, roundRecords, gameId, roundNumber]);

  const currentRoundFinished =
    (roundRecords[0]?.roundRecords.length || 0) === roundNumber;

  if (!puzzle?.artist_spotify_id)
    return (
      <div className="flex flex-col items-center mt-10 space-y-10">
        {getPuzzleError && (
          <div className="px-8 text-center">{getPuzzleError}</div>
        )}
        <Spinner />
      </div>
    );

  const IndexPage = () => {
    return (
      <>
        {!players.length && (
          <SetPlayersStep
            players={players}
            setPlayers={setPlayers}
            fetchRandomPuzzle={fetchAndSetPuzzle}
            setRoundNumber={setRoundNumber}
          />
        )}
        {!!players.length && (
          <PlayGeoGame
            players={players}
            roundRecords={roundRecords}
            setRoundRecords={setRoundRecords}
            puzzle={puzzle}
            setPuzzle={setPuzzle}
            currentRoundFinished={currentRoundFinished}
            roundNumber={roundNumber}
            setRoundNumber={setRoundNumber}
            setMusicPlaying={setMusicPlaying}
            spotifyUser={spotifyUser}
            token={token}
            gameId={gameId}
            setGetPuzzleError={setGetPuzzleError}
            setPlayers={setPlayers}
            setToken={setToken}
            setGameId={setGameId}
            showIntroModal={showIntroModal}
            setShowIntroModal={setShowIntroModal}
            showRefreshedModal={showRefreshedModal}
            setShowRefreshedModal={setShowRefreshedModal}
            resetGame={resetGame}
            hiddenGemsMode={hiddenGemsMode}
          />
        )}
      </>
    );
  };

  return (
    <div>
      <TopNavbar
        puzzle={puzzle}
        playing={musicPlaying}
        setPlaying={setMusicPlaying}
        blurred={!currentRoundFinished}
        resetGame={roundNumber > 0 ? resetGame : undefined}
      />
      <Routes>
        <Route index element={<IndexPage />} />
        <Route path="/about" element={<About />} />
        <Route path="/fundraiser/*" element={<FundraiserChallenge />} />
      </Routes>
    </div>
  );
};

export default GeoGame;
