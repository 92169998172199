import { useEffect, useState } from "react";
import { Puzzle } from "../data/puzzles";
import Spinner from "./Spinner";
import SpotifyTrackPlayer from "./SpotifyTrackPlayer";
import IconButton from "./basic/IconButton";
import PauseIcon from "./Icon/PauseIcon";
import { NeonColor } from "./basic/NeonText";
import PlayIcon from "./Icon/PlayIcon";
import RewindIcon from "./Icon/RewindIcon";
import Button from "./basic/Button";
import RefreshIcon from "./Icon/RefreshIcon";
import { setPlayerToUseCookie } from "./DualSongControl";
import AlertTriangleIcon from "./Icon/AlertTriangleIcon";

interface SongControlProps {
  puzzle: Puzzle;
  playing: boolean;
  setPlaying: (playing: boolean) => void;
}
const SongControl = ({ puzzle, playing, setPlaying }: SongControlProps) => {
  const [currentSong, setCurrentSong] = useState(puzzle.song_spotify_id);
  const [isActuallyPlaying, setIsActuallyPlaying] = useState(false); // is it actually loaded and playing music
  const [triggerReloadIfStuck, setTriggerReloadIfStuck] = useState(false); // trigger reload if it's stuck
  const [showRefreshButton, setShowRefreshButton] = useState(false); // show refresh button if it's stuck

  const handlePlayButtonClick = () => {
    setPlaying(!playing);
  };

  const handleRewindButtonClick = () => {
    setPlaying(false);
    setIsActuallyPlaying(false);
    setCurrentSong("");
    setTimeout(() => {
      setCurrentSong(puzzle.song_spotify_id);
      setPlaying(true);
    }, 500);
  };

  /* Handle automatically reloading if Spotify player gets stuck loading */
  useEffect(() => {
    /*
    Utility function that Spotify player calls some seconds after it claims to be ready.
    If it's not actually playing yet, reload the song.
    */
    const reloadIfStuck = () => {
      if (playing && !isActuallyPlaying) {
        const song = currentSong;
        setCurrentSong("");
        setTimeout(() => {
          setCurrentSong(song);
        }, 100);
        setShowRefreshButton(true);
      }
    };

    if (triggerReloadIfStuck) {
      reloadIfStuck();
      setTriggerReloadIfStuck(false);
    }
  }, [triggerReloadIfStuck, isActuallyPlaying, currentSong, playing]);

  /* Switch the currentSong if the puzzle changes */
  useEffect(() => {
    setCurrentSong(puzzle.song_spotify_id);
    setShowRefreshButton(false);
    setPlaying(false);
    setIsActuallyPlaying(false);
  }, [puzzle, setPlaying]);

  return (
    <div className="flex flex-col items-center">
      {/* Invisible player */}
      <div className="invisible">
        <SpotifyTrackPlayer
          width={"1"}
          height={"1"}
          uri={currentSong ? `spotify:track:${currentSong}` : undefined}
          playing={playing}
          onReady={(controller: any) => {
            /* within this block, cannot read state changes, can only write state changes */
            setTimeout(() => {
              setTriggerReloadIfStuck(true);
            }, 5000); // reload if it's not actually playing after 5 seconds
            // setPlaying(true); // autoplay on load
          }}
          setIsActuallyPlaying={setIsActuallyPlaying}
        />
      </div>

      <div className="p-4 flex-row space-x-4 items-center">
        {/* Play/pause button */}
        <IconButton
          onClick={handlePlayButtonClick}
          disabled={playing && !isActuallyPlaying}
        >
          <div className="text-lg">
            {playing ? (
              isActuallyPlaying ? (
                <PauseIcon size="xl" neonColor={NeonColor.PINK} />
              ) : (
                <Spinner />
              )
            ) : (
              <PlayIcon size="xl" neonColor={NeonColor.PINK} />
            )}
          </div>
        </IconButton>
        {/* Rewind button */}
        <IconButton onClick={handleRewindButtonClick}>
          <RewindIcon size="xl" neonColor={NeonColor.BLUE} />
        </IconButton>
      </div>

      {/* Refresh page button - if it gets stuck loading */}
      {showRefreshButton && (
        <div className="text-gray-400 text-sm flex flex-col space-y-2">
          <Button
            onClick={() => {
              // Create a URL object based on the current location
              const currentUrl = new URL(window.location.href);
              // Define the query parameter name and value you want to check and append if not present
              const paramName = "refreshed_at";
              const paramValue = Date.now().toString();

              // Update or set the query parameter
              currentUrl.searchParams.set(paramName, paramValue);
              // Use replaceState to modify the URL without reloading the page
              window.history.replaceState({}, "", currentUrl.toString());

              // Finally, reload the page
              window.location.reload();
            }}
            color="transparent"
            startIcon={<RefreshIcon size="sm" />}
          >
            Not loading? Refresh page
          </Button>
          <Button
            onClick={() => {
              setPlayerToUseCookie("embed");
            }}
            color="transparent"
            startIcon={<AlertTriangleIcon size="sm" />}
          >
            Refresh not working? Switch to classic song player
          </Button>
        </div>
      )}
    </div>
  );
};

export default SongControl;
