import { useState } from "react";
import { GeoGamePlayerRecords, TOTAL_RUMBLE_ROUNDS } from ".";
import GameRecapModal from "../../components/GameRecapModal";
import GeoIntroModal from "../../components/GeoIntroModal";
import GeoguessMap from "../../components/GeoguessMap";
import { calculateScoreByDistance } from "../../components/GeoguessMap/scoring";
import {
  CoordinatePair,
  getDistanceAndBearing,
} from "../../components/GuessCityPanel/location";
import RefreshedModal from "../../components/RefreshedModal";
import { SpotifyLogin } from "../../components/SpotifyLogin";
import Button from "../../components/basic/Button";
import {
  fetchRandomHiddenGemsPuzzle,
  fetchRandomPuzzle,
  Puzzle,
} from "../../data/puzzles";
import { recordGeoScore } from "../../services/user_tracking";
import HiddenGemsGeoIntroModal from "../../components/HiddenGemsGeoIntroModal";
import { hiddenGemsItineraryLink } from "../../components/AnswerModal/ItineraryInfo";
import HiddenGemsLeaderboardModal from "../../components/HiddenGemsLeaderboardModal";

interface PlayGeoGameProps {
  puzzle: Puzzle;
  setPuzzle: (puzzle: Puzzle) => void;
  setGetPuzzleError: (error: string | null) => void;
  players: string[];
  setPlayers: (players: string[]) => void;
  roundRecords: GeoGamePlayerRecords[];
  setRoundRecords: (records: GeoGamePlayerRecords[]) => void;
  roundNumber: number;
  setRoundNumber: (roundNumber: number) => void;
  currentRoundFinished: boolean;
  setMusicPlaying: (playing: boolean) => void;
  token: string;
  setToken: (token: string) => void;
  spotifyUser: SpotifyApi.CurrentUsersProfileResponse | null;
  gameId: string;
  setGameId: (gameId: string) => void;
  showIntroModal: boolean;
  setShowIntroModal: (show: boolean) => void;
  showRefreshedModal: boolean;
  setShowRefreshedModal: (show: boolean) => void;
  resetGame: () => void;
  hiddenGemsMode?: boolean;
}
const PlayGeoGame = ({
  puzzle,
  setPuzzle,
  setGetPuzzleError,
  players,
  setPlayers,
  roundRecords,
  setRoundRecords,
  roundNumber,
  setRoundNumber,
  currentRoundFinished,
  setMusicPlaying,
  token,
  setToken,
  spotifyUser,
  gameId,
  setGameId,
  showIntroModal,
  setShowIntroModal,
  showRefreshedModal,
  setShowRefreshedModal,
  resetGame,
  hiddenGemsMode,
}: PlayGeoGameProps) => {
  const [showGameRecapModal, setShowGameRecapModal] = useState(false);
  const [showHiddenGemsLeaderboardModal, setShowHiddenGemsLeaderboardModal] =
    useState(false);

  // Google maps embed
  const gmaps_key = "AIzaSyBYJdbJeHofpU40D3Nad3ZVV7kuk3A2E_E";

  /* Handle guess function:
  Calculate distance and points from answer
  Update roundRecords
  */
  const handleGuesses = (guesses: CoordinatePair[]) => {
    if (!puzzle) return;

    // Process each guess and update roundRecords directly
    guesses.forEach((guess, playerIndex) => {
      const distance = getDistanceAndBearing(guess, puzzle).distance;
      const points = calculateScoreByDistance(distance);
      const newRoundRecord = { puzzle, guess, distance, points };

      // Ensure there is a record object for each player
      if (!roundRecords[playerIndex]) {
        roundRecords[playerIndex] = {
          name: players[playerIndex],
          roundRecords: [],
        };
      }

      // Add the new round record to the appropriate player's record
      const playerName = players[playerIndex];
      const playerRecord = roundRecords.find(
        (record) => record.name === playerName
      );
      if (!playerRecord) {
        console.error(`Failed to find player record for ${playerName}`);
        return;
      }
      playerRecord.roundRecords.push(newRoundRecord);
    });

    // Update the state with the modified roundRecords array
    setRoundRecords([...roundRecords]);
  };

  return (
    <div className="flex flex-col space-y-4 sm:p-4 items-center">
      <div className="flex flex-col items-center w-full">
        {/* Round indicator */}
        <div className="flex flex-row text-base text-gray-400 pl-2 items-center space-x-4">
          <span className="font-semibold text-gray-400">
            Round {roundNumber} / {TOTAL_RUMBLE_ROUNDS}
          </span>
          <div>
            {roundRecords.map((playerRecord) => (
              <div key={playerRecord.name}>
                <span>
                  {players.length > 1 ? `${playerRecord.name}: ` : ""}
                  {playerRecord.roundRecords.reduce(
                    (acc, curr) => acc + curr.points,
                    0
                  )}{" "}
                  points
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Button to go to next puzzle after round finished */}
      {currentRoundFinished && roundNumber < TOTAL_RUMBLE_ROUNDS && (
        <Button
          onClick={() => {
            if (hiddenGemsMode) {
              fetchRandomHiddenGemsPuzzle(
                roundRecords.flatMap((playerRecord) =>
                  playerRecord.roundRecords.map(
                    (r) => r.puzzle.artist_spotify_id
                  )
                )
              )
                .then((dailyPuzzle) => {
                  setPuzzle(dailyPuzzle as unknown as Puzzle);
                  setGetPuzzleError(null);
                  setRoundNumber(roundNumber + 1);
                  setMusicPlaying(true); // start the music
                })
                .catch((error) => {
                  setGetPuzzleError("Failed to switch puzzle.");
                });
            } else {
              fetchRandomPuzzle(
                roundRecords.flatMap((playerRecord) =>
                  playerRecord.roundRecords.map(
                    (r) => r.puzzle.artist_spotify_id
                  )
                )
              )
                .then((dailyPuzzle) => {
                  setPuzzle(dailyPuzzle as unknown as Puzzle);
                  setGetPuzzleError(null);
                  setRoundNumber(roundNumber + 1);
                  setMusicPlaying(true); // start the music
                })
                .catch((error) => {
                  setGetPuzzleError("Failed to switch puzzle.");
                });
            }
          }}
        >
          {hiddenGemsMode ? "Next stop" : "Next puzzle"}
        </Button>
      )}
      {/* Button to finish game after last round */}
      {currentRoundFinished && roundNumber === TOTAL_RUMBLE_ROUNDS && (
        <Button
          onClick={async () => {
            // Record scores on finishing game
            if (process.env.NODE_ENV === "production") {
              await Promise.all(
                roundRecords.map((playerRecord) =>
                  recordGeoScore({
                    game_id: gameId,
                    score: playerRecord.roundRecords.reduce(
                      (acc, curr) => acc + curr.points,
                      0
                    ),
                    player: playerRecord.name,
                  })
                )
              );
            }
            // Either open game recap modal or hidden gems leaderboard modal
            // (depending on if hidden_gems_mode)
            if (hiddenGemsMode) {
              setShowHiddenGemsLeaderboardModal(true);
            } else {
              setShowGameRecapModal(true);
            }
          }}
        >
          Finish game
        </Button>
      )}
      {/* Map */}
      <div className="w-full md:w-3/4 lg:w-1/2 h-[500px] rounded-lg overflow-hidden">
        <GeoguessMap
          gmapsKey={gmaps_key}
          puzzle={puzzle}
          players={players}
          onSubmitGuess={handleGuesses}
          completedRoundRecords={
            currentRoundFinished
              ? roundRecords
                  .sort(
                    // sort according to player order in players
                    (a, b) => players.indexOf(a.name) - players.indexOf(b.name)
                  )
                  .map(
                    (playerRecord) => playerRecord.roundRecords[roundNumber - 1]
                  )
              : undefined
          }
          startingPlayerIdx={(roundNumber - 1) % players.length} // rotate starting player each round
          hiddenGemsMode={hiddenGemsMode}
        />
      </div>
      <div className="flex w-full justify-center mt-1">
        {hiddenGemsMode ? (
          <div className="text-gray-400 text-xs hover:underline">
            <a href={hiddenGemsItineraryLink} target="_blank" rel="noreferrer">
              <Button color="transparent">
                <span className="underline">Open</span>&nbsp;the Hidden Gems of
                Music Tourism Guide to explore the music itineraries from the
                cities featured in this game
              </Button>
            </a>
          </div>
        ) : (
          <SpotifyLogin
            token={token}
            setToken={setToken}
            spotifyUser={spotifyUser}
          />
        )}
      </div>
      {/* intro modal */}
      {hiddenGemsMode ? (
        <HiddenGemsGeoIntroModal
          isOpen={showIntroModal}
          onClose={() => {
            setMusicPlaying(true); // start playing the song
            setShowIntroModal(false);
          }}
        />
      ) : (
        <GeoIntroModal
          isOpen={showIntroModal}
          onClose={() => {
            setMusicPlaying(true); // start playing the song
            setShowIntroModal(false);
          }}
        />
      )}
      {/* refreshed modal */}
      <RefreshedModal
        isOpen={showRefreshedModal}
        onClose={() => {
          setMusicPlaying(true); // start playing the song
          setShowRefreshedModal(false);
        }}
      />
      {/* Game Recap modal */}
      <GameRecapModal
        isOpen={showGameRecapModal}
        onClose={() => {
          resetGame();
          setShowGameRecapModal(false);
          // keep the puzzle so that it can keep playing music on main menu
        }}
        playerRecords={roundRecords}
        hiddenGemsMode={hiddenGemsMode}
      />
      {/* Hidden gems leaderboard modal */}
      {showHiddenGemsLeaderboardModal && (
        <HiddenGemsLeaderboardModal
          isOpen={showHiddenGemsLeaderboardModal}
          onClose={() => {
            setShowHiddenGemsLeaderboardModal(false);
            setShowGameRecapModal(true);
          }}
          onNext={() => {
            setShowHiddenGemsLeaderboardModal(false);
            setShowGameRecapModal(true);
          }}
          playerScores={roundRecords.map((playerRecord) => ({
            game_id: gameId,
            score: playerRecord.roundRecords.reduce(
              (acc, curr) => acc + curr.points,
              0
            ),
            player: playerRecord.name,
          }))}
        />
      )}
      {/* Button to reopen intro modal in top left of screen */}
      {/* <div className="fixed top-0 left-2">
            <IconButton onClick={() => setShowIntroModal(true)}>
              <HelpCircleIcon className="text-gray-400" size="md" />
            </IconButton>
          </div> */}
    </div>
  );
};

export default PlayGeoGame;
