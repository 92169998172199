import { useState, useRef, useEffect } from "react";
import MinusCircleIcon from "../../components/Icon/MinusCircleIcon";
import PlayIcon from "../../components/Icon/PlayIcon";
import PlusIcon from "../../components/Icon/PlusIcon";
import Button from "../../components/basic/Button";
import NeonText, { NeonColor } from "../../components/basic/NeonText";
import IconButton from "../../components/basic/IconButton";
import Input from "../../components/basic/Input";

/*
Component that allows user to select the number of players, then enter their names.
Names default to "Player 1", "Player 2", etc.
*/
interface SetPlayersStepProps {
  players: string[];
  setPlayers: (players: string[]) => void;
  fetchRandomPuzzle: () => void;
  setRoundNumber: (roundNumber: number) => void;
}
const MAX_PLAYERS = 4;
const SetPlayersStep = ({
  players,
  setPlayers,
  fetchRandomPuzzle,
  setRoundNumber,
}: SetPlayersStepProps) => {
  // Initialize local state with default player names or existing names
  const [localPlayers, setLocalPlayers] = useState<string[]>(
    players.length > 0 ? players : ["Player 1"]
  );

  // Function to handle the change of any player's name
  const handlePlayerNameChange = (index: number, newName: string) => {
    const updatedPlayers = [...localPlayers];
    updatedPlayers[index] = newName;
    setLocalPlayers(updatedPlayers);
  };

  // Function to add a new player with a default name
  const addPlayer = () => {
    if (localPlayers.length >= MAX_PLAYERS) return;
    setLocalPlayers([...localPlayers, `Player ${localPlayers.length + 1}`]);
  };

  // Function to remove a player
  const removePlayer = (index: number) => {
    const updatedPlayers = [...localPlayers];
    updatedPlayers.splice(index, 1);
    setLocalPlayers(updatedPlayers);
  };

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    // Initialize the refs array with null values
    inputRefs.current = inputRefs.current.slice(0, localPlayers.length);
  }, [localPlayers.length]);

  const handleFocus = (index: number) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index]?.select();
    }
  };

  const handleStartGame = () => {
    setPlayers(localPlayers);
    fetchRandomPuzzle();
    setRoundNumber(1);
  };

  return (
    <div className="flex flex-col items-center mt-8">
      <div className="flex flex-col space-y-4 items-center">
        <div className="flex flex-row items-center space-x-2">
          <NeonText color={NeonColor.PINK} className="text-3xl">
            Pindrop
          </NeonText>
          <NeonText color={NeonColor.BLUE} className="text-3xl">
            Rumble
          </NeonText>
        </div>
        <div className="text-lg text-gray-400 px-2">
          Pindrop as a party game. Because it's more fun with friends.
        </div>
        <NeonText color={NeonColor.GREEN} className="text-xl">
          Lobby
        </NeonText>
        {localPlayers.map((player, index) => (
          <div key={index} className="flex items-center space-x-2">
            <Input
              ref={(el) => (inputRefs.current[index] = el)}
              type="text"
              value={player}
              onChange={(e) => handlePlayerNameChange(index, e.target.value)}
              onFocus={() => handleFocus(index)}
              placeholder={`Player ${index + 1}`}
            />
            {index === 0 && localPlayers.length === 1 ? null : (
              <IconButton onClick={() => removePlayer(index)}>
                <MinusCircleIcon size="md" />
              </IconButton>
            )}
          </div>
        ))}
        <Button
          onClick={addPlayer}
          disabled={localPlayers.length >= MAX_PLAYERS}
          color="dark"
          startIcon={<PlusIcon size="md" />}
        >
          {localPlayers.length >= MAX_PLAYERS ? "Game is full" : "Add player"}
        </Button>
      </div>
      <Button
        color="neon"
        onClick={handleStartGame}
        className="mt-8 px-4"
        startIcon={<PlayIcon size="md" />}
      >
        Ready {localPlayers.length === 1 ? "(Solo mode)" : ""}
      </Button>
    </div>
  );
};

export default SetPlayersStep;
