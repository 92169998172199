import { Puzzle, fetchPuzzleByArtistSpotifyId } from "../data/puzzles";
import { CookieGeoGameRecord, GeoGamePlayerRecords } from "../pages/GeoGame";

export const currentPuzzleFromCookieGeoGameRecord = async (
  record: CookieGeoGameRecord
): Promise<Puzzle> => {
  const { currentArtistSpotifyId } = record;
  return await fetchPuzzleByArtistSpotifyId(currentArtistSpotifyId).then(
    (puzzle) => {
      if (!puzzle) throw new Error("Failed to fetch puzzle");
      return puzzle as unknown as Puzzle;
    }
  );
};

export const playerRecordsFromCookieGeoGameRecord = async (
  record: CookieGeoGameRecord
): Promise<GeoGamePlayerRecords[]> => {
  const { playerRecords: cookiePlayerRecords } = record;
  // for each player record in cookiePlayerRecords,
  // for each round record in playerRecord.roundRecords,
  // replace the artistSpotifyId with the puzzle object
  const updatedPlayerRecords = await Promise.all(
    cookiePlayerRecords.map(async (playerRecord) => {
      const updatedRoundRecords = await Promise.all(
        playerRecord.roundRecords.map(async (roundRecord) => {
          const puzzle = await fetchPuzzleByArtistSpotifyId(
            roundRecord.artistSpotifyId
          );
          if (!puzzle) throw new Error("Failed to fetch puzzle");
          return {
            ...roundRecord,
            puzzle: puzzle as unknown as Puzzle,
          };
        })
      );
      return {
        ...playerRecord,
        roundRecords: updatedRoundRecords,
      };
    })
  );

  return updatedPlayerRecords;
};

export const playerRecordsToCookiePlayerRecords = (
  records: GeoGamePlayerRecords[]
): CookieGeoGameRecord["playerRecords"] => {
  return records.map((record) => {
    return {
      name: record.name,
      roundRecords: record.roundRecords.map((roundRecord) => {
        const { puzzle, ...rest } = roundRecord;
        return {
          ...rest,
          artistSpotifyId: puzzle.artist_spotify_id,
        };
      }),
    };
  });
};
