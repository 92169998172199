import React, { forwardRef } from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string;
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ type, placeholder, value, onChange, className, ...rest }, ref) => {
    const baseClassName = "border border-gray-300 rounded-md p-2 text-black";

    return (
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`${baseClassName} ${className}`}
        ref={ref}
        {...rest}
      />
    );
  }
);

export default Input;
