interface CompletionDollarPair {
  completions: number;
  dollars: number;
}
export const COMPLETIONS_TO_DOLLARS: CompletionDollarPair[] = [
  { completions: 0, dollars: 0 },
  { completions: 20, dollars: 25 },
  { completions: 40, dollars: 50 },
  { completions: 60, dollars: 100 },
  { completions: 80, dollars: 200 },
  { completions: 100, dollars: 300 },
];

/*
Given the number of completions, returns the corresponding dollar amount
*/
export function completionsToDollars(completions: number): number {
  // Handle edge cases where completions are less than the first threshold or more than the last threshold
  if (completions <= COMPLETIONS_TO_DOLLARS[0].completions) {
    return COMPLETIONS_TO_DOLLARS[0].dollars;
  }
  if (
    completions >=
    COMPLETIONS_TO_DOLLARS[COMPLETIONS_TO_DOLLARS.length - 1].completions
  ) {
    return COMPLETIONS_TO_DOLLARS[COMPLETIONS_TO_DOLLARS.length - 1].dollars;
  }

  // Find the surrounding thresholds
  for (let i = 0; i < COMPLETIONS_TO_DOLLARS.length - 1; i++) {
    const current = COMPLETIONS_TO_DOLLARS[i];
    const next = COMPLETIONS_TO_DOLLARS[i + 1];

    if (completions >= current.completions && completions <= next.completions) {
      // Perform linear interpolation
      const ratio =
        (completions - current.completions) /
        (next.completions - current.completions);
      return (
        Math.round(
          (current.dollars + ratio * (next.dollars - current.dollars)) * 100
        ) / 100
      );
    }
  }

  // This line should never be reached due to the earlier checks
  throw new Error("Unexpected error in completionsToDollars function");
}
