import React, { useState, useEffect, useCallback } from "react";
import { autoSignIn, signUp } from "aws-amplify/auth";
import Button from "../basic/Button";
import Input from "../basic/Input";

interface SignUpProps {
  setUsername: (username: string | null) => void;
}

const SignUp = ({ setUsername }: SignUpProps) => {
  const [usernameInput, setUsernameInput] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [backendError, setBackendError] = useState<string | null>(null);
  const [isFormValid, setIsFormValid] = useState(false);

  const validateForm = useCallback(() => {
    const newErrors: { [key: string]: string } = {};

    if (usernameInput.length < 3) {
      newErrors.username = "Username must be at least 3 characters long.";
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      newErrors.email = "Email must be a valid email address.";
    }

    if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    }

    if (password !== confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match.";
    }

    setErrors(newErrors);
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [usernameInput, email, password, confirmPassword]);

  useEffect(() => {
    validateForm();
  }, [usernameInput, email, password, confirmPassword, validateForm]);

  const handleSignUp = async () => {
    try {
      await signUp({
        username: usernameInput,
        password,
        options: {
          userAttributes: {
            email,
          },
          autoSignIn: true,
        },
      });
      await autoSignIn();
      setUsername(usernameInput);
    } catch (error: any) {
      if (error.name === "UsernameExistsException") {
        setBackendError("This username is already taken.");
      } else {
        setBackendError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="flex flex-col space-y-2 p-2">
      <div className="text-gray-400 w-full text-center">
        Sign up to raise money as you play!
      </div>
      {backendError && <small className="text-red-400">{backendError}</small>}
      <Input
        type="text"
        placeholder="Public Username"
        value={usernameInput}
        onChange={(e) => setUsernameInput(e.target.value)}
      />
      {errors.username && usernameInput && (
        <small className="text-red-400">{errors.username}</small>
      )}

      <Input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {errors.email && email && (
        <small className="text-red-400">{errors.email}</small>
      )}

      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {errors.password && password && (
        <small className="text-red-400">{errors.password}</small>
      )}

      <Input
        type="password"
        placeholder="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      {errors.confirmPassword && confirmPassword && (
        <small className="text-red-400">{errors.confirmPassword}</small>
      )}

      <Button onClick={handleSignUp} color="light" disabled={!isFormValid}>
        Sign Up
      </Button>
    </div>
  );
};

export default SignUp;
