import { useEffect, useState } from "react";
import { fetchAuthSession, getCurrentUser } from "aws-amplify/auth";
import Modal from "../basic/Modal";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import LogOut from "./LogOut";

interface AuthModalProps {
  isOpen: boolean;
  onClose: () => void;
  username: string | null;
  setUsername: (username: string | null) => void;
}
const AuthModal = ({
  isOpen,
  onClose,
  username,
  setUsername,
}: AuthModalProps) => {
  const [isSignUp, setIsSignUp] = useState(true);

  // detect if there is a user already signed in
  useEffect(() => {
    getAuthenticatedUser().then((user) => setUsername(user?.username || null));
  }, [username, setUsername]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-96">
      {/* Render log out or sign up/sign in depending if there is a logged in user */}
      {username ? (
        <LogOut username={username} setUsername={setUsername} />
      ) : (
        <>
          <div className="flex flex-row items-center justify-between p-2">
            <button
              onClick={() => setIsSignUp(false)}
              disabled={!isSignUp}
              className={`w-full text-white p-2 rounded transition ease-in-out duration-150 flex flex-row items-center justify-center bg-gray-800 ${
                isSignUp ? "opacity-50" : "opacity-100 cursor-not-allowed"
              }`}
            >
              Log in
            </button>
            <button
              onClick={() => setIsSignUp(true)}
              color="dark"
              disabled={isSignUp}
              className={`w-full text-white p-2 rounded transition ease-in-out duration-150 flex flex-row items-center justify-center bg-gray-800 ${
                isSignUp ? "opacity-100 cursor-not-allowed" : "opacity-50"
              }`}
            >
              Sign up
            </button>
          </div>
          {isSignUp ? (
            <SignUp setUsername={setUsername} />
          ) : (
            <SignIn setUsername={setUsername} />
          )}
        </>
      )}
    </Modal>
  );
};

/*
Attempt to get the current authenticated user.
If there is no user, return null.
*/
export const getAuthenticatedUser = async () => {
  try {
    const { username, signInDetails } = await getCurrentUser();
    const { tokens: session } = await fetchAuthSession();
    return {
      username,
      session,
      authenticationFlowType: signInDetails?.authFlowType,
    };
  } catch (error) {
    if ((error as any).name === "UserUnAuthenticatedException") return null; // user not logged in
    console.error("Error getting authenticated user:", error); // other errors
    return null;
  }
};

export default AuthModal;
