import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const ShareIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M12 2V15 M16 6L12 2L8 6 M4 12V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V12";
  return <StrokeIcon {...props} path={path} />;
};

export default ShareIcon;
