import { put } from "aws-amplify/api";
import { API_NAME } from "../constants";

export enum GameMode {
  DAILY = "daily",
  GEO = "geo",
}

// Register a site visit in DynamoDB
export async function registerVisit({ game_mode }: { game_mode: GameMode }) {
  try {
    const visit = { date: new Date().toISOString(), game_mode };
    put({
      apiName: API_NAME,
      path: "/visit",
      options: {
        body: visit,
      },
    });
  } catch (err) {
    console.log("PUT call failed: ", err);
  }
}

// Register a share in DynamoDB
export async function registerShare({
  game_mode,
  artist_name,
}: {
  artist_name: string;
  game_mode: GameMode;
}) {
  try {
    const share = {
      date: new Date().toISOString(),
      artist_name,
      game_mode,
    };
    put({
      apiName: API_NAME,
      path: "/share",
      options: {
        body: share,
      },
    });
  } catch (err) {
    console.log("PUT call failed: ", err);
  }
}

// Register a save in DynamoDB
export async function registerSave({
  game_mode,
  artist_name,
  platform,
}: {
  game_mode: GameMode;
  artist_name: string;
  platform: string;
}) {
  try {
    const save = {
      date: new Date().toISOString(),
      artist_name,
      platform,
      game_mode,
    };
    put({
      apiName: API_NAME,
      path: "/save",
      options: {
        body: save,
      },
    });
  } catch (err) {
    console.log("PUT call failed: ", err);
  }
}

// Register a user's score in DynamoDB - Daily game
export async function recordDailyScore({
  artist_name,
  num_guesses,
}: {
  artist_name: string;
  num_guesses: number; // MAX_GUESSES + 1 for a loss
}) {
  try {
    const score = {
      date: new Date().toISOString(),
      artist_name,
      num_guesses,
      game_mode: GameMode.DAILY,
    };
    put({
      apiName: API_NAME,
      path: "/score",
      options: {
        body: score,
      },
    });
  } catch (err) {
    console.log("PUT call failed: ", err);
  }
}

// helper func to add or subtract up to 1000 ms to avoid exact date collisions
// (date is primary key on many tables)
function getRandomAdjustedDate(): string {
  const currentDate = new Date();
  // Generate a random number between -1000 and 1000
  const randomAdjustment = Math.floor(Math.random() * 2001) - 1000; // 2001 because Math.random() is exclusive of 1
  // Adjust the date by the random number of milliseconds
  currentDate.setTime(currentDate.getTime() + randomAdjustment);
  return currentDate.toISOString();
}

// Register a user's score in DynamoDB - Geo game
export interface PlayerGeoScore {
  game_id: string;
  player: string;
  score: number;
}
export async function recordGeoScore({
  game_id,
  player,
  score,
}: PlayerGeoScore) {
  try {
    const scoreToRecord = {
      date: getRandomAdjustedDate(),
      game_id,
      player,
      score,
      game_mode: GameMode.GEO,
    };
    put({
      apiName: API_NAME,
      path: "/score",
      options: {
        body: scoreToRecord,
      },
    });
  } catch (err) {
    console.log("PUT call failed: ", err);
  }
}
