import { Puzzle } from "../../data/puzzles";
import NeonText, { NeonColor } from "../basic/NeonText";

// Basic display of song_name, song_album_art, and song_artists
const TrackInfo = ({ puzzle }: { puzzle: Puzzle }) => {
  return (
    <div className="flex flex-col space-y-4 items-center w-full">
      <div className="w-full text-left">
        <NeonText color={NeonColor.GREEN} className="text-md">
          Today's song
        </NeonText>
      </div>
      {/* Song row */}
      <div className="flex flex-row space-x-4 items-center">
        {/* Album art */}
        <img
          src={puzzle.song_album_art}
          alt={puzzle.song_name}
          className="w-16 rounded-lg overflow-hidden"
          style={{ objectFit: "contain" }}
        />
        {/* Song name & artists */}
        <div className="flex flex-col items-start">
          <h2 className="text-md font-bold">{puzzle.song_name}</h2>
          <h2 className="text-md font-md">{puzzle.song_artists.join(", ")}</h2>
        </div>
        {/* Song year */}
        <div className="text-lg font-medium">{puzzle.song_release_year}</div>
      </div>
    </div>
  );
};

export default TrackInfo;
