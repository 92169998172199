import "./App.css";
import Admin from "./pages/Admin";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PuzzlePicker from "./pages/PuzzlePicker";
import PuzzleOrdering from "./pages/PuzzleOrdering";
import Home from "./pages/Home";
import DailyGame from "./pages/DailyGame";
import GeoGame from "./pages/GeoGame";
import { UsernameProvider } from "./state/UsernameContext";
import DailyGameHiddenGemsDemo from "./pages/DailyGameHiddenGemsDemo";
import HiddenGemsLeaderboardPage from "./pages/GeoGame/HiddenGemsLeaderboardPage";

function App() {
  return (
    <UsernameProvider>
      <div className="App bg-color-black-gradient flex flex-col min-h-[100vh] sm:min-h-[95vh]">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/daily/hidden-gems-demo"
              element={<DailyGameHiddenGemsDemo />}
            />
            <Route path="/daily" element={<DailyGame />} />
            {/* Hidden gems rumble */}
            <Route
              path={`/rumble/hidden-gems/*`}
              element={
                <Routes>
                  <Route index element={<GeoGame hiddenGemsMode={true} />} />
                  <Route
                    path="/leaderboard"
                    element={<HiddenGemsLeaderboardPage />}
                  />
                </Routes>
              }
            />
            <Route path="/rumble/*" element={<GeoGame />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/puzzle-picker" element={<PuzzlePicker />} />
            <Route path="/puzzle-ordering" element={<PuzzleOrdering />} />
          </Routes>
        </Router>
      </div>
      <footer className="min-h-[5vh] flex-col items-center justify-center bg-page-black hidden sm:flex">
        <div className="flex flex-row justify-center text-gray-400">
          &copy; 2024 Ben Ma |&nbsp;
          <a href="mailto:chimera.recommender@gmail.com">Contact</a>
        </div>
      </footer>
    </UsernameProvider>
  );
}

export default App;
