import { Puzzle } from "../../data/puzzles";
import { GameMode, registerSave } from "../../services/user_tracking";
import SpotifyIcon from "../Icon/SpotifyIcon";
import YoutubeIcon from "../Icon/YoutubeIcon";

// Component to display artist spotify and youtube links
const ArtistLinks = ({ puzzle }: { puzzle: Puzzle }) => {
  const spotifyLink = `https://open.spotify.com/artist/${puzzle.artist_spotify_id}`;

  return (
    <div className="flex flex-row items-center space-x-4">
      <div className="text-xl font-medium text-right">
        Save {puzzle.artist_name}:
      </div>
      <div className="flex flex-row space-x-2 items-center">
        {/* Spotify button */}
        <a href={spotifyLink} target="_blank" rel="noopener noreferrer">
          <button
            onClick={() => {
              if (process.env.NODE_ENV === "production")
                registerSave({
                  game_mode: GameMode.DAILY,
                  artist_name: puzzle.artist_name,
                  platform: "spotify",
                });
            }}
          >
            <SpotifyIcon size="lg" />
          </button>
        </a>
        {/* Youtube button */}
        {puzzle.artist_youtube_link && (
          <a
            href={puzzle.artist_youtube_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button
              onClick={() => {
                if (process.env.NODE_ENV === "production")
                  registerSave({
                    game_mode: GameMode.DAILY,
                    artist_name: puzzle.artist_name,
                    platform: "youtube",
                  });
              }}
            >
              <YoutubeIcon size="lg" />
            </button>
          </a>
        )}
      </div>
    </div>
  );
};

export default ArtistLinks;
