import React from "react";

type ButtonProps = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  loading?: boolean;
  color?: "light" | "dark" | "neon" | "transparent";
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  className?: string;
};

const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  disabled = false,
  loading = false,
  color = "light",
  startIcon,
  endIcon,
  className = "",
}) => {
  // Define color classes for each button type
  const colorClasses = {
    light: "bg-gray-100 text-black",
    dark: "bg-gray-800 text-white",
    neon: "bg-pink-500 text-white",
    transparent: "text-gray-400 hover:border-gray-400 hover:border",
  };

  // Determine the button's base classes and merge with color-specific classes
  const buttonClasses = `p-2 rounded transition ease-in-out duration-150 flex flex-row items-center justify-center ${
    colorClasses[color]
  } ${
    disabled || loading
      ? "opacity-50 cursor-not-allowed"
      : "hover:bg-opacity-80"
  } ${className}`;

  return (
    <button
      className={buttonClasses}
      onClick={onClick || (() => {})}
      disabled={disabled || loading}
    >
      {loading && <span>🔄</span>} {/* Placeholder for loading spinner */}
      {startIcon && <span className="mr-2">{startIcon}</span>}
      {children}
      {endIcon && <span className="ml-2">{endIcon}</span>}
    </button>
  );
};

export default Button;
