import React from "react";
import { NeonColor, neonColors } from "../basic/NeonText";

export interface PindropIconProps {
  size: "2xl" | "xl" | "lg" | "md" | "sm" | "xs" | "2xs";
  className?: string;
  neonColorPrimary?: NeonColor;
  neonColorSecondary?: NeonColor;
}

export const sizeClasses = {
  "2xl": "h-64 w-64",
  xl: "h-32 w-32",
  lg: "h-16 w-16",
  md: "h-8 w-8",
  sm: "h-4 w-4",
  xs: "h-3 w-3",
  "2xs": "h-2 w-2",
};

const PindropIcon: React.FC<PindropIconProps> = ({
  size,
  className = "",
  neonColorPrimary,
  neonColorSecondary,
}) => {
  const sizeClass = sizeClasses[size];
  const neonClassPrimary = neonColorPrimary
    ? `drop-shadow-neon ${neonColors[neonColorPrimary].tailwindColorClass}`
    : "";
  const neonClassSecondary = neonColorSecondary
    ? `drop-shadow-neon ${neonColors[neonColorSecondary].tailwindColorClass}`
    : "";

  return (
    <div className={`relative ${sizeClass}`}>
      {/* Treble clef */}
      <svg
        className={`${sizeClass} ${neonClassPrimary} ${className} stroke-current absolute z-10`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 161 514"
        xmlns="http://www.w3.org/2000/svg"
        style={
          neonColorPrimary
            ? ({
                "--tw-drop-shadow-color":
                  neonColors[neonColorPrimary].shadowColorHex,
              } as React.CSSProperties)
            : {}
        }
      >
        <path
          d={
            "M74.0148 306.668C74.0148 306.668 21.9973 270.905 96.7408 252.517C168.235 234.928 164.479 332.697 114.712 343.065M114.712 343.065C64.9453 353.433 18.6344 325.094 11.7223 294.68C4.81025 264.267 8.95751 230.744 82.2254 188.58C155.493 146.416 123.293 81.6453 111.197 67.13C99.1007 52.6146 63.8001 97.5676 78.6508 166.564M114.712 343.065C129.588 420.33 105.716 293.079 120.933 371.404C123.352 383.846 99.16 424.282 64.2541 393.523"
          }
          strokeWidth="13"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      {/* Pin */}
      <svg
        className={`${sizeClass} ${neonClassSecondary} ${className} stroke-current absolute`}
        fill="none"
        stroke="currentColor"
        viewBox="0 0 161 514"
        xmlns="http://www.w3.org/2000/svg"
        style={
          neonColorSecondary
            ? ({
                "--tw-drop-shadow-color":
                  neonColors[neonColorSecondary].shadowColorHex,
              } as React.CSSProperties)
            : {}
        }
      >
        {/* Pin body */}
        <path
          d={
            "M127.86 400.705L144.709 488.625 M101.697 268.861L112.594 325.634 M89.1216 204.259L95.0511 235.49 M54.3238 28.1868L65.7775 87.0028"
          }
          strokeWidth="13"
          strokeLinecap="round"
        />
        {/* Pin point */}
        <path
          d={"M143.413 491.549L146.22 497.258L146.936 490.905"}
          strokeWidth="8"
          strokeLinecap="square"
        />
        {/* Pin head */}
        <path
          d={"M50.5462 13.717L52.6235 13.2283"}
          strokeWidth="25"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default PindropIcon;
