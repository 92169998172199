import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const SunIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M18.36 5.63997L19.78 4.21997 M4.22 19.7801L5.64 18.3601 M21 12H23 M1 12H3 M18.36 18.3601L19.78 19.7801 M4.22 4.21997L5.64 5.63997 M12 21V23 M12 1V3 M12 17C14.7614 17 17 14.7614 17 12C17 9.23858 14.7614 7 12 7C9.23858 7 7 9.23858 7 12C7 14.7614 9.23858 17 12 17Z";
  return <StrokeIcon {...props} path={path} />;
};

export default SunIcon;
