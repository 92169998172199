import fayettevillePic from "../../img/fayetteville_itinerary_pic.jpg";

const itineraryDescription =
  "end your day in Fayetteville with a show at George’s Majestic Lounge, Arkansas’s oldest live music venue. George’s is the heart of Dickson Street nightlife. As you walk back to your hotel, you’ll hear music calling from restaurants and pubs and you’ll dream of your next visit to Fayetteville.";
const itineraryTitle = "Soundtrack of the Ozarks Old and New";
const fullItineraryLink =
  "https://www.musiccitiesevents.com/hidden-gems-of-music-tourism";

const NickShouldersItineraryInfo = () => {
  return (
    <div className="flex flex-col items-center md:flex-row md:space-x-6">
      <div
        className="rounded-lg overflow-hidden max-w-64 md:max-w-none
    md:basis-1/2 lg:basis-2/5 md:sticky md:top-16 max-h-32 md:max-h-none"
      >
        <img
          src={fayettevillePic}
          alt={"Fayetteville itinerary"}
          className="w-full h-full object-contain"
        />
      </div>
      <div className="flex flex-col items-center md:basis-1/2 lg:basis-3/5">
        <div className="text-white text-md italic mt-2">
          ...{itineraryDescription}
        </div>
        <div className="text-gray-400 text-md mt-2">
          from <b>"{itineraryTitle}"</b>
        </div>
        <div className="text-gray-400 text-sm mt-2 border-gray-400 p-2 border rounded-md">
          Pindrop is partnering with the Hidden Gems of Music Tourism to
          showcase exceptional talent from music cities around the world. Check
          out the full guide{" "}
          <a
            href={fullItineraryLink}
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:text-white"
          >
            here
          </a>
          .
        </div>
      </div>
      {/* Divider */}
      <div className="w-full h-px bg-gray-400 mt-4 md:hidden"></div>
    </div>
  );
};

export default NickShouldersItineraryInfo;
