import { NeonColor } from "../../components/basic/NeonText";
import PindropIcon from "../../components/Icon/PindropIcon";

const About = () => {
  return (
    <div className="flex flex-row items-center justify-center w-full sm:mt-8">
      <div className="hidden sm:block">
        <PindropIcon
          size="2xl"
          neonColorPrimary={NeonColor.PINK}
          neonColorSecondary={NeonColor.GREEN}
        />
      </div>
      <div className="flex flex-col space-y-4 p-4 max-w-[500px]">
        <div className="font-semibold text-lg text-white">
          Pindrop is a multicultural music discovery game designed to spark
          curiosity in unheard music.
        </div>
        <div className="text-md text-gray-400 space-y-4">
          <p>
            We're building Pindrop because we believe music is an incredibly
            effective & accessible pathway to opening emotional doors to other
            cultures. Travel, people-to-people exchanges, language programs…
            these are ideal ways to make lifelong connections, but these
            opportunities come rarely, or never, for many.
          </p>
          <p>
            With music streaming platforms carrying astonishing catalogs of
            music, listening to the world is easier than ever. Except, as anyone
            who's frustrated hearing the same songs over and over knows, it's
            not. These platforms guide listeners to tiny niches - even to the
            avid explorer, it's very hard to be recommended music outside of
            your own musical & geographical culture.
          </p>
          <p>
            Pindrop was designed as a fun and creative way to break through the
            discovery barrier. A way to fight attention inertia for unfamiliar
            cultural experiences and get people thinking, "How is music from
            there so slept on?!"
          </p>
          <p>
            Interested in learning more or beta testing new features? Join our{" "}
            <a
              href="https://pindropgame.substack.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="underline hover:font-semibold"
            >
              Substack email list
            </a>
            !
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
