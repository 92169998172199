// loadFonts.js
const fontURL = "https://pindrop-public.s3.amazonaws.com/neontubes2.otf";

const loadFonts = () => {
  const style = document.createElement("style");
  style.innerHTML = `
    @font-face {
      font-family: 'NeonTubes';
      src: url('${fontURL}') format('opentype');
      font-weight: normal;
      font-style: normal;
    }
  `;
  document.head.appendChild(style);
};

export default loadFonts;
