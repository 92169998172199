/*
Puzzle data. Provides function to fetch daily puzzle from backend.
*/

import { get } from "aws-amplify/api";
import { API_NAME } from "../constants";

export interface PuzzleInput {
  // artist
  artist_name: string;
  artist_bio: string;
  artist_style?: string;
  artist_curiosity?: string;
  authors_note?: string;
  artist_spotify_id: string;
  artist_image: string;
  artist_location: string;
  artist_youtube_link?: string;
  // song
  song_spotify_id: string;
  // answer
  latitude: number;
  longitude: number;
  // sets
  add_to_daily?: boolean;
  in_rumble_classic_set?: boolean;
  in_rumble_full_set?: boolean;
  // hidden gems
  itinerary_description?: string;
  itinerary_title?: string;
  itinerary_image?: string;
}

export interface Puzzle extends PuzzleInput {
  // song
  song_name: string;
  song_album_art: string;
  song_artists: string[];
  song_release_year: number;

  // hidden gems
  itinerary_description?: string;
  itinerary_title?: string;
  itinerary_image?: string;
}

export const fetchDailyPuzzle = async () => {
  try {
    const restOperation = get({
      apiName: API_NAME,
      path: "/puzzle",
    });
    const response = await restOperation.response;
    const json = await response.body.json();
    return json;
  } catch (err) {
    console.log("GET call failed: ", err);
    return null;
  }
};

export const fetchRandomPuzzle = async (excluded_artist_ids: string[]) => {
  try {
    // Join the array of IDs into a comma-separated string
    const excludedArtistIdsParam = excluded_artist_ids.join(",");

    const restOperation = get({
      apiName: API_NAME,
      path: `/puzzle/random${
        excludedArtistIdsParam
          ? `?excludedArtistIds=${encodeURIComponent(excludedArtistIdsParam)}`
          : ""
      }`,
    });
    const response = await restOperation.response;
    const json = await response.body.json();
    return json;
  } catch (err) {
    console.log("GET call failed: ", err);
    return null;
  }
};

export const fetchRandomHiddenGemsPuzzle = async (
  excluded_artist_ids: string[]
) => {
  try {
    // Join the array of IDs into a comma-separated string
    const excludedArtistIdsParam = excluded_artist_ids.join(",");

    // Construct the query parameters
    const queryParams = new URLSearchParams();
    queryParams.append("hidden_gems", "true");

    if (excludedArtistIdsParam) {
      queryParams.append("excludedArtistIds", excludedArtistIdsParam);
    }

    // Construct the path with query parameters
    const path = `/puzzle/random?${queryParams.toString()}`;

    const restOperation = get({
      apiName: API_NAME,
      path: path,
    });

    const response = await restOperation.response;
    const json = await response.body.json();
    return json;
  } catch (err) {
    console.log("GET call failed: ", err);
    return null;
  }
};

export const fetchPuzzleByArtistSpotifyId = async (
  id: string
): Promise<Puzzle | null> => {
  try {
    const restOperation = get({
      apiName: API_NAME,
      path: `/puzzle/${id}`,
    });
    const response = await restOperation.response;
    const json = await response.body.json();
    return json as any;
  } catch (err) {
    console.log("GET call failed: ", err);
    return null;
  }
};

export const fetchScoreDistribution = async (artistName?: string) => {
  try {
    let restOperation;
    if (artistName)
      restOperation = get({
        apiName: API_NAME,
        path: "/score-distribution",
        options: {
          queryParams: { artist_name: artistName },
        },
      });
    else
      restOperation = get({
        apiName: API_NAME,
        path: "/score-distribution",
      });
    const response = await restOperation.response;
    const json = (await response.body.json()) as any;
    const distribution = json?.score_distribution;
    return distribution;
  } catch (err) {
    console.log("GET call failed: ", err);
    return null;
  }
};
