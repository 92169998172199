import { useState, useEffect } from "react";
import {
  getHiddenGemsLeaderboard,
  insertRumbleScore,
  RumbleLeaderboardEntry,
} from "../services/rumble_scores";
import Button from "./basic/Button";
import Modal from "./basic/Modal";
import { PlayerGeoScore } from "../services/user_tracking";
import { HiddenGemsLeaderboard } from "../pages/GeoGame/HiddenGemsLeaderboardPage";
import { useUsername } from "../state/UsernameContext";
import Spinner from "./Spinner";

/*
Leaderboard modal that shows after finishing a hidden gems rumble game
if at least one of the players achieved a high score (top 10)
*/
const TOP_N_SCORES = 10;
interface HiddenGemsLeaderboardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
  playerScores: PlayerGeoScore[];
}
export const HiddenGemsLeaderboardModal = ({
  isOpen,
  onClose,
  onNext,
  playerScores,
}: HiddenGemsLeaderboardModalProps) => {
  const { username } = useUsername();
  const [entries, setEntries] = useState<RumbleLeaderboardEntry[]>([]);
  const [editableIndices, setEditableIndices] = useState<number[]>([]);

  const fetchEntries = async () => {
    try {
      const _entries = await getHiddenGemsLeaderboard();
      return _entries;
    } catch (error) {
      console.error(error);
    }
  };

  // Fetch leaderboard entries and determine if player scores are top 10
  // Set editable indices for top scores
  useEffect(() => {
    const updateLeaderboard = async () => {
      const leaderboardEntries = await fetchEntries();
      if (!leaderboardEntries) return;

      if (
        leaderboardEntries.length >= TOP_N_SCORES &&
        !playerScores.some(
          (playerScore) =>
            playerScore.score > 0 &&
            playerScore.score >
              (leaderboardEntries[TOP_N_SCORES - 1]
                ? leaderboardEntries[TOP_N_SCORES - 1].score
                : 0)
        )
      ) {
        onNext();
      } else {
        // Sort playerScores in descending order by score
        const sortedPlayerScores = [...playerScores].sort(
          (a, b) => b.score - a.score
        );

        const updatedEntries = [...leaderboardEntries];
        const newIndices: number[] = [];

        sortedPlayerScores.forEach((playerScore) => {
          const index = updatedEntries.findIndex(
            (entry) => playerScore.score > entry.score
          );
          const newEntry: RumbleLeaderboardEntry = {
            date_utc: new Date(),
            score: playerScore.score,
            display_name: playerScore.player,
            mode: "hidden_gems",
            username: username || undefined,
          };
          if (index !== -1) {
            updatedEntries.splice(index, 0, newEntry);
            newIndices.push(index);
          } else if (updatedEntries.length < TOP_N_SCORES) {
            // If the score is less than all current top scores but the leaderboard isn't full
            updatedEntries.push(newEntry);
            newIndices.push(updatedEntries.length - 1);
          }
        });

        setEditableIndices(newIndices);
        setEntries(updatedEntries.slice(0, TOP_N_SCORES));
      }
    };

    updateLeaderboard();
  }, [playerScores, onNext, username]);

  const handleNameChange = (index: number, newName: string) => {
    const updatedEntries = [...entries];
    updatedEntries[index].display_name = newName;
    setEntries(updatedEntries);
  };

  const handleSubmit = async () => {
    try {
      const entriesToUpdate = editableIndices.map((index) => entries[index]);
      await Promise.all(
        entriesToUpdate.map((entry) => insertRumbleScore(entry))
      );
      onNext();
    } catch (error) {
      console.error("Error updating leaderboard:", error);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:max-w-xl">
      {entries && entries.length > 0 && (
        <div className="flex flex-col space-y-4 p-4 items-center">
          <HiddenGemsLeaderboard
            entries={entries}
            editableIndices={editableIndices}
            onNameChange={handleNameChange}
          />
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      )}
      {!entries?.length && (
        <div className="flex items-center justify-center w-40 h-24">
          <Spinner />
        </div>
      )}
    </Modal>
  );
};

export default HiddenGemsLeaderboardModal;
