import { get, put } from "aws-amplify/api";
import { API_NAME } from "../constants";
import { getAuthenticatedUser } from "../components/AuthModal";

export interface PerfectScore {
  username: string;
  artist_spotify_id: string;
  artist_name: string;
  date_utc: string;
}

export interface FundraiserChallengeLeaderboardEntry {
  username: string;
  num_completed: number;
  rank?: number;
}

export interface LeaderboardStats {
  leaderboard: FundraiserChallengeLeaderboardEntry[];
  user_leaderboard?: FundraiserChallengeLeaderboardEntry;
  artists_completed: string[]; // artist spotify ID's of artists completed, globally
}

// Get perfect scores for a given username
export async function perfectScoresForUsername(
  username: string
): Promise<PerfectScore[]> {
  try {
    const authenticatedUser = await getAuthenticatedUser();
    const token = authenticatedUser?.session?.idToken;

    const getOperation = get({
      apiName: API_NAME,
      path: `/perfect-score/${username}`,
      options: {
        headers: {
          Authorization: token ? `${token}` : "",
        },
      },
    });
    const { body: resp } = await getOperation.response;
    return (await resp.json()) as any;
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("GET call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}

// Insert a perfect score into DynamoDB
export async function insertPerfectScore(scoreInput: PerfectScore) {
  try {
    const authenticatedUser = await getAuthenticatedUser();
    const token = authenticatedUser?.session?.idToken;
    const body = { ...scoreInput };
    const putOperation = put({
      apiName: API_NAME,
      path: "/perfect-score",
      options: {
        body,
        headers: {
          Authorization: token ? `${token}` : "",
        },
      },
    });
    const { body: resp } = await putOperation.response;
    return resp.json();
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("PUT call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}

// Get leaderboard
export async function getLeaderboard(): Promise<LeaderboardStats> {
  try {
    const authenticatedUser = await getAuthenticatedUser();
    const token = authenticatedUser?.session?.idToken;

    const getOperation = get({
      apiName: API_NAME,
      path: `/perfect-score/leaderboard`,
      options: {
        headers: {
          Authorization: token ? `${token}` : "",
        },
      },
    });
    const { body: resp } = await getOperation.response;
    return (await resp.json()) as any;
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("GET call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}
