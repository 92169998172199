/*
On startup, parse all cities from the world_cities.json file and store them in a
global variable. This file is only read once, on startup.

Can optimize later on by storing this on the backend.
*/

import data from "./world_cities.json";

export interface WorldCity {
  city_ascii: string;
  admin_name?: string; // optional state name, etc.
  country: string;
  lat: number;
  lng: number;
}

// Filter out invalid entries
const validData = (data as WorldCity[]).filter(
  (item: any) =>
    item.city_ascii !== undefined &&
    item.country !== undefined &&
    item.lat !== undefined &&
    item.lng !== undefined
);

// Remove duplicates
const uniqueData: WorldCity[] = [];
const seen = new Set();

validData.forEach((item: WorldCity) => {
  let duplicate: string;
  // USA gets disambiguation between cities of the same name by adding admin_name (state)
  if (item.country === "United States") {
    duplicate = `${item.city_ascii}-${item.admin_name}-${item.country}`;
  } else duplicate = `${item.city_ascii}-${item.country}`;
  if (!seen.has(duplicate)) {
    seen.add(duplicate);
    uniqueData.push(item);
  }
});

export const worldCities: WorldCity[] = uniqueData as WorldCity[];
