import { get, post } from "aws-amplify/api";
import { API_NAME } from "../constants";
import { getAuthenticatedUser } from "../components/AuthModal";

export interface RumbleLeaderboardEntry {
  date_utc: Date;
  score: number;
  display_name: string;
  username?: string;
  mode: string;
}

// Insert a rumble score into DynamoDB
export async function insertRumbleScore(scoreInput: RumbleLeaderboardEntry) {
  try {
    const authenticatedUser = await getAuthenticatedUser();
    const token = authenticatedUser?.session?.idToken;
    const body = {
      ...scoreInput,
      date_utc: scoreInput.date_utc.toString(),
    };
    const postOperation = post({
      apiName: API_NAME,
      path: "/rumble/score",
      options: {
        body,
        headers: {
          Authorization: token ? `${token}` : "",
        },
      },
    });
    const { body: resp } = await postOperation.response;
    return resp.json();
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("POST call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}

// Get leaderboard
export async function getHiddenGemsLeaderboard(): Promise<
  RumbleLeaderboardEntry[]
> {
  try {
    const authenticatedUser = await getAuthenticatedUser();
    const token = authenticatedUser?.session?.idToken;

    const getOperation = get({
      apiName: API_NAME,
      path: `/rumble/score`,
      options: {
        headers: {
          Authorization: token ? `${token}` : "",
        },
        queryParams: { mode: "hidden_gems" },
      },
    });
    const { body: resp } = await getOperation.response;
    return (await resp.json()) as any;
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("GET call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}
