export interface ContinentIconProps {
  fillColor: string;
  strokeColor: string;
  scale?: number; // Optional scale prop
}
const AfricaIcon = ({
  fillColor,
  strokeColor,
  scale = 1,
}: ContinentIconProps) => {
  return (
    <svg
      width={91 * scale}
      height={102 * scale}
      viewBox="0 0 91 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `scale(${scale})` }} // Apply scaling transformation
    >
      <path
        d="M45.0721 60.6176L45.3915 61.5852L45.7619 62.3629L46.0581 62.7842L46.5535 63.4647L47.4099 63.3582L47.8405 63.173L48.558 63.3582L48.7524 63.0342L49.0765 62.2749L49.882 62.224L49.9515 61.9972L50.6135 61.9925L50.5024 62.4601L52.0764 62.4509L52.0995 63.2703L52.3634 63.7749L52.1736 64.5619L52.2708 65.3674L52.706 65.8535L52.6365 67.4136L52.956 67.2932L53.5208 67.3256L54.3263 67.1312L54.9188 67.2099L55.0577 67.6173L54.9096 68.2561L55.1364 68.8765L54.942 69.3718L55.0531 69.8301L52.3495 69.8116L52.2893 74.0522L53.1643 75.154L54.0115 75.9965L51.6273 76.5474L48.4839 76.3576L47.5858 75.7095L42.3222 75.7697L42.1277 75.8669L41.3546 75.2558L40.5121 75.2142L39.7343 75.4456L39.1094 75.7049L38.989 74.8577L39.1696 73.6772L39.6186 72.4504L39.688 71.8764L40.1093 70.6773L40.4195 70.1357L41.1648 69.27L41.5815 68.6821L41.7157 67.7052L41.6463 66.9599L41.2574 66.4923L40.9102 65.6961L40.5908 64.9137L40.6602 64.6406L41.0583 64.1221L40.6649 62.8629L40.401 61.9925L39.7529 61.1731L39.8779 60.9232L40.4149 60.7472L40.7898 60.7704L41.2435 60.613L45.0721 60.6176ZM40.0214 60.3676L39.6927 60.5065L39.3455 59.5343L39.8686 58.9742L40.2621 58.7566L40.7482 59.201L40.276 59.4741L40.063 59.8075L40.0214 60.3676Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M20.2121 40.5351L19.3232 40.1972L18.7121 40.2481L18.2584 40.5768L17.6751 40.299L17.4483 39.8685L16.865 39.5814L16.777 38.8222L17.1335 38.2621L17.1011 37.8176L18.1335 36.7251L18.3233 35.8177L18.6797 35.4937L19.3093 35.6696L19.851 35.4011L20.0269 35.0585L21.0361 34.4659L21.2815 34.0493L22.4944 33.4938L23.2119 33.304L23.536 33.5586L24.3646 33.5539L24.2628 34.2021L24.4387 34.8085L25.1702 35.6742L25.2072 36.3131L26.7071 36.614L26.6747 37.5167L26.3923 37.9148L25.7581 38.0352L25.4942 38.6093L25.0498 38.7574L23.911 38.7296L23.3091 38.6278L22.8925 38.8407L22.3184 38.7435L20.0639 38.8037L20.0315 39.549L20.2121 40.5351Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M61.9648 58.8353L61.8815 57.2752L61.5528 56.6918L62.3444 56.7937L62.7426 56.0576L63.4323 56.1409L63.5064 56.6502L63.7842 56.9418L63.7981 57.3631L63.4786 57.6316L62.9694 58.3075L62.5018 58.7751L61.9648 58.8353Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M27.3738 44.9424L26.2998 45.0951L25.9803 44.197L26.0405 41.2064L25.7767 40.9379L25.7304 40.2944L25.2767 39.8361L24.8832 39.4519L25.0498 38.7575L25.4943 38.6093L25.7581 38.0353L26.3924 37.9149L26.6748 37.5168L27.1099 37.1325L27.5775 37.1279L28.5682 37.8871L28.5173 38.3269L28.8089 39.1047L28.5543 39.6324L28.6886 39.9843L28.059 40.7944L27.6608 41.1972L27.4155 42.0166L27.4479 42.8453L27.3738 44.9424Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M57.1733 77.3621L57.4325 77.6028L57.8446 78.3945L59.3121 79.899L59.8676 80.0472L59.8723 80.5333L60.2519 81.4129L61.2565 81.6258L62.0851 82.2554L60.2472 83.2831L59.0806 84.3294L58.6501 85.2692L58.2612 85.8015L57.5529 85.9173L57.3261 86.5978L57.1918 87.0422L56.3632 87.3756L55.3077 87.3061L54.6873 86.908L54.141 86.7321L53.5068 87.0654L53.1874 87.7505L52.5717 88.1811L51.9236 88.8246L50.993 88.9727L50.706 88.4681L50.8264 87.5885L50.0533 86.2321L49.7061 86.0191V81.9175L50.9838 81.8665L51.0208 76.9732L51.9884 76.9316L53.9883 76.4548L54.4882 77.0149L55.3123 76.4825L55.7104 76.4779L56.4419 76.1724L56.6733 76.2742L57.1733 77.3621Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M63.9045 48.5023L63.8258 50.0115L64.3442 50.1828L63.9276 50.6411L63.4276 50.9837L62.9323 51.6596L62.6592 52.2568L62.5851 53.2938L62.2842 53.7845L62.2749 54.7567L61.8999 55.1177L61.8536 55.8862L61.6731 55.9834L61.5527 56.6917L61.8814 57.2751L61.9648 58.8352L62.1962 60.0249L62.0666 60.7008L62.3258 61.4508L63.0804 62.1776L63.7795 63.8211L63.2702 63.6868L61.525 63.909L61.1777 64.0618L60.8074 64.8951L61.0991 65.4737L60.8676 67.0246L60.7055 68.344L61.0574 68.5801L61.9648 69.0893L62.3212 68.8532L62.4323 70.2791L61.437 70.2652L60.9046 69.5384L60.4278 68.9736L59.4324 68.7884L59.1408 68.0986L58.3445 68.5153L57.3076 68.3301L56.8724 67.7329L56.0483 67.6125L55.4419 67.6449L55.3678 67.2375L54.9188 67.2052L54.3262 67.1264L53.5253 67.3209L52.9605 67.2885L52.6365 67.4088L52.7059 65.8487L52.2754 65.3626L52.1782 64.5617L52.368 63.7748L52.1087 63.2702L52.0856 62.4554L50.5069 62.4646L50.6227 61.9971L49.9607 62.0017L49.8912 62.2285L49.0857 62.2795L48.757 63.034L48.5626 63.3627L47.845 63.1776L47.4191 63.3627L46.558 63.4646L46.0627 62.7841L45.7664 62.3628L45.3914 61.585L45.072 60.6175L41.2435 60.6036L40.7852 60.7564L40.4102 60.7332L39.8732 60.9091L39.6926 60.5064L40.0213 60.3675L40.063 59.8027L40.2759 59.4694L40.7481 59.2009L41.0907 59.3305L41.5351 58.8352L42.2388 58.8491L42.3221 59.2148L42.8082 59.4463L43.5721 58.6315L44.3267 58.0019L44.6554 57.5899L44.6137 56.5251L45.1785 55.2705L45.771 54.6085L46.6275 53.9882L46.7756 53.5762L46.8081 53.1039L47.021 52.6549L46.9516 51.9235L47.1136 50.78L47.3682 49.9745L47.7571 49.2847L47.8311 48.5069L47.9469 47.6042L48.4561 46.9468L49.1505 46.5302L50.2199 46.97L51.0439 47.4468L51.993 47.5764L52.9605 47.8264L53.3494 47.0487L53.5299 46.9468L54.1179 47.0765L55.5669 46.433L56.0761 46.7061L56.4974 46.6691L56.6918 46.3543L57.1733 46.2432L58.1501 46.3774L58.9834 46.4052L59.4139 46.2709L60.1963 47.3403L60.7796 47.4931L61.1268 47.2755L61.7286 47.3635L62.4508 47.0903L62.761 47.6412L63.9045 48.5023Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M43.711 43.4331L44.785 43.3313L45.0257 42.998L45.2387 43.0258L45.5627 43.3174L47.1969 42.8221L47.7478 42.3128L48.4283 41.8545L48.2987 41.3962L48.6644 41.2758L49.919 41.3592L51.1412 40.7527L52.0763 39.3222L52.7383 38.7945L53.5577 38.5676L53.7059 39.1324L54.4558 39.9518V40.4842L54.2475 41.0305L54.3308 41.4332L54.7799 41.8082L55.7706 42.3823L56.4789 42.9054L56.4928 43.3313L57.3631 44.0072L57.9047 44.5673L58.2334 45.3451L59.2056 45.859L59.4139 46.271L58.9834 46.4052L58.1501 46.3774L57.1733 46.2432L56.6918 46.3543L56.4974 46.6691L56.0761 46.7061L55.5669 46.433L54.1179 47.0765L53.5299 46.9469L53.3494 47.0487L52.9605 47.8265L51.993 47.5765L51.0439 47.4468L50.2199 46.97L49.1505 46.5302L48.4561 46.9469L47.9469 47.6043L47.8311 48.507L46.9978 48.4329L46.1182 48.2153L45.3451 48.9005L44.6646 50.1041L44.5257 49.7292L44.4702 49.1412L43.8776 48.7246L43.3961 48.0579L43.285 47.595L42.674 46.9191L42.7758 46.5348L42.6462 45.9886L42.748 44.984L43.0582 44.7479L43.711 43.4331Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M40.7482 59.2012L40.2621 58.7568L39.8686 58.9743L39.3455 59.5299L38.2807 58.1642L39.2668 57.4513L38.7807 56.5948L39.2251 56.2708L40.1001 56.1134L40.2019 55.5393L40.8963 56.1597L42.0444 56.2152L42.4425 55.6041L42.6046 54.7477L42.4611 53.7385L41.85 52.9792L42.4102 51.4839L42.0861 51.2293L41.1232 51.3312L40.7574 50.6692L40.8547 50.1044L42.4889 50.1553L43.5397 50.4933L44.5721 50.7988L44.6647 50.1044L45.3452 48.9007L46.1183 48.2156L46.9979 48.4332L47.8312 48.5072L47.7571 49.285L47.3682 49.9748L47.1136 50.7803L46.9516 51.9237L47.021 52.6552L46.8081 53.1042L46.7757 53.5764L46.6276 53.9885L45.7711 54.6088L45.1785 55.2708L44.6137 56.5254L44.6554 57.5901L44.3267 58.0022L43.5721 58.6318L42.8083 59.4465L42.3222 59.2151L42.2388 58.8493L41.5352 58.8355L41.0908 59.3308L40.7482 59.2012Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M20.1704 46.5813L19.5825 46.5952L18.6751 46.3406L17.8464 46.3545L16.3048 46.5813L15.4067 46.9563L14.1197 47.4285L13.8698 47.3961L13.967 46.3313L14.092 46.1693L14.0549 45.6554L13.504 45.1138L13.092 45.0258L12.7124 44.6694L12.9948 44.0953L12.8652 43.4657L12.9254 43.0861H13.1337L13.2078 42.5167L13.1059 42.2667L13.2309 42.0861L13.7124 41.9287L13.3929 40.8825L13.092 40.3455L13.1985 39.8964L13.4577 39.7992L13.6244 39.6788L13.9855 39.8733L14.9854 39.8872L15.2262 39.5029L15.4484 39.5307L15.8234 39.3826L16.0271 39.9427L16.328 39.7761L16.865 39.5816L17.4483 39.8687L17.6751 40.2992L18.2584 40.577L18.7121 40.2483L19.3232 40.1973L20.212 40.5353L20.5546 42.3917L20.0084 43.4842L19.6704 44.9518L20.2306 46.0674L20.1704 46.5813Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M40.8547 50.1042L40.6926 50.0348L39.9242 50.2015L39.1325 50.0255L38.5168 50.1135L36.4058 50.0811L36.5956 49.0626L36.0864 48.2108L35.4938 47.9886L35.2299 47.4099L34.8966 47.2247L34.9151 46.8683L35.2484 45.9517L35.8642 44.7017L36.2391 44.6878L37.0123 43.9286L37.5076 43.9101L38.2344 44.4425L39.1279 44.0027L39.2483 43.4657L39.5399 42.9379L39.739 42.2805L40.4334 41.7435L40.6973 40.8315L40.9704 40.5399L41.1556 39.8593L41.4982 39.0214L42.5907 38.0029L42.6601 37.5632L42.8037 37.3271L42.2898 36.7993L42.3314 36.3826L42.6972 36.304L43.211 37.1511L43.299 38.0261L43.2527 38.9011L43.9564 40.0908L43.2342 40.0769L42.8685 40.1695L42.2759 40.0399L41.9935 40.6556L42.7574 41.4195L43.3221 41.6417L43.5073 42.1833L43.9147 43.0768L43.711 43.4333L43.0583 44.748L42.7481 44.9841L42.6462 45.9887L42.7759 46.535L42.674 46.9192L43.2851 47.5951L43.3962 48.058L43.8777 48.7247L44.4702 49.1413L44.5258 49.7293L44.6647 50.1042L44.5721 50.7987L43.5397 50.4931L42.4889 50.1552L40.8547 50.1042Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M79.8019 36.5166L80.1075 36.924L80.0658 37.4749L79.3251 37.7897L79.8852 38.1462L79.4038 38.8498L79.1168 38.6184L78.8066 38.7109L78.0798 38.6878L78.0566 38.2897L77.9594 37.9239L78.3946 37.3082L78.8529 36.7249L79.4084 36.8407L79.8019 36.5166Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M39.4566 21.8694L35.0077 24.5313L31.2486 27.2395L29.42 27.8506L27.9802 27.9849L27.9664 27.1146L27.3645 26.8923L26.5544 26.4988L26.2488 25.8554L21.8694 22.8231L17.49 19.7445L12.606 16.2586L12.6337 15.9762V15.879L12.6198 14.1429L14.717 13.0504L16.0132 12.8235L17.0733 12.4254L17.5733 11.6754L19.0918 11.0783L19.1473 9.96256L19.8973 9.82832L20.4852 9.26815L22.1842 9.0089L22.4203 8.41634L22.0777 8.08765L21.6287 6.45347L21.5546 5.50444L21.0639 4.49522L22.3092 3.62952L23.7166 3.35176L24.536 2.68975L25.7859 2.20367L27.9849 1.91665L30.1329 1.78239L30.7857 2.02312L32.0078 1.38889L33.3967 1.375L33.9244 1.74998L34.8086 1.65277L34.5448 2.48144L34.7531 3.99988L34.4475 5.30537L33.6467 6.1757L33.7624 7.34694L34.8225 8.26357L34.8364 8.63855L35.6327 9.25426L36.1882 11.9671L36.6095 13.2819L36.6789 13.967L36.4521 15.166L36.5447 15.8327L36.378 16.6289L36.4937 17.5409L35.9752 18.1381L36.7437 19.1844L36.7947 19.7954L37.253 20.5871L37.8594 20.3278L38.8872 20.9852L39.4566 21.8694Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M69.2098 13.1661L68.8441 13.7633L68.5664 14.8744L68.2145 15.6336L67.909 15.8928L67.4785 15.4206L66.8905 14.7633L65.9646 12.6476L65.835 12.7819L66.372 14.342L67.1683 15.8141L68.1497 18.0733L68.6265 18.851L69.0432 19.6566L70.2098 21.2306L69.9506 21.4759L69.9922 22.3879L71.506 23.6425L71.7329 23.9295H66.585H61.5482H56.3308V18.7307V13.555L55.942 12.3606L56.2753 11.4347L56.0762 10.7912L56.5438 10.0644L58.2705 10.0413L59.5205 10.4394L60.8075 10.8884L61.4093 11.1199L62.4092 10.6431L62.9416 10.2125L64.0897 10.0876L65.011 10.2774L65.3674 11.0273L65.6684 10.532L66.7053 10.8884L67.7192 10.9764L68.3581 10.5968L69.2098 13.1661Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M78.8529 36.7252L78.4085 36.2946L77.8761 35.5215L77.3021 35.0956L76.9641 34.6327L75.8345 34.1003L74.9457 34.0864L74.6309 33.804L73.8716 34.1188L73.0846 33.5123L72.6772 34.5077L71.1681 34.2299L71.0292 33.6975L71.5893 31.73L71.7143 30.8365L72.1217 30.4199L73.08 30.1977L73.7374 29.4246L74.492 30.9893L74.8484 32.2254L75.5614 32.8781L77.3298 34.1373L78.052 34.8965L78.7557 35.665L79.1631 36.1187L79.8019 36.5168L79.4084 36.8409L78.8529 36.7252Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M73.0847 33.5125L73.8717 34.1189L74.6309 33.8041L74.9457 34.0865L75.8345 34.1004L76.9641 34.6328L77.3021 35.0957L77.8761 35.5216L78.4085 36.2947L78.8529 36.7253L78.3946 37.3086L77.9594 37.9243L78.0567 38.29L78.0798 38.6881L78.8066 38.7113L79.1168 38.6187L79.4038 38.8502L79.1214 39.3177L79.6029 40.0399L80.0797 40.6695L80.575 41.1371L84.8202 42.6833L85.9128 42.6741L82.2417 46.572L80.5519 46.6276L79.3946 47.5396L78.5659 47.5627L78.2094 47.9701H77.3206L76.7975 47.5349L75.6123 48.0766L75.2281 48.6136L74.3624 48.5118L74.0754 48.3636L73.7698 48.396L73.3624 48.3868L71.719 47.285H70.8163L70.3718 46.8637V46.1369L69.6959 45.9193L68.9321 44.5073L68.3395 44.2064L68.108 43.6879L67.4507 43.0537L66.6544 42.9611L67.0988 42.2157L67.784 42.1833L67.9784 41.7852L67.9645 40.614L68.3488 39.2437L68.9599 38.8733L69.0941 38.3363L69.6496 37.3317L70.432 36.6743L70.9598 35.3735L71.1681 34.23L72.6773 34.5078L73.0847 33.5125Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M38.2807 58.1642L36.9474 56.8587L36.0864 55.7939L35.2994 54.4606L35.341 54.0347L35.6234 53.6181L35.9382 52.6829L36.2021 51.7246L36.6419 51.6506L38.5261 51.6645L38.5168 50.1136L39.1325 50.0256L39.9242 50.2016L40.6926 50.0349L40.8547 50.1043L40.7574 50.6691L41.1232 51.3311L42.0861 51.2293L42.4102 51.4839L41.85 52.9792L42.4611 53.7384L42.6046 54.7476L42.4425 55.6041L42.0444 56.2152L40.8963 56.1596L40.2019 55.5393L40.1001 56.1133L39.2251 56.2707L38.7807 56.5948L39.2668 57.4512L38.2807 58.1642Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M25.2581 45.3684L23.2212 46.1276L22.499 46.572L21.3277 46.947L20.1704 46.5813L20.2306 46.0674L19.6704 44.9517L20.0084 43.4842L20.5546 42.3916L20.212 40.5352L20.0315 39.5492L20.0639 38.8038L22.3184 38.7437L22.8925 38.8409L23.3091 38.6279L23.911 38.7298L23.8137 39.1418L24.3554 39.8177V40.7667L24.4804 41.7944L24.8044 42.2713L24.5174 43.4425L24.6193 44.0906L24.9665 44.9147L25.2581 45.3684Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M2.01849 35.9197L2.18516 35.3318L3.59713 35.2901L3.8934 34.9799L4.30543 34.9568L4.81466 35.2855L5.21742 35.2901L5.64795 35.0679L5.9072 35.4475L5.34704 35.7484L4.78226 35.7253L4.22672 35.4429L3.74527 35.7484L3.5138 35.7623L3.19899 35.9475L2.01849 35.9197Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M12.9254 43.086L12.5597 43.0536L12.2958 43.5768L11.9254 43.5721L11.6755 43.2944L11.7588 42.7712L11.2171 41.975L10.8792 42.1185L10.6014 42.1509L10.245 42.225L10.2588 41.7481L10.0505 41.4056L10.0922 41.0259L9.80979 40.475L9.44869 40.0075H8.41171L8.1108 40.2528L7.74971 40.2806L7.52749 40.563L7.37934 40.9287L6.68493 41.5028L6.11552 40.7297L5.61092 40.2158L5.2776 40.0491L4.95353 39.7853L4.81002 39.2066L4.62022 38.9196L4.2406 38.702L4.81928 38.0631L5.21278 38.0863L5.55073 37.8641H5.83775L6.04145 37.6881L5.93035 37.253L6.07386 37.1141L6.097 36.665L6.72197 36.6789L7.65711 37.003L7.94413 36.9706L8.04136 36.8271L8.74503 36.9289L8.93483 36.8548L9.00891 37.3409L9.21722 37.3363L9.55517 37.1604L9.76812 37.2067L10.1292 37.54L10.6848 37.6465L11.0412 37.3595L11.4625 37.1835L11.7727 36.9984L12.0319 37.0354L12.3189 37.3224L12.4763 37.6881L13.0087 38.239L12.7402 38.577L12.6893 39.0029L12.9671 38.8733L13.1291 39.0307L13.0596 39.4195L13.4578 39.7991L13.1985 39.8964L13.0921 40.3454L13.393 40.8824L13.7124 41.9287L13.2309 42.0861L13.1059 42.2666L13.2078 42.5166L13.1337 43.086H12.9254Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M36.2021 51.7247L35.9567 51.5303L36.4058 50.0813L38.5168 50.1137L38.5261 51.6646L36.6419 51.6507L36.2021 51.7247Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M4.24061 38.7019L3.5462 38.151L2.99993 38.0676L2.70365 37.6927L2.70827 37.4936L2.31479 37.2158L2.23145 36.9334L2.92124 36.7158L3.35177 36.7575L3.69898 36.6094L6.09701 36.6649L6.07387 37.114L5.93036 37.2529L6.04146 37.688L5.83776 37.8639H5.55074L5.21279 38.0861L4.81929 38.063L4.24061 38.7019Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M77.0891 54.1364L77.8575 55.1965L76.9502 55.715L76.6308 56.252L76.14 56.3446L75.9595 57.2566L75.5428 57.7751L75.2882 58.6362L74.7651 59.0621L72.9041 57.7705L72.8115 57.0205L68.108 54.3956L67.8858 54.2521L67.8765 52.8865L68.2469 52.3633L68.8857 51.5115L69.3579 50.5717L68.7885 49.0903L68.6358 48.4422L68.02 47.5441L68.8163 46.771L69.6959 45.9192L70.3718 46.1368V46.8636L70.8162 47.2849H71.719L73.3624 48.3867L73.7698 48.3959L74.0753 48.3635L74.3623 48.5117L75.228 48.6135L75.6123 48.0765L76.7974 47.5349L77.3205 47.97H78.2094L77.0752 49.4375L77.0891 54.1364Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M13.8697 47.3961L13.5272 47.4054L12.1892 46.7897L11.0134 45.8036L9.90695 45.0953L9.03198 44.2574L9.34216 43.8408L9.4116 43.4658L9.99491 42.7575L10.6014 42.151L10.8791 42.1186L11.2171 41.9751L11.7587 42.7714L11.6754 43.2945L11.9254 43.5722L12.2957 43.5769L12.5596 43.0537L12.9253 43.0862L12.8651 43.4658L12.9948 44.0954L12.7124 44.6694L13.092 45.0259L13.504 45.1138L14.0549 45.6555L14.0919 46.1693L13.9669 46.3314L13.8697 47.3961Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M61.4925 92.0836L61.9462 92.528L61.5481 93.2502L61.3259 93.7363L60.6037 93.9677L60.363 94.4492L59.9 94.5973L58.9279 93.4446L59.6176 92.5048L60.3213 91.9261L60.9278 91.6299L61.4925 92.0836Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M43.1554 22.7257L42.2388 23.2442L41.5074 22.4758L39.4565 21.8693L38.8871 20.9851L37.8594 20.3277L37.2529 20.587L36.7946 19.7953L36.7437 19.1842L35.9752 18.138L36.4937 17.5408L36.378 16.6288L36.5446 15.8326L36.452 15.1659L36.6789 13.9669L36.6094 13.2817L36.1882 11.967L36.8224 11.6198L36.9335 10.9809L36.7946 10.3559L37.6881 9.77265L38.0862 9.28656L38.7205 8.84677L38.7945 7.66626L40.3176 8.19864L40.8639 8.0644L41.9472 8.32364L43.6693 9.00417L44.2758 10.3559L45.4424 10.6522L47.271 11.2818L48.6552 12.0272L49.2894 11.6383L49.9097 10.9485L49.6089 9.78654L50.0162 9.04583L50.9514 8.32826L51.8448 8.11995L53.5994 8.43474L54.0438 9.1199L54.5253 9.12452L54.9373 9.38377L56.2289 9.56432L56.5437 10.0643L56.0761 10.7911L56.2752 11.4346L55.9419 12.3605L56.3307 13.5549V18.7306V23.9294V26.6885L54.8401 26.6932L54.8216 27.2672L49.6459 24.6284L44.4656 21.9573L43.1554 22.7257Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M22.0777 8.08787L21.6287 6.45368L21.5546 5.50466L21.0639 4.49543L20.4991 4.47693L19.1566 4.12972L17.9205 4.24082L17.1381 3.56493L16.1845 3.55566L15.7725 4.53248L14.9068 6.15741L13.9438 6.80089L12.643 7.50919L11.8097 8.54617L11.6338 9.3517L11.1384 10.6572L11.4625 12.5229L10.3792 13.7635L9.73107 14.157L8.70798 15.1616L7.49969 15.3236L6.84694 15.8838L6.8238 15.9023L5.99977 17.3837L5.13869 17.9115L4.66649 18.7957L4.63872 19.5596L4.29151 20.3882L3.85635 20.6151L3.13416 21.5132L2.68974 22.5085L2.77306 22.9807L2.34715 23.7075L1.84718 24.0871L1.787 24.7306L1.73145 25.3185L2.01383 24.8556L7.09694 24.8649L6.85158 22.8511L7.17101 22.1335L8.38391 22.0085L8.34225 18.3698L12.6059 18.4485V16.2588L12.6337 15.9764V15.8792L12.6198 14.1431L14.7169 13.0506L16.0132 12.8238L17.0733 12.4256L17.5733 11.6757L19.0918 11.0785L19.1473 9.96277L19.8973 9.82853L20.4852 9.26836L22.1842 9.00911L22.4203 8.41655L22.0777 8.08787Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M88.1904 69.2376L88.533 69.7978L88.8524 70.6727L89.0654 72.2745L89.3987 72.8995L89.2691 73.5384L89.0423 73.9365L88.5978 73.1495L88.3571 73.5476L88.6025 74.5383L88.4867 75.1124L88.1303 75.4225L88.0469 76.5706L87.5377 78.1539L86.8988 80.0473L86.0933 82.6722L85.598 84.6212L85.01 86.2646L83.9545 86.6025L82.8203 87.209L82.075 86.8433L81.0426 86.3341L80.6862 85.5841L80.5982 84.3295L80.1445 83.2092L80.0242 82.2046L80.2556 81.2047L80.8528 80.9639L80.8575 80.5056L81.4778 79.4594L81.5935 78.589L81.2926 77.9409L81.0473 77.0845L80.9454 75.8346L81.3991 75.0799L81.5704 74.2235L82.2185 74.1772L82.9453 73.8994L83.4221 73.6587L83.9962 73.6402L84.7323 72.8763L85.8017 72.0523L86.1905 71.3857L86.0146 70.8163L86.5655 70.9783L87.2831 70.057L87.3062 69.2608L87.7368 68.6682L88.1904 69.2376Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M8.08301 33.9706L8.51817 33.7299L8.73576 32.9429L9.14777 32.9104L10.0551 33.2808L10.7866 33.0169L11.2866 33.1049L11.4856 32.8086L16.6937 32.7901L16.9807 31.8503L16.7539 31.6837L16.1289 25.8136L15.504 19.7675L17.49 19.7444L21.8694 22.823L26.2488 25.8552L26.5544 26.4987L27.3645 26.8922L27.9663 27.1144L27.9802 27.9848L29.42 27.8505L29.4246 30.9754L28.7117 31.8735L28.6006 32.7021L27.4478 32.9104L25.6794 33.0262L25.198 33.503L24.3647 33.5539L23.536 33.5586L23.2119 33.3039L22.4944 33.4938L21.2815 34.0493L21.0361 34.4659L20.0269 35.0585L19.851 35.4011L19.3093 35.6696L18.6797 35.4937L18.3233 35.8177L18.1335 36.7251L17.1011 37.8176L17.1335 38.262L16.777 38.8222L16.865 39.5814L16.328 39.7759L16.0271 39.9425L15.8234 39.3824L15.4484 39.5305L15.2262 39.5027L14.9855 39.887L13.9855 39.8731L13.6244 39.6786L13.4578 39.799L13.0596 39.4194L13.1291 39.0305L12.967 38.8731L12.6893 39.0028L12.7402 38.5769L13.0087 38.2389L12.4763 37.688L12.3189 37.3223L12.0319 37.0353L11.7727 36.9982L11.4625 37.1834L11.0412 37.3593L10.6847 37.6463L10.1292 37.5399L9.76811 37.2065L9.55516 37.1602L9.21721 37.3362L9.00889 37.3408L8.93482 36.8547L8.995 36.4427L8.88389 35.9335L8.3978 35.5585L8.14319 34.7993L8.08301 33.9706Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M8.083 33.9707L7.22656 33.0541L6.43955 32.068L5.57849 31.7115L4.95815 31.318L4.23133 31.3319L3.60173 31.6236L2.9536 31.5078L2.50918 31.9384L2.39808 31.2162L2.75918 30.5495L2.92121 29.2765L2.7777 27.9293L2.62029 27.2488L2.74991 26.5683L2.4166 25.9109L1.73145 25.3183L2.01383 24.8554L7.09694 24.8646L6.85158 22.8508L7.17101 22.1333L8.38391 22.0083L8.34225 18.3696L12.6059 18.4483V16.2585L17.49 19.7445L15.504 19.7676L16.1289 25.8137L16.7539 31.6838L16.9807 31.8504L16.6937 32.7902L11.4856 32.8087L11.2866 33.105L10.7866 33.017L10.0551 33.2809L9.14776 32.9106L8.73575 32.943L8.51817 33.73L8.083 33.9707Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M68.7376 67.983L68.3765 68.983L68.7376 70.7051L69.1913 70.6866L69.6589 71.1125L70.2005 72.0754L70.3116 73.7976L69.7514 74.08L69.3533 75.0105L68.5061 74.1818L68.4135 73.2374L68.6867 72.6124L68.608 72.0801L68.0987 71.7421L67.7376 71.8625L66.9923 71.2236L66.3118 70.881L66.7053 69.6496L67.1127 69.1913L66.8627 68.0988L67.1219 67.034L67.3441 66.6775L67.0154 65.5665L66.4044 64.9832L67.6728 65.2239L67.9367 65.585L68.3765 66.1961L68.7376 67.983Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M68.7376 67.9832L69.7144 67.8767L71.2745 68.2471L71.6171 68.0804L72.5198 68.048L72.9828 67.6545L73.7605 67.673L75.1771 67.1638L76.2095 66.4045L76.4224 66.9925L76.3669 68.3026L76.5289 69.46L76.5798 71.5339L76.8067 72.1821L76.4224 73.1403L75.9178 74.0709L75.0892 74.9042L73.904 75.418L72.4411 76.0707L70.9736 77.529L70.4736 77.779L69.5663 78.7466L69.0339 79.066L68.9228 80.0474L69.5385 81.089L69.7931 81.9038L69.8116 82.3205L70.0385 82.251L70.0014 83.6213L69.7931 84.2741L70.0987 84.5148L69.9042 85.1027L69.3626 85.6074L68.2932 86.0888L66.7331 86.8573L66.1637 87.3897L66.2748 87.9915L66.6034 88.0887L66.4923 88.848L65.5109 88.8387L65.3998 88.1998L65.2054 87.5563L65.0943 87.0425L65.3257 85.4546L64.9878 84.45L64.3674 82.4779L65.7331 80.8993L66.0757 79.9039L66.2748 79.7789L66.4183 78.9734L66.2099 78.566L66.2655 77.5475L66.5201 76.6031L66.5155 74.8949L65.8442 74.4597L65.2239 74.3625L64.9461 74.0292L64.3443 73.7468L63.261 73.7746L63.1777 73.2746L63.0527 72.3256L66.9923 71.2238L67.7376 71.8626L68.0987 71.7423L68.608 72.0802L68.6867 72.6126L68.4135 73.2376L68.5061 74.182L69.3533 75.0106L69.7515 74.0801L70.3116 73.7977L70.2005 72.0756L69.6589 71.1127L69.1913 70.6868L68.7376 70.7053L68.3765 68.9831L68.7376 67.9832Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M45.0952 91.5235L44.1322 90.4171L43.623 89.3523L43.336 87.9496L43.0166 86.908L42.5814 84.7229L42.5536 83.0424L42.3869 82.2832L41.8823 81.7092L41.2111 80.5657L40.5305 78.913L40.2481 78.0566L39.188 76.7279L39.1093 75.6863L39.7343 75.4317L40.512 75.2002L41.3546 75.2419L42.1277 75.8529L42.3221 75.7557L47.5858 75.7002L48.4839 76.3483L51.6273 76.5381L54.0114 75.9872L55.0762 75.677L55.9187 75.7557L56.428 76.0613L56.4419 76.1724L55.7104 76.4779L55.3123 76.4825L54.4882 77.0149L53.9882 76.4548L51.9883 76.9316L51.0208 76.9733L50.9838 81.8666L49.706 81.9175V86.0192L49.7014 91.3522L48.5441 92.1068L47.8497 92.2133L47.0303 91.9355L46.4469 91.8291L46.2294 91.1995L45.7155 90.7967L45.0952 91.5235Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M26.6748 37.5171L26.7071 36.6143L25.2072 36.3134L25.1702 35.6745L24.4387 34.8089L24.2628 34.2024L24.3647 33.5543L25.198 33.5034L25.6794 33.0265L27.4479 32.9108L28.6006 32.7025L28.7117 31.8738L29.4246 30.9757L29.42 27.8508L31.2486 27.2398L35.0077 24.5315L39.4566 21.8696L41.5074 22.4761L42.2388 23.2446L43.1555 22.7261L43.4749 24.888L43.961 25.2491L43.9841 25.6889L44.5212 26.1611L44.2387 26.7537L43.7388 29.5221L43.674 31.2766L42.0166 32.5451L41.4565 34.3042L41.9981 34.795L41.9935 35.6514L42.8268 35.6838L42.6972 36.3042L42.3314 36.3828L42.2898 36.7995L42.0444 36.8319L41.1694 35.3829L40.8639 35.3273L39.8501 36.0681L38.8455 35.6838L38.1464 35.6051L37.7715 35.7903L37.0076 35.7533L36.2437 36.318L35.5817 36.3504L34.0124 35.6653L33.3967 35.9893L32.7347 35.9662L32.2486 35.4662L30.9431 34.9709L29.5496 35.1283L29.2117 35.4153L29.0311 36.1792L28.6561 36.7115L28.5682 37.8874L27.5775 37.1282L27.1099 37.1328L26.6748 37.5171Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M34.9151 46.8683L33.5679 47.3312L33.0726 47.2664L32.5726 47.5535L31.5356 47.5257L30.8412 46.7155L30.4153 45.7804L29.4941 44.9286L28.5172 44.9425H27.3738L27.4479 42.8453L27.4155 42.0167L27.6608 41.1973L28.0589 40.7945L28.6885 39.9844L28.5543 39.6325L28.8089 39.1048L28.5172 38.327L28.5682 37.8872L28.6561 36.7114L29.0311 36.179L29.2117 35.4151L29.5496 35.1281L30.9431 34.9707L32.2486 35.4661L32.7347 35.966L33.3967 35.9892L34.0124 35.6651L35.5817 36.3503L36.2437 36.3179L37.0076 35.7531L37.7715 35.7901L38.1464 35.6049L38.8455 35.6836L39.8501 36.0679L40.8639 35.3272L41.1694 35.3827L42.0444 36.8317L42.2898 36.7993L42.8036 37.3271L42.6601 37.5632L42.5907 38.003L41.4981 39.0214L41.1556 39.8594L40.9704 40.5399L40.6972 40.8315L40.4334 41.7435L39.739 42.2806L39.5399 42.9379L39.2482 43.4657L39.1279 44.0027L38.2344 44.4425L37.5076 43.9101L37.0122 43.9286L36.2391 44.6878L35.8641 44.7017L35.2484 45.9517L34.9151 46.8683Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M63.3629 54.4929L63.8814 55.2197L63.8027 55.979L63.4323 56.141L62.7426 56.0577L62.3444 56.7937L61.5528 56.6919L61.6732 55.9836L61.8537 55.8864L61.9 55.1179L62.275 54.7568L62.5898 54.8911L63.3629 54.4929Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M67.9645 40.7666L67.784 40.7435L67.8071 40.0907L67.6497 39.6417L66.9831 39.1232L66.8257 38.1741L66.9831 37.202L66.3812 37.114L66.2933 37.4057L65.5109 37.4751L65.8257 37.8594L65.9368 38.651L65.2239 39.3732L64.5758 40.3176L63.9091 40.4518L62.8166 39.688L62.3259 39.9565L62.1916 40.3407L61.525 40.5861L61.4787 40.8546H60.1871L60.0065 40.5861L59.0714 40.5398L58.6038 40.7666L58.2474 40.6509L57.5807 39.887L57.3585 39.5306L56.4187 39.7111L56.0623 40.3176L55.729 41.4842L55.2846 41.7296L54.8864 41.8731L54.7799 41.8082L54.3309 41.4333L54.2475 41.0305L54.4559 40.4842V39.9519L53.7059 39.1324L53.5578 38.5677L53.5717 38.2482L53.0948 37.864L53.0809 37.0955L52.8124 36.5863L52.3541 36.665L52.4837 36.1789L52.8217 35.6234L52.6736 35.0771L53.0995 34.6697L52.8309 34.3595L53.1735 33.5355L53.7661 32.5494L54.8864 32.642L54.8216 27.2672L54.8401 26.6932L56.3308 26.6886V23.9294H61.5481H66.5849H71.7329L72.1495 25.2905L71.8671 25.5405L72.0569 26.9571L72.5337 28.5866L73.0244 28.9246L73.7374 29.4246L73.08 30.1977L72.1217 30.4199L71.7143 30.8365L71.5893 31.73L71.0292 33.6975L71.1681 34.2299L70.9597 35.3734L70.432 36.6742L69.6496 37.3316L69.0941 38.3362L68.9598 38.8732L68.3488 39.2435L67.9645 40.6139V40.7666Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M9.03205 44.2574L8.68023 44.1602L7.74971 43.637L7.07382 42.9426L6.84697 42.4658L6.68494 41.5029L7.37935 40.9288L7.52749 40.5631L7.74971 40.2807L8.1108 40.2529L8.41171 40.0076H9.4487L9.80979 40.4751L10.0922 41.026L10.0505 41.4056L10.2588 41.7482L10.245 42.225L10.6014 42.151L9.99498 42.7574L9.41167 43.4657L9.34223 43.8407L9.03205 44.2574Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M2.18514 35.3318L1.64813 34.2948L1 33.8226L1.57406 33.568L2.20365 32.6282L2.50919 31.9385L2.95361 31.5079L3.60174 31.6237L4.23134 31.332L4.95816 31.3181L5.5785 31.7116L6.43956 32.0681L7.22657 33.0541L8.08301 33.9708L8.1432 34.7994L8.39781 35.5587L8.88389 35.9336L8.99501 36.4429L8.93482 36.8549L8.74502 36.929L8.04135 36.8271L7.94412 36.9706L7.6571 37.003L6.72196 36.679L6.09699 36.6651L3.69896 36.6095L3.35175 36.7577L2.92122 36.716L2.23143 36.9336L2.01848 35.9198L3.19897 35.9475L3.51378 35.7624L3.74525 35.7485L4.2267 35.4429L4.78224 35.7253L5.34703 35.7485L5.90718 35.4476L5.64794 35.0679L5.2174 35.2902L4.81465 35.2855L4.30541 34.9568L3.89338 34.98L3.59711 35.2902L2.18514 35.3318Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M90.2551 37.7715L90.2227 37.4058L89.732 37.4104L89.1162 37.8641L88.4265 37.9937L87.8293 38.1881L87.4173 38.2159L86.6766 38.2622L86.2136 38.5029L85.5701 38.5909L84.4266 38.9983L83.0147 39.151L81.7879 39.489L81.1444 39.4844L80.5611 38.9335L80.3065 38.3918L79.8852 38.1465L79.4037 38.8501L79.1214 39.3177L79.6028 40.0399L80.0796 40.6695L80.575 41.1371L84.8202 42.6833L85.9127 42.674L82.2416 46.572L80.5518 46.6275L79.3945 47.5395L78.5658 47.5627L78.2093 47.9701L77.0751 49.4376L77.089 54.1365L77.8575 55.1966L78.1492 54.8911L78.4501 54.2152L79.8713 52.6504L81.0796 51.669L83.0239 50.3913L84.3202 49.345L85.8479 47.5812L86.9543 46.1322L88.07 44.2341L88.8709 42.5722L89.4959 41.1139L89.8616 39.7019L90.1394 39.2297L90.1347 38.5353L90.2551 37.7715Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M67.9645 40.7666L67.9784 41.7851L67.784 42.1832L67.0988 42.2156L66.6544 42.961L67.4507 43.0535L68.108 43.6878L68.3395 44.2063L68.9321 44.5072L69.6959 45.9192L68.8163 46.771L68.0201 47.5441L67.2192 48.1366H66.3072L65.2609 48.4376L64.4369 48.1459L63.9045 48.5024L62.7611 47.6413L62.4509 47.0904L61.7287 47.3635L61.1269 47.2756L60.7797 47.4932L60.1964 47.3404L59.414 46.271L59.2057 45.859L58.2335 45.3451L57.9048 44.5674L57.3632 44.0072L56.4928 43.3313L56.4789 42.9054L55.7706 42.3823L54.8864 41.8731L55.2846 41.7295L55.729 41.4842L56.0623 40.3176L56.4187 39.7111L57.3585 39.5306L57.5807 39.887L58.2474 40.6509L58.6038 40.7666L59.0714 40.5398L60.0065 40.5861L60.1871 40.8546H61.4787L61.525 40.5861L62.1916 40.3407L62.3259 39.9565L62.8166 39.688L63.9091 40.4518L64.5758 40.3176L65.2239 39.3732L65.9368 38.651L65.8257 37.8593L65.5109 37.4751L66.2933 37.4057L66.3813 37.114L66.9831 37.202L66.8257 38.1741L66.9831 39.1232L67.6497 39.6417L67.8071 40.0907L67.784 40.7435L67.9645 40.7666Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M65.5109 88.8384L65.247 89.4819L64.4878 89.6347L63.71 88.8523L63.7008 88.3524L64.0526 87.8107L64.1776 87.3941L64.5526 87.2922L65.2054 87.5561L65.3998 88.1996L65.5109 88.8384Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M42.6972 36.3038L42.8268 35.6835L41.9935 35.6511L41.9981 34.7946L41.4565 34.3039L42.0167 32.5448L43.674 31.2763L43.7388 29.5217L44.2388 26.7534L44.5212 26.1608L43.9842 25.6886L43.961 25.2488L43.4749 24.8877L43.1555 22.7258L44.4656 21.9573L49.6459 24.6285L54.8216 27.2672L54.8864 32.642L53.7661 32.5494L53.1736 33.5354L52.831 34.3595L53.0995 34.6697L52.6736 35.077L52.8217 35.6233L52.4838 36.1788L52.3541 36.6649L52.8125 36.5862L53.081 37.0955L53.0948 37.864L53.5717 38.2482L53.5578 38.5676L52.7384 38.7945L52.0764 39.3222L51.1412 40.7527L49.9191 41.3592L48.6645 41.2758L48.2988 41.3962L48.4284 41.8545L47.7479 42.3128L47.197 42.8221L45.5628 43.3174L45.2387 43.0257L45.0258 42.998L44.785 43.3313L43.711 43.4331L43.9147 43.0767L43.5073 42.1832L43.3221 41.6416L42.7574 41.4193L41.9935 40.6555L42.2759 40.0398L42.8685 40.1694L43.2342 40.0768L43.9564 40.0907L43.2527 38.9009L43.299 38.026L43.211 37.151L42.6972 36.3038Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M26.2998 45.0952L25.2582 45.3683L24.9665 44.9146L24.6193 44.0906L24.5175 43.4425L24.8045 42.2712L24.4804 41.7944L24.3554 40.7667V39.8177L23.8138 39.1418L23.911 38.7297L25.0498 38.7575L24.8832 39.4519L25.2767 39.8362L25.7304 40.2945L25.7767 40.938L26.0405 41.2065L25.9804 44.1971L26.2998 45.0952Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M36.1882 11.9671L35.6327 9.25424L34.8364 8.63854L34.8225 8.26355L33.7624 7.34692L33.6467 6.17568L34.4475 5.30535L34.7531 3.99987L34.5448 2.48142L34.8086 1.65275L36.2253 1L37.1326 1.19444L37.0956 2.01384L38.1974 1.41664L38.29 1.72682L37.6372 2.51845L37.6326 3.25916L38.0816 3.65266L37.9104 5.02296L37.0539 5.81459L37.2993 6.66178L37.9705 6.68956L38.2992 7.42563L38.7946 7.66636L38.7205 8.84686L38.0863 9.28665L37.6881 9.77275L36.7947 10.356L36.9336 10.981L36.8224 11.6199L36.1882 11.9671Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M67.8858 54.2522L68.108 54.3957L72.8115 57.0206L72.9041 57.7706L74.7651 59.0622L74.1679 60.6593L74.242 61.3954L75.0753 61.8676L75.1123 62.2055L74.7559 62.9925L74.8299 63.386L74.7466 64.011L75.2003 64.8258L75.7373 66.1174L76.2095 66.4044L75.1771 67.1636L73.7605 67.6729L72.9828 67.6544L72.5198 68.0478L71.6171 68.0803L71.2745 68.2469L69.7144 67.8766L68.7376 67.983L68.3765 66.1961L67.9367 65.585L67.6728 65.2239L66.4044 64.9832L65.6637 64.5897L64.8396 64.3721L64.3211 64.1499L63.7795 63.8212L63.0805 62.1778L62.3259 61.4509L62.0666 60.701L62.1963 60.0251L61.9648 58.8353L62.5018 58.7751L62.9694 58.3076L63.4786 57.6317L63.798 57.3632L63.7841 56.9419L63.5064 56.6502L63.4323 56.141L63.8026 55.979L63.8813 55.2197L63.3628 54.4929L63.8212 54.3355L65.2424 54.354L67.8858 54.2522Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M65.2424 54.3541L63.8212 54.3355L63.3629 54.4929L62.5898 54.8911L62.275 54.7568L62.2842 53.7846L62.5851 53.2939L62.6592 52.2569L62.9323 51.6597L63.4277 50.9838L63.9277 50.6413L64.3443 50.1829L63.8258 50.0117L63.9045 48.5025L64.4369 48.146L65.2609 48.4377L66.3072 48.1368H67.2192L68.0201 47.5442L68.6358 48.4423L68.7886 49.0904L69.358 50.5718L68.8858 51.5116L68.2469 52.3634L67.8765 52.8865L67.8858 54.2522L65.2424 54.3541Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M64.7934 92.528L64.5388 92.741L63.9879 93.4956L63.6268 94.2641L62.8907 95.3427L61.4231 96.9074L60.5065 97.8241L59.5251 98.5231L58.1687 99.125L57.5067 99.2036L57.34 99.6342L56.553 99.4027L55.9095 99.699L54.5022 99.3981L53.7152 99.5879L53.1782 99.5046L51.8403 100.12L50.7338 100.37L49.933 100.963L49.3404 101L48.7895 100.44L48.3497 100.412L47.7895 99.7129L47.7294 99.9305L47.5581 99.5092L47.5673 98.6018L47.146 97.5695L47.5627 97.2917L47.5303 96.1204L46.6785 94.7085L46.0257 93.44V93.4354L45.0952 91.5142L45.7155 90.7874L46.2294 91.1901L46.447 91.8197L47.0303 91.9262L47.8451 92.204L48.5441 92.0975L49.7015 91.3429V86.0098L50.0533 86.2228L50.8218 87.5792L50.7014 88.4541L50.9931 88.9634L51.9236 88.8152L52.5717 88.1717L53.1874 87.7412L53.5069 87.0561L54.1411 86.7227L54.6874 86.8987L55.3077 87.3014L56.3632 87.3709L57.1919 87.0375L57.3215 86.5931L57.5483 85.9126L58.2566 85.7969L58.6455 85.2645L59.0761 84.3247L60.2426 83.2784L62.0805 82.2507L62.6083 82.2646L63.2379 82.5007L63.6731 82.3341L64.3628 82.4729L64.9832 84.4451L65.3211 85.4496L65.0897 87.0375L65.2008 87.5514L64.5434 87.2875L64.1684 87.3894L64.048 87.806L63.6916 88.3477L63.7055 88.8476L64.4786 89.6347L65.2378 89.4772L65.5017 88.8337L66.4877 88.8476L66.1637 89.9031L66.0109 91.116L65.673 91.7781L64.7934 92.528ZM61.4926 92.0836L60.9278 91.6299L60.3213 91.9308L59.6177 92.5095L58.9233 93.4493L59.8954 94.5974L60.3584 94.4492L60.5991 93.9724L61.3213 93.7409L61.5435 93.2549L61.9416 92.5326L61.4926 92.0836Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M66.4044 64.9832L67.0155 65.5666L67.3442 66.6776L67.1219 67.0341L66.8627 68.0988L67.1127 69.1914L66.7053 69.6497L66.3118 70.8811L66.9923 71.2237L63.0527 72.3255L63.1777 73.2745L62.1916 73.4597L61.4555 73.9921L61.2982 74.4597L60.8306 74.5615L59.701 75.6726L58.9835 76.5475L58.5437 76.5799L58.1224 76.4225L56.6734 76.2744L56.4419 76.1725L56.428 76.0614L55.9188 75.7559L55.0762 75.6772L54.0115 75.9874L53.1643 75.1448L52.2893 74.043L52.3495 69.8025L55.0531 69.821L54.942 69.3627L55.1364 68.8673L54.9096 68.2516L55.0577 67.6128L54.9188 67.2054L55.3679 67.2378L55.4419 67.6452L56.0484 67.6128L56.8724 67.7331L57.3076 68.3303L58.3446 68.5155L59.1408 68.0988L59.4325 68.7886L60.4278 68.9738L60.9046 69.5386L61.437 70.2654L62.4324 70.2793L62.3212 68.8534L61.9648 69.0895L61.0574 68.5803L60.7056 68.3442L60.8676 67.0248L61.0991 65.474L60.8074 64.8953L61.1778 64.062L61.525 63.9092L63.2703 63.687L63.7795 63.8213L64.3212 64.15L64.8396 64.3722L65.6637 64.5897L66.4044 64.9832Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
      <path
        d="M64.3674 82.4777L63.6776 82.3388L63.2378 82.5055L62.6129 82.2694L62.0851 82.2555L61.2564 81.6259L60.2519 81.4129L59.8723 80.5333L59.8676 80.0473L59.3121 79.8991L57.8446 78.3945L57.4325 77.6029L57.1733 77.3622L56.6733 76.2743L58.1223 76.4224L58.5436 76.5798L58.9834 76.5474L59.701 75.6724L60.8305 74.5614L61.2981 74.4595L61.4555 73.992L62.1916 73.4596L63.1777 73.2744L63.261 73.7744L64.3443 73.7466L64.9461 74.029L65.2239 74.3623L65.8442 74.4595L66.5155 74.8947L66.5201 76.603L66.2655 77.5474L66.2099 78.5658L66.4183 78.9732L66.2747 79.7787L66.0757 79.9037L65.7331 80.8991L64.3674 82.4777Z"
        fill={fillColor}
        stroke={strokeColor}
        stroke-width="0.5"
      />
    </svg>
  );
};

export default AfricaIcon;
