import NeonText, { NeonColor } from "../../components/basic/NeonText";
import DailyStats from "./DailyStats";
import GeoStats from "./GeoStats";

const Admin = () => {
  return (
    <div className="flex flex-col items-center w-full p-4 space-y-4">
      {/* Geo game */}
      <NeonText color={NeonColor.WHITE}>
        <span className="text-3xl">Geo game stats</span>
      </NeonText>
      <GeoStats />
      {/* Daily game */}
      <NeonText color={NeonColor.WHITE}>
        <span className="text-3xl">Daily game stats</span>
      </NeonText>
      <DailyStats />
    </div>
  );
};

export default Admin;
