import { Puzzle } from "../../data/puzzles";

export const hiddenGemsItineraryLink =
  "https://www.musiccitiesevents.com/hidden-gems-of-music-tourism";

const ItineraryInfo = ({ puzzle }: { puzzle: Puzzle }) => {
  const img = puzzle.itinerary_image;
  const title = puzzle.itinerary_title;
  const description = puzzle.itinerary_description;

  if (!img || !title || !description) {
    return null;
  }

  return (
    <div className="flex flex-col items-center md:flex-row md:space-x-6">
      <div
        className="rounded-lg overflow-hidden max-w-64 md:max-w-none
    md:basis-1/2 lg:basis-2/5 md:sticky md:top-16 max-h-32 md:max-h-none"
      >
        <a
          href={hiddenGemsItineraryLink}
          target="_blank"
          rel="noopener noreferrer"
          className="underline hover:text-white"
        >
          <img
            src={img}
            alt={"Itinerary"}
            className="w-full h-full object-contain"
          />
        </a>
      </div>
      <div className="flex flex-col items-center md:basis-1/2 lg:basis-3/5">
        <div className="text-white text-md italic mt-2">{description}</div>
        <div className="text-gray-400 text-md mt-2 text-right">
          this is a Hidden Gem of <b>{title}</b>
        </div>
        <PindropHiddenGemsExplanation />
      </div>
      {/* Divider */}
      <div className="w-full h-px bg-gray-400 mt-4 md:hidden"></div>
    </div>
  );
};

export const PindropHiddenGemsExplanation = () => {
  return (
    <div className="text-gray-400 text-sm mt-2 border-gray-400 p-2 border rounded-md">
      Pindrop is partnering with the Hidden Gems of Music Tourism to showcase
      the Hidden Gems and the Exceptional Talent of Music Cities around the
      world. Check out the full guide{" "}
      <a
        href={hiddenGemsItineraryLink}
        target="_blank"
        rel="noopener noreferrer"
        className="underline hover:text-white"
      >
        here
      </a>
      .
    </div>
  );
};

export default ItineraryInfo;
