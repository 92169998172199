import { post } from "@aws-amplify/api";
import { API_NAME } from "../constants";

interface SpotifyApiRequest {
  path: string;
  method: "GET" | "POST"; // Extend this as needed
  queryParams?: Record<string, any>;
  bodyParams?: Record<string, any>;
}

export const spotifyApi = async ({
  path,
  method,
  queryParams = {},
  bodyParams = {},
}: SpotifyApiRequest) => {
  try {
    let restOperation;
    restOperation = post({
      apiName: API_NAME,
      path: `/spotify`,
      options: {
        headers: { "Content-Type": "application/json" }, // Optional based on your backend requirements
        body: { path, method, queryParams, bodyParams },
      },
    });

    const response = await restOperation.response;
    const json = await response.body.json();
    return json;
  } catch (err) {
    console.error("API call failed:", err);
    return null;
  }
};
