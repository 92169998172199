import { useState, useEffect } from "react";
import {
  PerfectScore,
  perfectScoresForUsername,
} from "../../../services/perfect_scores";
import { useUsername } from "../../../state/UsernameContext";
import Spinner from "../../../components/Spinner";
import { Link } from "react-router-dom";
import { Puzzle, fetchPuzzleByArtistSpotifyId } from "../../../data/puzzles";
import { completionsToDollars } from "./utils";
import Button from "../../../components/basic/Button";
import ContinentBreakdown, {
  artistIdsToContinentBreakdownStats,
} from "./ContinentBreakdown";
import { ArtistOneLiner } from "../../../components/GeoguessMap/ArtistOneLiner";

const PlayerStats = () => {
  const { username } = useUsername();
  const [completions, setCompletions] = useState<PerfectScore[] | null>(null);

  const fetchCompletions = async (username: string) => {
    try {
      const perfectScores = await perfectScoresForUsername(username);
      setCompletions(perfectScores);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!username) return;
    fetchCompletions(username);
  }, [username]);

  if (!username) return <NotLoggedIn />;
  if (!completions)
    return (
      <div className="flex flex-col space-y-2 pt-4 items-center">
        <Spinner />
      </div>
    );

  return (
    <div className="flex flex-col space-y-2 items-center">
      <TotalRaised numCompletions={completions.length} />
      <div className="flex flex-col md:flex-row md:space-x-16">
        <ContinentBreakdown
          continentBreakdown={artistIdsToContinentBreakdownStats(
            completions.map((completion) => completion.artist_spotify_id)
          )}
        />
        <div className="flex flex-col items-center space-y-2">
          <div className="text-gray-400 text-lg">
            <Link to="/rumble" className="underline cursor-pointer">
              Play Pindrop Rumble
            </Link>{" "}
            to see more artists!
          </div>
          {/* List of completed artists */}
          <div className="flex flex-col space-y-1 items-start w-80">
            {completions.map((completion: PerfectScore) => (
              <ArtistOneLinerFromId
                artistId={completion.artist_spotify_id}
                key={completion.artist_spotify_id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const NotLoggedIn = () => {
  return (
    <div className="flex flex-col items-center pt-16 px-4 w-full space-y-4">
      <div className="text-gray-400">
        Log in to see your stats and participate in the fundraiser.
      </div>
      <Link to="/rumble?showLogin=true">
        <Button color="dark">Log in</Button>
      </Link>
    </div>
  );
};

export const TotalRaised = ({ numCompletions }: { numCompletions: number }) => {
  return (
    <div className="flex flex-col items-center space-y-2">
      <div className="text-white text-2xl">
        {numCompletions} / 100 artists perfected
      </div>
      <div className="text-white text-lg">
        ${completionsToDollars(numCompletions).toFixed(2)} raised
      </div>
    </div>
  );
};

/*
Given just the artist ID, fetches the puzzle for the ID from the backend
then displays the artist one liner. While fetching, show a spinner
*/
const ArtistOneLinerFromId = ({ artistId }: { artistId: string }) => {
  const [puzzle, setPuzzle] = useState<Puzzle | null>(null);

  const fetchPuzzle = async (artistId: string) => {
    try {
      const puzzle = await fetchPuzzleByArtistSpotifyId(artistId);
      setPuzzle(puzzle);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPuzzle(artistId);
  }, [artistId]);

  if (!puzzle)
    return (
      <div className="m-auto">
        <Spinner />
      </div>
    );

  return <ArtistOneLiner puzzle={puzzle} />;
};

export default PlayerStats;
