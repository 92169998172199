import React from "react";

export enum NeonColor {
  PINK = "pink",
  BLUE = "blue",
  GREEN = "green",
  ORANGE = "orange",
  RED = "red",
  YELLOW = "yellow",
  WHITE = "white",
}

type NeonColorPair = {
  tailwindColorClass: string;
  shadowColorHex: string;
};

export const neonColors: Record<NeonColor, NeonColorPair> = {
  pink: {
    tailwindColorClass: "text-pink-200",
    shadowColorHex: "#ec4899",
  },
  blue: {
    tailwindColorClass: "text-blue-200",
    shadowColorHex: "#2563eb",
  },
  green: {
    tailwindColorClass: "text-green-200",
    shadowColorHex: "#10b981",
  },
  orange: {
    tailwindColorClass: "text-yellow-200",
    shadowColorHex: "#ff621f",
  },
  red: {
    tailwindColorClass: "text-red-300",
    shadowColorHex: "#dd3333",
  },
  yellow: {
    tailwindColorClass: "text-yellow-100",
    shadowColorHex: "#f59e0b",
  },
  white: {
    tailwindColorClass: "text-white",
    shadowColorHex: "#ffffff",
  },
};

type NeonTextProps = {
  children: React.ReactNode;
  color: NeonColor;
  className?: string;
};
const NeonText: React.FC<NeonTextProps> = ({
  children,
  color,
  className = "",
}) => {
  return (
    <span
      className={`text-shadow-neon font-neon-tubes ${neonColors[color].tailwindColorClass} ${className}`}
      style={
        {
          "--tw-text-color": neonColors[color].shadowColorHex,
        } as React.CSSProperties
      }
    >
      {children}
    </span>
  );
};

export default NeonText;
