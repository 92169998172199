import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const VolumeOnIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M11 5L6 9H2V15H6L11 19V5Z M19.07 4.92993C20.9447 6.80521 21.9979 9.34829 21.9979 11.9999C21.9979 14.6516 20.9447 17.1947 19.07 19.0699M15.54 8.45993C16.4773 9.39757 17.0039 10.6691 17.0039 11.9949C17.0039 13.3208 16.4773 14.5923 15.54 15.5299";
  return <StrokeIcon {...props} path={path} />;
};

export default VolumeOnIcon;
