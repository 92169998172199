import React, { createContext, useContext, useState, ReactNode } from "react";

// Define the shape of the context
interface UsernameContextType {
  username: string | null;
  setUsername: React.Dispatch<React.SetStateAction<string | null>>;
}

// Create the context with a default value
const UsernameContext = createContext<UsernameContextType | undefined>(
  undefined
);

// Create a provider component
export const UsernameProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [username, setUsername] = useState<string | null>(null);

  return (
    <UsernameContext.Provider value={{ username, setUsername }}>
      {children}
    </UsernameContext.Provider>
  );
};

// Create a custom hook to use the UsernameContext
export const useUsername = (): UsernameContextType => {
  const context = useContext(UsernameContext);
  if (context === undefined) {
    throw new Error("useUsername must be used within a UsernameProvider");
  }
  return context;
};
