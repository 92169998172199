import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface GuessDistributionChartProps {
  scoreDistribution: number[];
  userScore: number; // 1-based index of the user's score in the distribution, use a negative value or a value greater than the array length to indicate no highlight
  title?: string;
}

const GuessDistributionChart: React.FC<GuessDistributionChartProps> = ({
  scoreDistribution,
  userScore,
  title,
}) => {
  // Prepare labels and data for the chart
  const labels = scoreDistribution.map((_, index) =>
    index + 1 === scoreDistribution.length ? "X" : (index + 1).toString()
  );

  // Generate a gradient effect for the bars
  const backgroundColors = scoreDistribution.map((_, index) => {
    if (index + 1 === userScore) {
      return "rgba(245, 214, 130, 0.7)"; // Highlight color for userScore
    } else {
      const gradientStep = index / (scoreDistribution.length - 1);
      const colorValue = Math.floor(220 - gradientStep * 120);
      return `rgba(${colorValue}, ${colorValue}, ${colorValue}, 0.7)`; // Gradient from white to black
    }
  });

  const data = {
    labels,
    datasets: [
      {
        label: "Score Distribution",
        data: scoreDistribution,
        backgroundColor: backgroundColors,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        display: false,
        beginAtZero: true,
      },
      x: {
        ticks: {
          color: "#fff",
          font: {
            family: "Rajdhani",
            size: 14,
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return ""; // Hides the value, showing only the tooltip with no content.
          },
        },
      },
      title: {
        display: !!title,
        text: title,
        font: {
          family: "Rajdhani",
          size: 14,
          weight: "normal" as any,
        },
        color: "#fff",
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default GuessDistributionChart;
