import React from "react";

import SpotifyIcon from "./Icon/SpotifyIcon";
import Button from "./basic/Button";
import { ORIGIN } from "../constants";

const REDIRECT_URI = `${ORIGIN}/rumble`;
const CLIENT_ID = "66da7230e25d44d19011dc0989f6033c";
const AUTH_ENDPOINT = "https://accounts.spotify.com/authorize";
const RESPONSE_TYPE = "token";
const SCOPES: string[] = [];

interface Props {
  token: string;
  setToken: (token: string) => void;
  spotifyUser: SpotifyApi.CurrentUsersProfileResponse | null;
}

export const SpotifyLogin = ({ token, setToken, spotifyUser }: Props) => {
  const logout = () => {
    setToken("");
    window.localStorage.removeItem("token");
  };

  return (
    <div>
      {!token && (
        <div className="text-gray-400 text-xs">
          <a
            href={`${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${SCOPES.join(
              "%20"
            )}`}
          >
            <Button startIcon={<SpotifyIcon size="sm" />} color="transparent">
              <span className="underline">
                Log into Spotify Premium to play full-length songs
              </span>
            </Button>
          </a>
        </div>
      )}
      {token && spotifyUser && (
        <div className="flex items-center text-xs">
          <div className="text-gray-400">
            Logged into Spotify as {spotifyUser.display_name}.
          </div>
          <Button onClick={logout} color="transparent">
            <span className="underline">Log out</span>
          </Button>
        </div>
      )}
    </div>
  );
};
