import React from "react";
import PieChart from "../../../components/basic/PieChart";
import AfricaIcon from "../../../components/Icon/Continents/AfricaIcon";
import AsiaIcon from "../../../components/Icon/Continents/AsiaIcon";
import EuropeIcon from "../../../components/Icon/Continents/EuropeIcon";
import NorthAmericaIcon from "../../../components/Icon/Continents/NorthAmericaIcon";
import SouthAmericaIcon from "../../../components/Icon/Continents/SouthAmericaIcon";
import OceaniaIcon from "../../../components/Icon/Continents/OceaniaIcon";
import {
  Continent,
  continentsData,
  continentTotals,
} from "../../../data/rumble_classic_continents";

interface ContinentStats {
  perfect: number;
  total: number;
}

interface ContinentBreakdownStats {
  [Continent.Africa]: ContinentStats;
  [Continent.Asia]: ContinentStats;
  [Continent.Europe]: ContinentStats;
  [Continent.NorthAmerica]: ContinentStats;
  [Continent.SouthAmerica]: ContinentStats;
  [Continent.Oceania]: ContinentStats;
}

// Helper function to go from artist ID to continent
export const artistIdsToContinentBreakdownStats = (
  artistIds: string[]
): ContinentBreakdownStats => {
  const continentBreakdown: ContinentBreakdownStats = {
    [Continent.Africa]: {
      perfect: 0,
      total: continentTotals[Continent.Africa],
    },
    [Continent.Asia]: { perfect: 0, total: continentTotals[Continent.Asia] },
    [Continent.Europe]: {
      perfect: 0,
      total: continentTotals[Continent.Europe],
    },
    [Continent.NorthAmerica]: {
      perfect: 0,
      total: continentTotals[Continent.NorthAmerica],
    },
    [Continent.SouthAmerica]: {
      perfect: 0,
      total: continentTotals[Continent.SouthAmerica],
    },
    [Continent.Oceania]: {
      perfect: 0,
      total: continentTotals[Continent.Oceania],
    },
  };

  artistIds.forEach((artistId) => {
    const continent = continentsData[artistId];
    if (continent) {
      continentBreakdown[continent].perfect += 1;
    }
  });

  return continentBreakdown;
};

const ContinentBreakdown = ({
  continentBreakdown,
}: {
  continentBreakdown: ContinentBreakdownStats;
}) => {
  const colors = {
    [Continent.Africa]: "#e76f51",
    [Continent.Asia]: "#f4a261",
    [Continent.Europe]: "#e9c46a",
    [Continent.NorthAmerica]: "#8ab17d",
    [Continent.SouthAmerica]: "#2a9d8f",
    [Continent.Oceania]: "#264653",
  };

  const continentScale = 0.8;
  const icons = {
    [Continent.Africa]: (
      <AfricaIcon
        fillColor={colors[Continent.Africa]}
        strokeColor="#f0f0f0"
        scale={continentScale}
      />
    ),
    [Continent.Asia]: (
      <AsiaIcon
        scale={continentScale}
        fillColor={colors[Continent.Asia]}
        strokeColor="#f0f0f0"
      />
    ),
    [Continent.Europe]: (
      <EuropeIcon
        scale={continentScale}
        fillColor={colors[Continent.Europe]}
        strokeColor="#f0f0f0"
      />
    ),
    [Continent.NorthAmerica]: (
      <NorthAmericaIcon
        scale={continentScale}
        fillColor={colors[Continent.NorthAmerica]}
        strokeColor="#f0f0f0"
      />
    ),
    [Continent.SouthAmerica]: (
      <SouthAmericaIcon
        scale={continentScale}
        fillColor={colors[Continent.SouthAmerica]}
        strokeColor="#f0f0f0"
      />
    ),
    [Continent.Oceania]: (
      <OceaniaIcon
        scale={continentScale}
        fillColor={colors[Continent.Oceania]}
        strokeColor="#f0f0f0"
      />
    ),
  };

  return (
    <div className="grid grid-cols-2 gap-x-6 justify-items-end">
      {Object.entries(continentBreakdown).map(([continent, stats]) => (
        <div key={continent} className="flex flex-row my-4 items-center">
          {icons[continent as Continent]}
          <PieChart
            perfect={stats.perfect}
            total={stats.total}
            color={colors[continent as Continent]}
            label={continent}
            width={85}
            height={85}
          />
        </div>
      ))}
    </div>
  );
};

export default ContinentBreakdown;
