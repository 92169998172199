import React, { useEffect, useState, useCallback } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { getAllPuzzles, updatePuzzleDate } from "../services/puzzle_admin";
import { Puzzle } from "../data/puzzles";
import Button from "../components/basic/Button";
import PindropIcon from "../components/Icon/PindropIcon";
import { NeonColor } from "../components/basic/NeonText";

export interface PuzzleWithDate extends Puzzle {
  daily_date_utc: string;
}

/* Disable React.StrictMode for Draggable component, causes errors in dev */
export const useStrictDroppable = (loading: boolean) => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    let animation: any;

    if (!loading) {
      animation = requestAnimationFrame(() => setEnabled(true));
    }

    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, [loading]);

  return [enabled];
};

const PuzzleOrdering = () => {
  const [originalPuzzles, setOriginalPuzzles] = useState<PuzzleWithDate[]>([]);
  const [allPuzzles, setAllPuzzles] = useState<PuzzleWithDate[]>([]);
  const [changed, setChanged] = useState(false);
  const [error, setError] = useState<string | null>(null);

  /* Disable React.StrictMode for Draggable component, causes errors in dev */
  const [enabled] = useStrictDroppable(!allPuzzles.length);

  useEffect(() => {
    const fetchPuzzles = async () => {
      try {
        const puzzles = await getAllPuzzles();
        // Sort puzzles by date in descending order
        const filteredPuzzles = puzzles.filter((p) => p.daily_date_utc);
        filteredPuzzles.sort(
          (a, b) =>
            new Date(b.daily_date_utc).getTime() -
            new Date(a.daily_date_utc).getTime()
        );
        setAllPuzzles(filteredPuzzles);
        setOriginalPuzzles(structuredClone(filteredPuzzles)); // deep copy
      } catch (err) {
        console.error(err);
        setError(`Failed to fetch puzzles: ${err}`);
      }
    };
    fetchPuzzles();
  }, []);

  const handleOnDragEnd = useCallback(
    async (result: any) => {
      if (!result.destination) return; // dropped outside the list
      setChanged(true);

      const items = Array.from(allPuzzles);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setAllPuzzles(items);

      // Update the dates based on new order
      // Use the dates of the corresponding index in originalPuzzles
      for (let i = 0; i < items.length; i++) {
        items[i].daily_date_utc = originalPuzzles[i].daily_date_utc;
        setAllPuzzles([...items]);
      }
    },
    [allPuzzles, originalPuzzles]
  );

  // Find the rows that have changed between originalPuzzles and allPuzzles,
  // and send a request to update the date for each changed row
  const handleSave = async () => {
    const changedRows = allPuzzles.filter(
      (puzzle, index) =>
        puzzle.artist_name !== originalPuzzles[index].artist_name
    );

    for (const puzzle of changedRows) {
      try {
        await updatePuzzleDate(puzzle.artist_spotify_id, puzzle.daily_date_utc);
        console.log(
          `Updated date for ${puzzle.artist_name} to ${puzzle.daily_date_utc}`
        );
      } catch (err) {
        console.error(err);
        setError(`Failed to update date for ${puzzle.artist_name}: ${err}`);
      }
    }

    setOriginalPuzzles(structuredClone(allPuzzles)); // deep copy
    setChanged(false);
  };

  return (
    <div>
      {error && <div className="bg-red-500 text-white p-4">{error}</div>}
      <div className="flex flex-row space-x-12 items-center">
        <div className="text-xl text-white py-4">Puzzle Ordering</div>
        <Button
          onClick={handleSave}
          disabled={!changed}
          color="dark"
          className="h-10"
        >
          Save changes
        </Button>
      </div>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {enabled && (
          <Droppable droppableId="puzzles">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {allPuzzles.map((puzzle, index) => (
                  <Draggable
                    key={puzzle.artist_spotify_id}
                    draggableId={puzzle.artist_spotify_id}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="flex items-center p-4 border-b border-gray-200 space-x-4"
                      >
                        <img
                          src={puzzle.artist_image}
                          alt={puzzle.artist_name}
                          className="w-12 h-12 rounded-full"
                        />
                        <div className="w-48">
                          <div
                            className={`text-lg ${
                              puzzle.artist_name !==
                              originalPuzzles[index].artist_name
                                ? "text-orange-200"
                                : "text-white"
                            }`}
                          >
                            {puzzle.artist_name}
                          </div>
                          <div className="text-md text-gray-400">
                            {puzzle.artist_location}
                          </div>
                        </div>
                        <div>
                          <div className="text-md text-gray-400">
                            {new Date(puzzle.daily_date_utc).toLocaleDateString(
                              undefined,
                              { timeZone: "UTC" }
                            )}
                          </div>
                        </div>
                        {
                          /* if the puzzle and todays date are the same, show a star */
                          new Date(puzzle.daily_date_utc).toLocaleDateString(
                            undefined,
                            { timeZone: "UTC" }
                          ) ===
                            new Date().toLocaleDateString(undefined, {
                              timeZone: "UTC",
                            }) && (
                            <PindropIcon
                              size="lg"
                              neonColorPrimary={NeonColor.PINK}
                              neonColorSecondary={NeonColor.BLUE}
                            />
                          )
                        }
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </div>
  );
};

export default PuzzleOrdering;
