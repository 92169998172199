import React from "react";
import FillIcon, { FillIconProps } from "./FillIcon";

const SpotifyIcon: React.FC<Omit<FillIconProps, "path">> = (props) => {
  const path =
    "M19.08 10.68C15.24 8.39999 8.82 8.16 5.16 9.3C4.56 9.48 3.96 9.12 3.78 8.58C3.6 7.98 3.96 7.38 4.5 7.2C8.76 5.94 15.78 6.18001 20.22 8.82001C20.76 9.12001 20.94 9.84 20.64 10.38C20.34 10.8 19.62 10.98 19.08 10.68ZM18.96 14.04C18.66 14.46 18.12 14.64 17.7 14.34C14.46 12.36 9.54 11.76 5.76 12.96C5.28 13.08 4.74 12.84 4.62 12.36C4.5 11.88 4.74 11.34 5.22 11.22C9.6 9.9 15 10.56 18.72 12.84C19.08 13.02 19.26 13.62 18.96 14.04ZM17.52 17.34C17.28 17.7 16.86 17.82 16.5 17.58C13.68 15.84 10.14 15.48 5.94 16.44C5.52 16.56 5.16 16.26 5.04 15.9C4.92 15.48 5.22 15.12 5.58 15C10.14 13.98 14.1 14.4 17.22 16.32C17.64 16.5 17.7 16.98 17.52 17.34ZM12 0C5.4 0 0 5.4 0 12C0 18.6 5.4 24 12 24C18.6 24 24 18.6 24 12C24 5.4 18.66 0 12 0Z";
  return <FillIcon {...props} path={path} />;
};

export default SpotifyIcon;
