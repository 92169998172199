import React, { useRef, useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { NeonColor } from "./basic/NeonText";
import { CityGuess } from "./GuessCityPanel";
import { distanceToNeonColor } from "./GuessCityPanel/emoji";

// Define props type if you need to pass any props to the component
interface MapComponentProps {
  gmapsKey: string;
  guesses: CityGuess[];
}

const MapComponent: React.FC<MapComponentProps> = ({ gmapsKey, guesses }) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);

  /* initialize map */
  useEffect(() => {
    const loader = new Loader({
      apiKey: gmapsKey,
      version: "weekly",
    });

    loader.load().then(async () => {
      if (mapRef.current) {
        const _map = new google.maps.Map(mapRef.current, {
          gestureHandling: "greedy",
          zoom: 2,
          center: { lat: 0, lng: 0 },
          disableDefaultUI: true, // a way to quickly hide all controls
          // You can design styles at https://mapstyle.withgoogle.com/
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.business",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8a8a8a",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#373737",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3c3c3c",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#4e4e4e",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#000000",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        });
        setMap(_map);
      }
    });
  }, [gmapsKey]);

  /* add location pins to map */
  useEffect(() => {
    if (map && mapRef.current) {
      // Function to add a marker for each location pin
      const addMarker = (guess: CityGuess) => {
        const svgIcon = {
          url:
            "data:image/svg+xml;charset=UTF-8," +
            encodeURIComponent(
              getCustomSVG(distanceToNeonColor(guess.distance))
            ),
          scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
        };

        new google.maps.Marker({
          position: { lat: guess.latitude, lng: guess.longitude },
          map,
          icon: svgIcon,
        });
      };

      // Add marker for newest guess
      if (guesses.length > 0) {
        addMarker(guesses[guesses.length - 1]);
      }
    }
  }, [map, guesses]);

  return <div className="w-full h-full" ref={mapRef} />;
};

/* Helper function to get the custom SVG code for a given color */
function getCustomSVG(color: NeonColor) {
  const neonColorToHex: { [key in NeonColor]: string } = {
    pink: "#ec4899",
    blue: "#2563eb",
    green: "#bbf7d0",
    orange: "#fdba74",
    red: "#dd3333",
    yellow: "#ffff99",
    white: "#ffffff",
  };

  const customSVG = `
<svg width="30" height="30" viewBox="-10 -10 313.334 313.334" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
    <defs>
        <filter id="shadow" x="-100%" y="-100%" width="300%" height="300%">
            <feDropShadow dx="0" dy="0" stdDeviation="10" flood-color="#ffffff" flood-opacity="0.9"/>
        </filter>
    </defs>
    <g filter="url(#shadow)">
        <path style="fill:none; stroke:${neonColorToHex[color]}; stroke-width:10; stroke-linecap:round; stroke-linejoin:round;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878
            c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212
            c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084
            c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358
            c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307
            C198.98,120.938,175.559,144.358,146.667,144.358z"/>
    </g>
</svg>
`;
  return customSVG;
}

export default MapComponent;
