import { Grid } from "react-loader-spinner";

const Spinner = () => {
  return (
    <div className="inline-flex justify-center items-center transform translate-x-0.5 -translate-y-0.5">
      <Grid
        height="30"
        width="30"
        color="white"
        radius="10"
        ariaLabel="loading"
      />
    </div>
  );
};

export default Spinner;
