import { RWebShare } from "react-web-share";
import { GameMode, registerShare } from "../../services/user_tracking";
import { Puzzle } from "../../data/puzzles";
import IconButton from "../basic/IconButton";
import ShareIcon from "../Icon/ShareIcon";
import NeonText, { NeonColor } from "../basic/NeonText";

const ShareButton = ({
  puzzle,
  emojiStory,
}: {
  puzzle: Puzzle;
  emojiStory: string;
}) => {
  return (
    <div>
      <RWebShare
        data={{
          text:
            `Pindrop Daily ${new Date().toLocaleDateString("en-US", {
              year: "2-digit",
              month: "numeric",
              day: "numeric",
            })}\n` + emojiStory,
          url: "https://pindrop.cc/daily",
          title: "Pindrop Daily",
        }}
        onClick={() => {
          if (process.env.NODE_ENV === "production")
            registerShare({
              artist_name: puzzle.artist_name,
              game_mode: GameMode.DAILY,
            });
        }}
      >
        <div className="flex flex-col items-center">
          <IconButton onClick={() => {}}>
            <ShareIcon size="lg" neonColor={NeonColor.ORANGE} />
          </IconButton>
          <NeonText className="text-md" color={NeonColor.ORANGE}>
            share
          </NeonText>
        </div>
      </RWebShare>
    </div>
  );
};

export default ShareButton;
