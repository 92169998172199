import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const AlertTriangleIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M10.29 3.85996L1.81999 18C1.64536 18.3024 1.55296 18.6453 1.55198 18.9945C1.551 19.3437 1.64148 19.6871 1.81442 19.9905C1.98735 20.2939 2.23672 20.5467 2.5377 20.7238C2.83868 20.9009 3.18079 20.9961 3.52999 21H20.47C20.8192 20.9961 21.1613 20.9009 21.4623 20.7238C21.7633 20.5467 22.0126 20.2939 22.1856 19.9905C22.3585 19.6871 22.449 19.3437 22.448 18.9945C22.447 18.6453 22.3546 18.3024 22.18 18L13.71 3.85996C13.5317 3.56607 13.2807 3.32308 12.9812 3.15444C12.6817 2.98581 12.3437 2.89722 12 2.89722C11.6563 2.89722 11.3183 2.98581 11.0188 3.15444C10.7193 3.32308 10.4683 3.56607 10.29 3.85996Z M12 17H12.01 M12 9V13";
  return <StrokeIcon {...props} path={path} />;
};

export default AlertTriangleIcon;
