import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const RefreshIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M1 4V10H7 M23 20V14H17 M20.49 9C19.9828 7.56678 19.1209 6.2854 17.9845 5.27542C16.8482 4.26543 15.4745 3.55976 13.9917 3.22426C12.5089 2.88875 10.9652 2.93434 9.50481 3.35677C8.04437 3.77921 6.71475 4.56471 5.64 5.64L1 10M23 14L18.36 18.36C17.2853 19.4353 15.9556 20.2208 14.4952 20.6432C13.0348 21.0657 11.4911 21.1112 10.0083 20.7757C8.52547 20.4402 7.1518 19.7346 6.01547 18.7246C4.87913 17.7146 4.01717 16.4332 3.51 15";
  return <StrokeIcon {...props} path={path} />;
};

export default RefreshIcon;
