import { setPlayerToUseCookie } from "./DualSongControl";
import AlertTriangleIcon from "./Icon/AlertTriangleIcon";
import Button from "./basic/Button";
import Modal from "./basic/Modal";

interface RefreshedModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const RefreshedModal = ({ isOpen, onClose }: RefreshedModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:max-w-xl">
      <div className="flex flex-col space-y-4 p-4 items-center">
        <div>
          If you keep having issues with the song player, try switching to the
          classic song player.
        </div>
        <Button
          onClick={() => {
            setPlayerToUseCookie("embed");
            onClose();
          }}
          color="dark"
          startIcon={<AlertTriangleIcon size="sm" />}
        >
          Switch to classic song player
        </Button>
        <Button color="dark" onClick={onClose}>
          Try again with neon player
        </Button>
      </div>
    </Modal>
  );
};
export default RefreshedModal;
