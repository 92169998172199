import {
  useNavigate,
  useLocation,
  Outlet,
  Routes,
  Route,
} from "react-router-dom";
import GlobalLeaderboard from "./GlobalLeaderboard";
import PlayerStats from "./PlayerStats";
import GALFundraiserScreenshotResults from "./GALFundraiserScreenshotResults";

const PageOutline = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const basePath = "/rumble/fundraiser";

  const isGlobalLeaderboard = location.pathname === basePath;
  const isPlayerStats = location.pathname === basePath + "/you";

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex flex-row items-center justify-between p-2">
        <button
          onClick={() => navigate(basePath)}
          disabled={isGlobalLeaderboard}
          className={`w-full text-white p-2 rounded transition ease-in-out duration-150 flex flex-row items-center justify-center bg-gray-800 ${
            isPlayerStats ? "opacity-50" : "opacity-100 cursor-not-allowed"
          }`}
        >
          Global leaderboard
        </button>
        <button
          onClick={() => navigate(basePath + "/you")}
          disabled={isPlayerStats}
          className={`w-full text-white p-2 rounded transition ease-in-out duration-150 flex flex-row items-center justify-center bg-gray-800 ${
            isGlobalLeaderboard
              ? "opacity-50"
              : "opacity-100 cursor-not-allowed"
          }`}
        >
          Your stats
        </button>
      </div>
      <Outlet />
    </div>
  );
};

const FundraiserChallengeRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<PageOutline />}>
        <Route index element={<GlobalLeaderboard />} />
        <Route path="you" element={<PlayerStats />} />
        <Route path="screenshot" element={<GALFundraiserScreenshotResults />} />
      </Route>
    </Routes>
  );
};

export default FundraiserChallengeRoutes;
