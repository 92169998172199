import React, { useState } from "react";
import { signIn } from "aws-amplify/auth";
import Button from "../basic/Button";
import Input from "../basic/Input";

interface SignInProps {
  setUsername: (username: string | null) => void;
}
const SignIn = ({ setUsername }: SignInProps) => {
  const [usernameInput, setUsernameInput] = useState("");
  const [password, setPassword] = useState("");
  const [backendError, setBackendError] = useState<string | null>(null);

  const handleSignIn = async () => {
    try {
      await signIn({ username: usernameInput, password });
      setUsername(usernameInput);
    } catch (error: any) {
      if (error.name === "NotAuthorizedException") {
        setBackendError("Username or password is incorrect.");
      } else {
        setBackendError("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="flex flex-col space-y-2 p-2">
      {backendError && <small className="text-red-400">{backendError}</small>}
      <Input
        type="text"
        placeholder="Username or Email"
        value={usernameInput}
        onChange={(e) => setUsernameInput(e.target.value)}
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Button
        onClick={handleSignIn}
        color="light"
        disabled={!usernameInput || !password}
      >
        Log In
      </Button>
    </div>
  );
};

export default SignIn;
