import { useEffect, useState } from "react";

/*
Typescript React Component wrapper for the Spotify iFrame API Embed controller
*/
interface SpotifyTrackPlayerProps {
  width?: string;
  height?: string;
  uri?: string;
  onReady?: (controller: any) => void;
  setIsPaused?: (isPaused: boolean) => void;
  setFinished?: (finished: boolean) => void;
  setIsActuallyPlaying?: (isActuallyPlaying: boolean) => void;
  playing?: boolean;
}

const SpotifyTrackPlayer = ({
  width,
  height,
  uri,
  onReady,
  setIsPaused,
  setFinished,
  setIsActuallyPlaying,
  playing,
}: SpotifyTrackPlayerProps) => {
  const [controller, setController] = useState<any>(null);

  /* handle uri changes */
  useEffect(() => {
    if (controller) {
      if (uri) controller.loadUri(uri);
      else controller.pause();
    }
  }, [controller, uri]);

  /* handle playing changes */
  useEffect(() => {
    if (controller) {
      if (playing) {
        controller.togglePlay(); // togglePlay() resumes, play() starts from the beginning
      } else {
        controller.pause();
      }
    }
  }, [controller, playing]);

  /* Startup setup */
  useEffect(() => {
    if (window && document) {
      const script = document.createElement("script");
      const body = document.getElementsByTagName("body")[0];
      script.src = "https://open.spotify.com/embed/iframe-api/v1";
      body.appendChild(script);
      script.addEventListener("load", () => {
        (window as any).onSpotifyIframeApiReady = (IFrameAPI: any) => {
          const element = document.getElementById(`embed-iframe`);
          const options = {
            width,
            height,
            uri,
          };
          const callback = (EmbedController: any) => {
            setController(EmbedController);
            EmbedController.addListener("ready", () => {
              if (onReady) onReady(EmbedController);
            });
            EmbedController.addListener("playback_update", (e: any) => {
              /* For future functionality hinging on pause/play state,
              these callbacks are static and cannot take in changing state
              variables from outside. Recommended way is to just pass in
              setState functions (like setIsPaused) and call them here, then
              manage what to do with the state variables in the parent component. */
              if (setIsPaused) setIsPaused(e.data.isPaused);
              if (setIsActuallyPlaying)
                setIsActuallyPlaying(!e.data.isPaused && e.data.position > 0);

              // Finished playing
              if (setFinished)
                setFinished(
                  e.data.duration > 0 && e.data.position === e.data.duration
                );
            });
          };
          IFrameAPI.createController(element, options, callback);
        };
      });
    }
  }, []);

  return (
    <>
      <div id={`embed-iframe`} />
    </>
  );
};

export default SpotifyTrackPlayer;
