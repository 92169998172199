import { signOut } from "aws-amplify/auth";
import Button from "../basic/Button";

interface LogOutProps {
  username: string;
  setUsername: (username: string | null) => void;
}
const LogOut = ({ username, setUsername }: LogOutProps) => {
  return (
    <div className="flex flex-col space-y-2 p-2">
      <div>Signed in as {username}.</div>
      <Button
        onClick={async () => {
          await signOut();
          setUsername(null);
        }}
        color="light"
      >
        Log Out
      </Button>
    </div>
  );
};

export default LogOut;
