import { get, patch, put } from "aws-amplify/api";
import { API_NAME } from "../constants";
import { PuzzleInput } from "../data/puzzles";
import { PuzzleWithDate } from "../pages/PuzzleOrdering";

// Register a puzzle input into DynamoDB
export async function addPuzzleInput(puzzleInput: PuzzleInput) {
  try {
    const body = { ...puzzleInput };
    const putOperation = put({
      apiName: API_NAME,
      path: "/admin/puzzle/add",
      options: {
        body,
      },
    });
    const { body: resp } = await putOperation.response;
    return resp.json();
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("PUT call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}

// Update an existing puzzle date in DynamoDB using PATCH
export async function updatePuzzleDate(
  artist_spotify_id: string,
  new_date: string
) {
  try {
    const body = { daily_date_utc: new_date };
    const patchOperation = patch({
      apiName: API_NAME,
      path: `/admin/puzzle/add/object/${artist_spotify_id}`,
      options: {
        body,
      },
    });
    const { body: resp } = await patchOperation.response;
    return resp.json();
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("PATCH call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}

// Get all puzzles
export async function getAllPuzzles(): Promise<PuzzleWithDate[]> {
  try {
    const getOperation = get({
      apiName: API_NAME,
      path: "/admin/puzzle/add",
    });
    const { body: resp } = await getOperation.response;
    return (await resp.json()) as any;
  } catch (err: any) {
    const errorBody = await JSON.parse(err.response?.body);
    console.log("GET call failed: ", errorBody.error);
    throw Error(errorBody.error);
  }
}
