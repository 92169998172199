/* Functions for scoring the Geoguessr style game */

export const MAX_PUZZLE_SCORE = 100;

/*
 * Calculate the score based on the distance from the correct location
 * @param distance - the distance from the correct location in km
 * @returns the score
 */
export const calculateScoreByDistance = (distance: number) => {
  // If distance is within 100km, return max score
  if (distance <= 100) return MAX_PUZZLE_SCORE;
  // If within 200km, range from 90 to 95
  if (distance <= 200) return Math.round(90 + (200 - distance) * 0.05);
  // If within 500km, range from 75 to 90
  if (distance <= 500) return Math.round(75 + (500 - distance) * 0.03);
  // If within 2000km, range from 50 to 75
  if (distance <= 2000) return Math.round(50 + (2000 - distance) * 0.025);
  // If within 5000km, range from 20 to 50
  if (distance <= 5000) return Math.round(20 + (5000 - distance) * 0.006);
  // If within 10000km, range from 0 to 20
  if (distance <= 10000) return Math.round((10000 - distance) * 0.002);
  return 0;
};
