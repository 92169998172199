import { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner";
import {
  LeaderboardStats,
  getLeaderboard,
} from "../../../services/perfect_scores";
import FundraiserThermometer from "./FundraiserThermometer";
import { ContinentScores, LeaderboardTable } from "./GlobalLeaderboard";
import { TotalRaised } from "./PlayerStats";

const GALFundraiserScreenshotResults = () => {
  const [leaderboard, setLeaderboard] = useState<LeaderboardStats | null>(null);

  const fetchLeaderboard = async () => {
    try {
      const leaderboard = await getLeaderboard();
      setLeaderboard(leaderboard);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchLeaderboard();
  }, []);

  if (!leaderboard) {
    return (
      <div className="flex flex-col space-y-2 pt-4 items-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="flex flex-col space-y-2 items-center py-8">
      <TotalRaised numCompletions={leaderboard.artists_completed.length} />
      <div className="py-4">
        <FundraiserThermometer value={leaderboard.artists_completed.length} />
      </div>
      <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:items-start">
        <ContinentScores leaderboard={leaderboard} />
        <LeaderboardTable leaderboard={leaderboard} />
      </div>
    </div>
  );
};

export default GALFundraiserScreenshotResults;
