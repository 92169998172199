import React from "react";
import FillIcon, { FillIconProps } from "./FillIcon";

const YoutubeIcon: React.FC<Omit<FillIconProps, "path">> = (props) => {
  const path =
    "M19.0155 0H4.98449C2.23163 0 0 2.23163 0 4.98449V11.9985C0 14.7514 2.23163 16.983 4.98449 16.983H19.0155C21.7684 16.983 24 14.7514 24 11.9985V4.98449C24 2.23163 21.7684 0 19.0155 0ZM15.6445 8.83276L9.08177 11.9628C8.9069 12.0462 8.7049 11.9187 8.7049 11.725V5.26926C8.7049 5.07279 8.91221 4.94545 9.08744 5.03426L15.6502 8.35995C15.8453 8.45881 15.8419 8.73863 15.6445 8.83276Z";
  return <FillIcon {...props} path={path} />;
};

export default YoutubeIcon;
