import { TOTAL_RUMBLE_ROUNDS } from "../pages/GeoGame";
import { hiddenGemsItineraryLink } from "./AnswerModal/ItineraryInfo";
import MapPinIcon from "./Icon/MapPinIcon";
import PindropIcon from "./Icon/PindropIcon";
import SunIcon from "./Icon/SunIcon";
import VolumeOnIcon from "./Icon/VolumeOnIcon";
import Modal from "./basic/Modal";
import NeonText, { NeonColor } from "./basic/NeonText";
import hiddenGemsLogo from "../img/hidden_gems_logo_animated.gif";

interface HiddenGemsGeoIntroModalProps {
  isOpen: boolean;
  onClose: () => void;
}
const HiddenGemsGeoIntroModal = ({
  isOpen,
  onClose,
}: HiddenGemsGeoIntroModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:max-w-xl">
      <div className="flex flex-col space-y-4 p-4 items-center">
        {/* Temporary news message */}
        {/* <div className="text-white p-2 border border-gray-500 w-full rounded-md text-center">
          We have a new home:&nbsp;
          <a href="https://pindrop.cc">
            <b>pindrop.cc</b>
          </a>
          !
        </div> */}
        {/* Main title */}
        <div className="flex flex-row items-center">
          <NeonText color={NeonColor.PINK} className="text-3xl">
            Pindrop
          </NeonText>
          <NeonText color={NeonColor.BLUE} className="text-3xl ml-2">
            Rumble
          </NeonText>
        </div>
        {/* Subtitle */}
        <div className="text-center">
          <NeonText color={NeonColor.WHITE} className="text-lg">
            Hidden Gems of Music Tourism edition
          </NeonText>
        </div>
        <div className="flex flex-row items-center justify-between">
          <PindropIcon
            size="xl"
            neonColorPrimary={NeonColor.PINK}
            neonColorSecondary={NeonColor.GREEN}
          />
          <div className="text-xl">&times;</div>
          {/* Hidden gems logo */}
          <a
            href={hiddenGemsItineraryLink}
            target="_blank"
            rel="noopener noreferrer"
            className="underline hover:text-white pl-2"
          >
            <img
              src={hiddenGemsLogo}
              alt="Hidden Gems logo"
              width={100}
              height={100}
              className="ml-2"
            />
          </a>
        </div>
        <div className="text-md text-gray-400">
          Pindrop is partnering with the Hidden Gems of Music Tourism with a
          collection of unique puzzles from The Hidden Gems of Music Tourism
          Guide.
        </div>
        <div className="flex flex-col space-y-4 text-lg w-full">
          <div className="flex flex-row space-x-4 items-center">
            <VolumeOnIcon size="lg" neonColor={NeonColor.ORANGE} />
            <div className="pt-0.5">
              <b>Listen</b> to a song by a mystery artist
            </div>
          </div>
          <div className="flex flex-row space-x-4 items-center">
            <MapPinIcon size="lg" neonColor={NeonColor.ORANGE} />
            <div className="flex flex-col items-start pt-0.5">
              <div>
                <b>Guess the city</b> where the artist is from
              </div>
              <div className="text-sm">Drop a pin on the map</div>
            </div>
          </div>
          <div className="flex flex-row space-x-4 items-center">
            <SunIcon size="lg" neonColor={NeonColor.ORANGE} />
            <div className="pt-0.5">
              <b>Explore</b> more about the artist & its city
            </div>
          </div>
        </div>
        <div className="text-sm pt-4">
          {TOTAL_RUMBLE_ROUNDS} rounds, scores added up. Good luck!
        </div>
      </div>
    </Modal>
  );
};
export default HiddenGemsGeoIntroModal;
