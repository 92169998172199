import React from "react";
import NeonText, { NeonColor } from "../components/basic/NeonText";
import { useNavigate } from "react-router-dom";
import CalendarIcon from "../components/Icon/CalendarIcon";
import BriefcaseIcon from "../components/Icon/BriefcaseIcon";

const Home: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="h-screen flex flex-col items-center justify-center">
        <NeonText color={NeonColor.WHITE} className="text-3xl py-8">
          Pindrop
        </NeonText>
        <div
          className="flex flex-col items-center justify-center w-full flex-1
        md:flex-row
      "
        >
          <button
            className="w-full h-full flex-1 flex flex-col items-center justify-center
          bg-transparent hover:bg-pink-100 hover:bg-opacity-20 p-4 transition-colors"
            onClick={() => navigate("/daily")}
          >
            <CalendarIcon size="2xl" neonColor={NeonColor.PINK} />
            <NeonText color={NeonColor.PINK} className="text-xl py-2">
              Daily
            </NeonText>
          </button>
          <button
            className="w-full h-full flex-1 flex flex-col items-center justify-center
          bg-transparent hover:bg-blue-100 hover:bg-opacity-20 p-4 transition-colors"
            onClick={() => navigate("/rumble")}
          >
            <BriefcaseIcon size="2xl" neonColor={NeonColor.BLUE} />
            <NeonText color={NeonColor.BLUE} className="text-xl py-2">
              Rumble
            </NeonText>
          </button>
        </div>
      </div>
    </>
  );
};

export default Home;
