import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Legend,
  BarElement,
  Tooltip,
} from "chart.js";
import Spinner from "../../components/Spinner";
import { get } from "@aws-amplify/api";
import { GameMode } from "../../services/user_tracking";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Filler,
  Legend,
  Tooltip
);

/* Metric data types */
type GeoUsageMetricsData = {
  date: string;
  artist: string;
  visits: number;
  completions: number;
  shares: number;
  saves: number;
}[];

interface GeoScoreDataItem {
  date: string;
  numPlayers: number[]; // each index (0-indexed) is the number of players, the value is the # of games completed w/ that amount
}
type GeoScoresData = GeoScoreDataItem[];

type GeoUserData = {
  usageMetrics: GeoUsageMetricsData;
  scores: GeoScoresData;
};

/* GeoUsageTotalsLineChart */
interface GeoUsageTotalsLineChartProps {
  data: GeoUsageMetricsData;
}
const GeoUsageTotalsLineChart: React.FC<GeoUsageTotalsLineChartProps> = ({
  data,
}) => {
  // order data by date
  data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });
  const labels = data.map((item) => [item.date]);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Visits",
        data: data.map((item) => item.visits),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Completions",
        data: data.map((item) => item.completions),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Saves",
        data: data.map((item) => item.saves),
        borderColor: "rgb(255, 205, 86)",
        backgroundColor: "rgba(255, 205, 86, 0.5)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          beforeBody: (tooltipItems: any) => {
            const artist = data[tooltipItems[0].dataIndex].artist;
            return `Artist: ${artist}`;
          },
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

/* GeoScoresBarChart */
interface GeoScoresBarChartProps {
  data: GeoScoresData;
}
const GeoScoresBarChart: React.FC<GeoScoresBarChartProps> = ({ data }) => {
  // order data by date
  data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });

  const colors = [
    "rgba(255, 99, 132, 0.8)",
    "rgba(54, 162, 235, 0.8)",
    "rgba(255, 206, 86, 0.8)",
    "rgba(75, 192, 192, 0.8)",
    "rgba(153, 102, 255, 0.8)",
    "rgba(255, 159, 64, 0.8)",
  ];

  // For datasets, we need a separate dataset for each numPlayers amount (1-4),
  // with the number of games completed with that many players as the data
  const playersDatasets = [];
  for (let i = 1; i < 5; i++) {
    playersDatasets.push({
      label: `${i} player${i !== 1 ? "s" : ""}`,
      data: data.map((item) => item.numPlayers[i]),
      backgroundColor: colors[i % colors.length],
    });
  }

  const chartData = {
    labels: data.map((item) => [item.date]),
    datasets: playersDatasets,
  };

  const options = {
    scales: {
      x: {
        stacked: true,
        ticks: {
          display: false, // Hides the tick labels for the x-axis
        },
      },
      y: {
        stacked: true,
        ticks: {
          display: true, // Hides the tick labels for the y-axis
          font: {
            size: 16,
            family: "Rajdhani",
          },
          stepSize: 1,
          color: "white",
        },
      },
    },
    maintainAspectRatio: false,
  };

  return <Bar data={chartData} options={options} />;
};

const GeoStats = () => {
  const apiName = "mgdapi";
  const path = `/admin/stats?game_mode=${GameMode.GEO}`;

  const [geoUserData, setGeoUserData] = useState<GeoUserData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const getData = async () => {
    try {
      const getOperation = get({
        apiName,
        path,
      });
      const response = await getOperation.response;
      const data = await response.body.json();
      setGeoUserData(data as unknown as GeoUserData);
    } catch (error: any) {
      setError(error.response.body);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center w-full p-4 space-y-4">
      {!geoUserData && !error && <Spinner />}
      {error && <div>Error: {error}</div>}
      {geoUserData && (
        <>
          {geoUserData.usageMetrics && (
            <div className="flex flex-row justify-center h-[600px] w-full">
              <GeoUsageTotalsLineChart data={geoUserData.usageMetrics} />
            </div>
          )}
          {geoUserData.scores && (
            <div className=" flex flex-row justify-center h-64 w-full">
              <GeoScoresBarChart data={geoUserData.scores} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default GeoStats;
