import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const HelpCircleIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M12 17H12.01 M9.09 9.00008C9.3251 8.33175 9.78915 7.76819 10.4 7.40921C11.0108 7.05024 11.7289 6.91902 12.4272 7.03879C13.1255 7.15857 13.7588 7.52161 14.2151 8.06361C14.6713 8.60561 14.9211 9.2916 14.92 10.0001C14.92 12.0001 11.92 13.0001 11.92 13.0001 M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z";
  return <StrokeIcon {...props} path={path} />;
};

export default HelpCircleIcon;
