import React from "react";

// Define the props for the IconButton component
type IconButtonProps = {
  children: React.ReactNode; // Accept any valid React node as children
  onClick: () => void; // Function to be called when the button is clicked
  disabled?: boolean; // Optional boolean to disable the button
};

// The IconButton component
const IconButton: React.FC<IconButtonProps> = ({
  children,
  onClick,
  disabled = false,
}) => {
  return (
    <button
      className={`p-2 transition duration-150 ease-in-out rounded-full ${
        disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
      }`}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default IconButton;
