import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Filler,
  Legend,
  BarElement,
  Tooltip,
} from "chart.js";
import Spinner from "../../components/Spinner";
import { get } from "@aws-amplify/api";
import { GameMode } from "../../services/user_tracking";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Filler,
  Legend,
  Tooltip
);

/* Metric data types */
type DailyUsageMetricsData = {
  date: string;
  artist: string;
  visits: number;
  completions: number;
  shares: number;
  saves: number;
}[];

interface DailyScoreDataItem {
  date: string;
  artist: string;
  averageScore: number; // Assuming this is the float value between 1 and 7
  n: number; // number of scores for that day/artist
}

type DailyScoresData = DailyScoreDataItem[];

type DailyUserData = {
  usageMetrics: DailyUsageMetricsData;
  scores: DailyScoresData;
};

/* DailyUsageTotalsLineChart */
interface DailyUsageTotalsLineChartProps {
  data: DailyUsageMetricsData;
}
const DailyUsageTotalsLineChart: React.FC<DailyUsageTotalsLineChartProps> = ({
  data,
}) => {
  // order data by date
  data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });
  const labels = data.map((item) => [item.date, item.artist]);

  const chartData = {
    labels,
    datasets: [
      {
        label: "Visits",
        data: data.map((item) => item.visits),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Completions",
        data: data.map((item) => item.completions),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Shares",
        data: data.map((item) => item.shares),
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.5)",
      },
      {
        label: "Saves",
        data: data.map((item) => item.saves),
        borderColor: "rgb(255, 205, 86)",
        backgroundColor: "rgba(255, 205, 86, 0.5)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          beforeBody: (tooltipItems: any) => {
            const artist = data[tooltipItems[0].dataIndex].artist;
            return `Artist: ${artist}`;
          },
        },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

/* DailyScoresBarChart */
interface DailyScoresBarChartProps {
  data: DailyScoresData;
}
const DailyScoresBarChart: React.FC<DailyScoresBarChartProps> = ({ data }) => {
  // filter out data where n is less than 1 and order data by date
  data = data.filter((item) => item.n >= 1);
  data.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateA.getTime() - dateB.getTime();
  });
  const chartData = {
    labels: data.map((item) => [item.date, item.artist]),
    datasets: [
      {
        label: "Average Score",
        data: data.map((item) => item.averageScore),
        backgroundColor: "rgba(150, 200, 235, 0.6)",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: 7, // Since the score is from 1 to 7
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          beforeBody: (tooltipItems: any) => {
            const n = data[tooltipItems[0].dataIndex].n;
            return `N: ${n}`;
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={options} />;
};

const DailyStats = () => {
  const apiName = "mgdapi";
  const path = `/admin/stats?game_mode=${GameMode.DAILY}`;

  const [dailyUserData, setDailyUserData] = useState<DailyUserData | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);

  const getData = async () => {
    try {
      const getOperation = get({
        apiName,
        path,
      });
      const response = await getOperation.response;
      const data = await response.body.json();
      setDailyUserData(data as unknown as DailyUserData);
    } catch (error: any) {
      setError(error.response.body);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col items-center w-full p-4 space-y-4">
      {!dailyUserData && !error && <Spinner />}
      {error && <div>Error: {error}</div>}
      {dailyUserData && (
        <>
          {dailyUserData.usageMetrics && (
            <div className="flex flex-row justify-center h-[600px] w-full">
              <DailyUsageTotalsLineChart data={dailyUserData.usageMetrics} />
            </div>
          )}
          {dailyUserData.scores && (
            <div className=" flex flex-row justify-center h-64 w-full">
              <DailyScoresBarChart data={dailyUserData.scores} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DailyStats;
