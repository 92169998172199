import React from "react";
import { Puzzle } from "../../data/puzzles";
import AnswerModal from "../AnswerModal";
import Button from "../basic/Button";

/*
Small component to display artist basic info and button to show more info
*/
export const ArtistOneLiner = ({ puzzle }: { puzzle: Puzzle }) => {
  const [showAnswerModal, setShowAnswerModal] = React.useState(false);

  return (
    <>
      <div className="flex flex-row items-center w-80">
        <img
          src={puzzle.artist_image}
          alt="puzzle"
          className="w-24 h-24 rounded-md"
          style={{ objectFit: "cover" }}
        />
        <div className="flex flex-col items-start ml-4">
          <p className="text-base font-semibold">{puzzle.artist_name}</p>
          <p className="text-sm text-gray-400">{puzzle.artist_location}</p>
        </div>
        <div className="ml-auto">
          <Button onClick={() => setShowAnswerModal(true)}>More</Button>
        </div>
      </div>
      <AnswerModal
        puzzle={puzzle}
        isOpen={showAnswerModal}
        onClose={() => setShowAnswerModal(false)}
      />
    </>
  );
};

/*
Slightly larger component to display artist + itinerary info and button to show more info
*/
export const HiddenGemsOneLiner = ({ puzzle }: { puzzle: Puzzle }) => {
  const [showAnswerModal, setShowAnswerModal] = React.useState(false);

  return (
    <>
      <div className="flex flex-row items-center max-w-[650px] mx-4">
        <div className="flex flex-col items-center">
          <img
            src={puzzle.itinerary_image}
            alt="puzzle"
            className="w-24 h-24 rounded-md"
            style={{ objectFit: "cover" }}
          />
          <p className="text-md text-gray-400 font-semibold mt-2 w-24 text-center">
            {puzzle.artist_location}
          </p>
          {/* Show more button under image on xs screens */}
          <div className="mt-2 sm:hidden">
            <Button onClick={() => setShowAnswerModal(true)}>
              Explore the city
            </Button>
          </div>
        </div>
        <div className="flex flex-col items-start ml-4 mr-2">
          <p className="text-xl font-semibold">{puzzle.itinerary_title}</p>
          <p className="text-sm sm:text-base italic text-gray-400 line-clamp-3">
            {puzzle.itinerary_description}
          </p>
        </div>
        <div className="ml-auto hidden sm:block">
          <Button onClick={() => setShowAnswerModal(true)}>
            Explore the city
          </Button>
        </div>
      </div>
      <AnswerModal
        puzzle={puzzle}
        isOpen={showAnswerModal}
        onClose={() => setShowAnswerModal(false)}
        hiddenGemsMode={true}
      />
    </>
  );
};
