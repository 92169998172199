import { Puzzle } from "../data/puzzles";
import SongControl from "./SongControl";
import SpotifyEmbedPlayer from "./SpotifyEmbedController";

/*
Dual song control component gives user two options for the controller:
Spotify iFrame player or Spotify oEmbed player. The iFrame player is
buggy but offers more control, while the oEmbed player is more stable
and is the backup option (users will get the option to switch to it if
they run into problems with the iFrame player).

8/2/24: making the embed player the default player b/c too many ppl ran into
issues with the iframe player
*/
interface DualSongControlProps {
  puzzle: Puzzle;
  // Playing/Set Playing only work for iFrame controller. Embed needs manual control
  playing: boolean;
  setPlaying: (playing: boolean) => void;
  // Blurred only matters for the embed controller
  blurred: boolean;
}
const DualSongControl = ({
  puzzle,
  playing,
  setPlaying,
  blurred,
}: DualSongControlProps) => {
  /* Fetch player to use from "spotifyPlayer" cookie. If not present default to iframe */
  const cookiePlayer = document.cookie
    .split("; ")
    .find((row) => row.startsWith("spotifyPlayer="))
    ?.split("=")[1];

  if (cookiePlayer === "embed" || !cookiePlayer) {
    return (
      <div className="mx-8 my-2">
        <SpotifyEmbedPlayer
          trackUri={puzzle.song_spotify_id}
          blurred={blurred}
        />
      </div>
    );
  } else {
    return (
      <SongControl puzzle={puzzle} playing={playing} setPlaying={setPlaying} />
    );
  }
};

/* Utility function to set the player to use in the cookie */
export const setPlayerToUseCookie = (player: "iframe" | "embed") => {
  document.cookie = `spotifyPlayer=${player}; max-age=31536000; path=/`;
  // refresh page
  window.location.reload();
};

export default DualSongControl;
