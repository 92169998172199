import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GeoGamePlayerRecords } from "../pages/GeoGame";
import Modal from "./basic/Modal";
import NeonText, { NeonColor } from "./basic/NeonText";
import Button from "./basic/Button";
import {
  ArtistOneLiner,
  HiddenGemsOneLiner,
} from "./GeoguessMap/ArtistOneLiner";
import { PindropHiddenGemsExplanation } from "./AnswerModal/ItineraryInfo";

interface GameRecapModalProps {
  isOpen: boolean;
  onClose: () => void;
  playerRecords: GeoGamePlayerRecords[];
  hiddenGemsMode?: boolean;
}

const GameRecapModal = ({
  isOpen,
  onClose,
  playerRecords,
  hiddenGemsMode,
}: GameRecapModalProps) => {
  if (playerRecords.length === 0) return null;

  const sortedPlayerRecords = playerRecords.sort(
    (a, b) =>
      b.roundRecords.reduce((acc, record) => acc + record.points, 0) -
      a.roundRecords.reduce((acc, record) => acc + record.points, 0)
  );
  const winningScore = sortedPlayerRecords[0].roundRecords.reduce(
    (acc, record) => acc + record.points,
    0
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="sm:max-w-xl">
      <div className="flex flex-col space-y-4 items-center">
        <div className="flex flex-col space-y-4 items-center">
          {/* Header */}
          <NeonText className="text-3xl" color={NeonColor.WHITE}>
            {sortedPlayerRecords.length === 1
              ? `${winningScore} points`
              : `${sortedPlayerRecords[0].name} won!`}
          </NeonText>
          {/* Score breakdown */}
          <ScoreBreakdownChart
            maxScore={winningScore}
            playerRecords={playerRecords}
          />
          {/* Perfect scores */}
          {/* <PerfectScores playerRecords={playerRecords} /> */}
          {hiddenGemsMode && <PindropHiddenGemsExplanation />}
          {/* Artist panels */}
          <div className="flex flex-col space-y-4 items-start">
            {sortedPlayerRecords[0].roundRecords.map((record, i) => (
              <div key={i}>
                {hiddenGemsMode ? (
                  <div className="py-2">
                    <HiddenGemsOneLiner puzzle={record.puzzle} />
                  </div>
                ) : (
                  <ArtistOneLiner puzzle={record.puzzle} />
                )}
              </div>
            ))}
          </div>
        </div>
        {/* Hidden Gems CTA button */}
        {hiddenGemsMode && (
          <div className="flex flex-row items-center space-x-4">
            <div className="text-md">
              Enjoyed exploring the world? Join the{" "}
              <b>Sonic Gem Seeker Group</b> and connect with music itinerary
              curators from all around the world!
            </div>
            <a
              href="https://www.musiccitiescommunity.com/share/ZL_MReV1kavOWyon?utm_source=manual"
              target="_blank"
              rel="noreferrer"
            >
              <Button color="light" onClick={onClose}>
                Join
              </Button>
            </a>
          </div>
        )}
        {/* Done button */}
        <Button color="dark" onClick={onClose}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

/*
Chart.js chart to display score breakdown for a series of roundRecords.
Horizontal stacked bar chart where each bar represents a roundRecord.
- maxScore: the maximum value for x-axis
- roundRecords: GeoGameRoundRecords[] to display
*/
// Register the necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface ScoreBreakdownChartProps {
  maxScore: number;
  playerRecords: GeoGamePlayerRecords[];
}
const ScoreBreakdownChart: React.FC<ScoreBreakdownChartProps> = ({
  maxScore,
  playerRecords,
}) => {
  const colors = [
    "rgba(255, 99, 132, 0.8)",
    "rgba(54, 162, 235, 0.8)",
    "rgba(255, 206, 86, 0.8)",
    "rgba(75, 192, 192, 0.8)",
    "rgba(153, 102, 255, 0.8)",
    "rgba(255, 159, 64, 0.8)",
  ];

  // For datasets, we need a separate dataset for each round, with the score for each player as the data
  const numRounds = playerRecords[0].roundRecords.length;
  const datasets = [];
  for (let i = 0; i < numRounds; i++) {
    datasets.push({
      label: playerRecords[0].roundRecords[i].puzzle.artist_name,
      data: playerRecords.map((player) => player.roundRecords[i].points),
      backgroundColor: colors[i % colors.length],
    });
  }

  const data = {
    labels: playerRecords.map((player) =>
      playerRecords.length === 1
        ? "Score"
        : `${player.name}: ${player.roundRecords.reduce(
            (acc, record) => acc + record.points,
            0
          )}`
    ),
    datasets,
  };

  const options = {
    indexAxis: "y" as any, // horizontal
    scales: {
      x: {
        stacked: true,
        max: maxScore,
        grid: {
          display: false, // Hides the grid lines for the x-axis
        },
        ticks: {
          display: false, // Hides the tick labels for the x-axis
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false, // Hides the grid lines for the y-axis
        },
        ticks: {
          display: true, // Hides the tick labels for the y-axis
          font: {
            size: 16,
            family: "Rajdhani",
          },
          color: "white",
        },
      },
    },
    plugins: {
      legend: {
        display: true, // Adjust this to false if you want to hide the legend as well
      },
    },
    maintainAspectRatio: false,
  };

  return (
    <div className="px-2">
      <Bar data={data} options={options} />
    </div>
  );
};

export default GameRecapModal;
