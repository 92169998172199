import { useEffect, useState } from "react";
import {
  Puzzle,
  fetchDailyPuzzle,
  fetchScoreDistribution,
} from "../data/puzzles";
import AnswerModal from "../components/AnswerModal";
import { GameMode, registerVisit } from "../services/user_tracking";
import GuessCityPanel, { CityGuess } from "../components/GuessCityPanel";
import DailyIntroModal from "../components/DailyIntroModal";
import Spinner from "../components/Spinner";
import Button from "../components/basic/Button";
import GoogleMap from "../components/GoogleMap";
import IconButton from "../components/basic/IconButton";
import HelpCircleIcon from "../components/Icon/HelpCircleIcon";
import RefreshedModal from "../components/RefreshedModal";
import Cookies from "js-cookie";
import DualSongControl from "../components/DualSongControl";

interface CookieGameRecord {
  datetime: string;
  artist: string;
  emojiStory: string;
  gameStatus: GameStatus;
  guesses: CityGuess[];
}

export enum GameStatus {
  PLAYING = "playing",
  WON = "won",
  LOST = "lost",
}
const DailyGame = () => {
  const [puzzle, setPuzzle] = useState<Puzzle | null>(null);
  const [getPuzzleError, setGetPuzzleError] = useState<null | string>(null);
  const [musicPlaying, setMusicPlaying] = useState(false); // // intention to play or not
  const [showDailyIntroModal, setShowDailyIntroModal] = useState(false);
  const [showRefreshedModal, setShowRefreshedModal] = useState(false);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [scoreDistribution, setScoreDistribution] = useState<number[]>([
    0, 0, 0, 0, 0, 0, 0,
  ]);
  /* Cookie-based state */
  const [emojiStory, setEmojiStory] = useState("");
  const [gameStatus, setGameStatus] = useState<GameStatus>(GameStatus.PLAYING);
  const [guesses, setGuesses] = useState<CityGuess[]>([]);

  // Google maps embed
  const gmaps_key = "AIzaSyBYJdbJeHofpU40D3Nad3ZVV7kuk3A2E_E";

  // Grab daily puzzle
  useEffect(() => {
    fetchDailyPuzzle()
      .then((dailyPuzzle) => {
        setPuzzle(dailyPuzzle as unknown as Puzzle);
        setGetPuzzleError(null);
      })
      .catch((error) => {
        setGetPuzzleError(
          "The Spotify player service looks like it's down right now. Please try again later."
        );
      });
  }, []);

  // Grab score distribution after getting puzzle
  useEffect(() => {
    if (!puzzle) return;
    fetchScoreDistribution(puzzle.artist_name).then((scoreDistribution) => {
      setScoreDistribution(scoreDistribution as unknown as number[]);
    });
  }, [puzzle]);

  // Register a new visit on each page load
  useEffect(() => {
    if (process.env.NODE_ENV === "production")
      registerVisit({ game_mode: GameMode.DAILY });
  }, []);

  // On first loading page, show either DailyIntroModal or RefreshedModal
  // based on if refreshed_at query param exists and is less than 10 seconds ago
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refreshed_at = urlParams.get("refreshed_at");
    if (refreshed_at && Date.now() - parseInt(refreshed_at) < 10000) {
      setShowRefreshedModal(true);
    } else {
      setShowDailyIntroModal(true);
      // remove any refreshed_at query param
      window.history.replaceState({}, "", window.location.pathname);
    }
  }, []);

  // Read cookies - if they already played this artist, setGuesses, setGameStatus, setEmojiStory
  useEffect(() => {
    if (puzzle?.artist_name) {
      const gameRecords: CookieGameRecord[] = JSON.parse(
        Cookies.get("gameRecords") || "[]"
      );
      const currentGameRecord = gameRecords.find(
        (record) => record.artist === puzzle.artist_name
      );

      if (currentGameRecord) {
        setEmojiStory(currentGameRecord.emojiStory);
        setGameStatus(currentGameRecord.gameStatus as GameStatus);
        setGuesses(currentGameRecord.guesses);
      }
    }
  }, [puzzle]);

  // When game status changes, update cookies with guesses, game status, and emoji story
  useEffect(() => {
    if (!puzzle?.artist_name) return;

    const gameRecords: CookieGameRecord[] = JSON.parse(
      Cookies.get("gameRecords") || "[]"
    );
    const existingRecordIndex = gameRecords.findIndex(
      (record) => record.artist === puzzle.artist_name
    );

    const newRecord: CookieGameRecord = {
      datetime: new Date().toISOString(),
      artist: puzzle.artist_name,
      emojiStory,
      gameStatus,
      guesses,
    };

    if (existingRecordIndex !== -1) {
      // Update existing record
      gameRecords[existingRecordIndex] = newRecord;
    } else {
      // Add new record
      gameRecords.push(newRecord);
    }

    Cookies.set("gameRecords", JSON.stringify(gameRecords));
  }, [emojiStory, gameStatus, guesses, puzzle]);

  if (!puzzle?.artist_spotify_id)
    return (
      <div className="flex flex-col items-center mt-10 space-y-10">
        {getPuzzleError && (
          <div className="px-8 text-center">{getPuzzleError}</div>
        )}
        <Spinner />
      </div>
    );

  return (
    <div className="flex flex-col space-y-4 sm:p-4 items-center">
      {/* Song play controller */}
      <div className="flex flex-col space-y-4 md:flex-row md:space-y-0 md:space-x-8">
        <DualSongControl
          puzzle={puzzle}
          playing={musicPlaying}
          setPlaying={setMusicPlaying}
          blurred={gameStatus === GameStatus.PLAYING}
        />
      </div>
      {/* Guesses */}
      <GuessCityPanel
        answer={{ latitude: puzzle.latitude, longitude: puzzle.longitude }}
        setShowAnswerModal={setShowAnswerModal}
        setEmojiStory={setEmojiStory}
        artist_name={puzzle.artist_name}
        gameStatus={gameStatus}
        setGameStatus={setGameStatus}
        guesses={guesses}
        setGuesses={setGuesses}
      />
      {/* Show answer button if game is over */}
      {gameStatus !== GameStatus.PLAYING && (
        <Button color="dark" onClick={() => setShowAnswerModal(true)}>
          Show Answer
        </Button>
      )}
      {/* Map */}
      <div className="w-full md:w-3/4 lg:w-1/2 h-[450px] rounded-lg overflow-hidden">
        <GoogleMap gmapsKey={gmaps_key} guesses={guesses} />
      </div>
      {/* intro modal */}
      {showDailyIntroModal && (
        <DailyIntroModal
          isOpen={showDailyIntroModal}
          onClose={() => {
            setMusicPlaying(true); // start playing the song
            setShowDailyIntroModal(false);
          }}
        />
      )}
      {/* refreshed modal */}
      {showRefreshedModal && (
        <RefreshedModal
          isOpen={showRefreshedModal}
          onClose={() => {
            setMusicPlaying(true); // start playing the song
            setShowRefreshedModal(false);
          }}
        />
      )}
      {/* answer modal */}
      {showAnswerModal && (
        <AnswerModal
          puzzle={puzzle}
          isOpen={showAnswerModal}
          onClose={() => setShowAnswerModal(false)}
          emojiStory={emojiStory}
          numGuesses={guesses.length}
          gameStatus={gameStatus}
          scoreDistribution={scoreDistribution}
        />
      )}
      {/* Button to reopen intro modal in top left of screen */}
      <div className="fixed top-0 left-2">
        <IconButton onClick={() => setShowDailyIntroModal(true)}>
          <HelpCircleIcon className="text-gray-400" size="md" />
        </IconButton>
      </div>
    </div>
  );
};

export default DailyGame;
