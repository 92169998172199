import React from "react";
import ReactModal from "react-modal";

// Ensure to set the app element for accessibility reasons
ReactModal.setAppElement("#root");

type ModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  zIndex?: number;
};

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  className,
  zIndex = 10,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={`fixed inset-0 bg-color-black-gradient text-white p-5 rounded-lg max-w-[90vw] max-h-[85vh] overflow-auto mx-auto my-0
      ${zIndex === 10 ? "z-10" : `z-${zIndex}`} ${className}`}
      overlayClassName={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ${
        zIndex === 10 ? "z-10" : `z-${zIndex}`
      }`}
      style={{
        // For properties not covered by Tailwind classes
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
        },
      }}
    >
      <button
        onClick={onClose}
        className="absolute top-2.5 right-2.5 bg-transparent border-none text-white text-3xl"
      >
        &times;
      </button>
      {children}
    </ReactModal>
  );
};

export default Modal;
