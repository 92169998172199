import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#121212",
    },
    text: {
      primary: "#fff",
    },
  },
  typography: {
    fontFamily: ["Rajdhani", "Fira Sans", "sans-serif"].join(","),
  },
});
