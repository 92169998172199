import React from "react";
import { sizeClasses } from "./StrokeIcon";

export interface FillIconProps {
  size: "2xl" | "xl" | "lg" | "md" | "sm" | "xs" | "2xs";
  className?: string;
  path: string;
}

const FillIcon: React.FC<FillIconProps> = ({ size, className = "", path }) => {
  const sizeClass = sizeClasses[size];

  return (
    <svg
      className={`${sizeClass} ${className} fill-current`}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={path} />
    </svg>
  );
};

export default FillIcon;
