import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const CalendarIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M16 2V6 M8 2V6 M3 10H21 M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z";
  return <StrokeIcon {...props} path={path} />;
};

export default CalendarIcon;
