import { useState } from "react";
import { CoordinatePair, getDistanceAndBearing } from "./location";
import {
  bearingToEmoji,
  bearingToRotationClass,
  distanceToEmoji,
  distanceToNeonColor,
  numberToEmoji,
} from "./emoji";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { worldCities } from "../../data/world_cities";
import { MAX_GUESSES } from "../../constants";
import { recordDailyScore } from "../../services/user_tracking";
import Button from "../basic/Button";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../basic/themes";
import { GameStatus } from "../../pages/DailyGame";
import ArrowUpIcon from "../Icon/ArrowUpIcon";

export interface CityGuess {
  name: string;
  latitude: number;
  longitude: number;
  distance: number;
  bearing: number;
}

/*
Wordle-like guessing panel to guess the city from a dropdown list of known cities.
Shows guesses and how close they were as well as bearing below.
*/
interface GuessCityPanelProps {
  answer: CoordinatePair;
  setShowAnswerModal: (show: boolean) => void;
  setEmojiStory: (story: string) => void;
  artist_name: string;
  gameStatus: GameStatus;
  setGameStatus: (status: GameStatus) => void;
  guesses: CityGuess[];
  setGuesses: (guesses: CityGuess[]) => void;
}
const GuessCityPanel = ({
  answer,
  setShowAnswerModal,
  setEmojiStory,
  artist_name,
  gameStatus,
  setGameStatus,
  guesses,
  setGuesses,
}: GuessCityPanelProps) => {
  const handleAddGuess = async (guess: CityGuess) => {
    const newGuesses = [...guesses, guess];
    setGuesses(newGuesses);
    // if guess is within 10km of answer, show answer modal
    if (guess.distance < 10) {
      setShowAnswerModal(true);
      // Create emoji story - each line is a guess
      // Color - direction - distance
      // e.g. 🟢🎯 0️⃣
      setEmojiStory(
        newGuesses
          .map((guess) => {
            return `${distanceToEmoji(guess.distance)}${
              guess.distance < 10 ? "🎯" : bearingToEmoji(guess.bearing)
            } ${numberToEmoji(Math.floor(guess.distance / 10) * 10)}`;
          })
          .join("\n")
      );
      setGameStatus(GameStatus.WON);
      if (process.env.NODE_ENV === "production") {
        await recordDailyScore({
          artist_name,
          num_guesses: newGuesses.length,
        });
      }
    }
    // if you've made 6 unsuccessful guesses, show answer modal (but you lose)
    else if (newGuesses.length >= MAX_GUESSES) {
      setShowAnswerModal(true);
      setEmojiStory(
        newGuesses
          .map((guess) => {
            return `${distanceToEmoji(guess.distance)}${
              guess.distance < 10 ? "🎯" : bearingToEmoji(guess.bearing)
            } ${numberToEmoji(Math.floor(guess.distance / 10) * 10)}`;
          })
          .join("\n")
      );
      setGameStatus(GameStatus.LOST);
      if (process.env.NODE_ENV === "production") {
        await recordDailyScore({
          artist_name,
          num_guesses: newGuesses.length + 1,
        });
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <CitySelector
        handleAddGuess={handleAddGuess}
        gameStatus={gameStatus}
        guessesRemaining={MAX_GUESSES - guesses.length}
        answer={answer}
      />
      <PastGuesses guesses={guesses} answer={answer} />
    </div>
  );
};

/*
Dropdown list of cities to guess from. Read cities from worldcities.csv.
On submission, add guess to list of guesses.
Uses Material UI Autocomplete.
*/
interface CitySelectorProps {
  handleAddGuess: (guess: CityGuess) => void;
  gameStatus: GameStatus;
  guessesRemaining: number;
  answer: CoordinatePair;
}
const CitySelector = ({
  handleAddGuess,
  gameStatus,
  guessesRemaining,
  answer,
}: CitySelectorProps) => {
  const [guess, setGuess] = useState<CityGuess | null>(null);
  const [currentGuessLength, setCurrentGuessLength] = useState(0);

  const cities = worldCities.map((city) => ({
    label: `${city.city_ascii}, ${
      city.country === "United States" && city.admin_name
        ? `${city.admin_name}, `
        : ""
    }${city.country}`,
    latitude: city.lat,
    longitude: city.lng,
  }));

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 10,
  });

  const handleChange = (event: any, newValue: any) => {
    if (newValue?.label)
      setGuess({
        name: newValue.label,
        latitude: newValue.latitude,
        longitude: newValue.longitude,
        ...getDistanceAndBearing({ ...newValue }, answer),
      });
    else setGuess(null);
  };

  const handleInputChange = (e: any) => {
    setCurrentGuessLength(e.target.value?.length || 0);
  };

  return (
    <div className="flex flex-col items-start space-y-1">
      <div className="text-gray-400 text-sm">
        {guessesRemaining === 1
          ? `Last guess`
          : `${guessesRemaining} guesses remaining`}
      </div>
      <div className="flex flex-row space-x-4 items-center">
        <ThemeProvider theme={darkTheme}>
          {" "}
          <Autocomplete
            disablePortal
            id="city-selector"
            filterOptions={filterOptions}
            options={currentGuessLength > 0 ? cities : []} // make user have to put in at least 1 char
            noOptionsText={"Type your guess"}
            sx={{
              width: {
                xs: 250,
                sm: 300,
              },
            }}
            renderInput={(params) => <TextField {...params} label="City" />}
            onChange={handleChange}
            onInputChange={handleInputChange}
          />
        </ThemeProvider>
        <Button
          color="light"
          onClick={() => {
            if (guess) handleAddGuess(guess);
          }}
          disabled={!guess || gameStatus !== GameStatus.PLAYING}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

/*
Panel to show past guesses and how close they were to the answer.
For each past guess, show the name, emoji color icon for distance, the actual distance, and emoji for bearing.
*/
interface PastGuessesProps {
  guesses: CityGuess[];
  answer: CoordinatePair;
}
const PastGuesses = ({ guesses, answer }: PastGuessesProps) => {
  return (
    <div className="grid gap-4">
      {guesses.map((guess, index) => (
        <div
          key={index}
          className="grid grid-cols-[7fr_1fr_3fr] sm:grid-cols-[7fr_1fr_2fr] gap-4 items-center"
        >
          <div className="text-lg">
            <span className="font-medium">{guess.name.split(",")[0]}</span>
            <span className="font-light text-sm">
              {guess.name.split(",").slice(1, guess.name.split(",").length)}
            </span>
          </div>
          <div className="text-lg">
            {guess.distance < 10 ? (
              "🎯"
            ) : (
              <ArrowUpIcon
                className={bearingToRotationClass(guess.bearing)}
                size="md"
                neonColor={distanceToNeonColor(guess.distance)}
              />
            )}
          </div>
          <div className="text-lg">
            <span className="font-light">
              {guess.distance < 10
                ? "0"
                : Math.round(guess.distance).toLocaleString()}{" "}
              km
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GuessCityPanel;
