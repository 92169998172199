import { Puzzle } from "../../data/puzzles";
import NeonText, { NeonColor } from "../basic/NeonText";

const BioPanel = ({ puzzle }: { puzzle: Puzzle }) => {
  const bio = puzzle.artist_bio;
  const style = puzzle.artist_style;
  const curiosity = puzzle.artist_curiosity;
  const authors_note = puzzle.authors_note;

  return (
    <div className="flex flex-col space-y-4 items-center w-full">
      <div className="w-full text-left">
        <NeonText color={NeonColor.GREEN} className="text-md">
          About {puzzle.artist_name}
        </NeonText>
      </div>
      <div>{bio}</div>
      {style && (
        <>
          <div className="font-semibold">Style</div>
          <div>{style}</div>
        </>
      )}
      {curiosity && (
        <>
          <div className="font-semibold">Curiosity</div>
          <div>{curiosity}</div>
        </>
      )}
      {authors_note && (
        <>
          <div className="font-semibold">Author&rsquo;s Note</div>
          <div>{authors_note}</div>
        </>
      )}
    </div>
  );
};

export default BioPanel;
