import React from "react";
import StrokeIcon, { StrokeIconProps } from "./StrokeIcon";

const MinusCircleIcon: React.FC<Omit<StrokeIconProps, "path">> = (props) => {
  const path =
    "M8 12H16 M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z";
  return <StrokeIcon {...props} path={path} />;
};

export default MinusCircleIcon;
