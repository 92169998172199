import React, { useRef, useEffect, MutableRefObject, useCallback } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import NeonText, { NeonColor } from "../basic/NeonText";
import { Puzzle } from "../../data/puzzles";
import Button from "../basic/Button";
import { CoordinatePair } from "../GuessCityPanel/location";
import { GeoGameRoundRecord, PLAYER_IDX_TO_COLOR } from "../../pages/GeoGame";
import CheckIcon from "../Icon/CheckIcon";
import { ArtistOneLiner, HiddenGemsOneLiner } from "./ArtistOneLiner";
import hiddenGemsLogoStatic from "../../img/hidden_gems_logo_static.png";

interface Marker extends CoordinatePair {
  color: NeonColor;
}

/*
Component to display the map with answers, take in user guesses, and display the correct answer
Has info bar and submit button above map
*/
interface GeoguessMapProps {
  puzzle: Puzzle;
  gmapsKey: string;
  players: string[];
  onSubmitGuess: (guesses: CoordinatePair[]) => void; // Now accepts an array of guesses
  completedRoundRecords?: GeoGameRoundRecord[]; // Now an array of records corresponding to each player
  startingPlayerIdx: number;
  hiddenGemsMode?: boolean;
}
const GeoguessMap = ({
  puzzle,
  gmapsKey,
  players,
  onSubmitGuess,
  completedRoundRecords,
  startingPlayerIdx,
  hiddenGemsMode,
}: GeoguessMapProps) => {
  const [userGuesses, setUserGuesses] = React.useState<(Marker | null)[]>([]);
  const [submitted, setSubmitted] = React.useState(false);
  const [currentPickingPlayer, setCurrentPickingPlayer] = React.useState(0);

  // set currentPickingPlayer to startingPlayerIdx
  useEffect(() => {
    setCurrentPickingPlayer(startingPlayerIdx);
  }, [startingPlayerIdx]);

  // Handle user guess, setting based on currentPickingPlayer
  const handleGuess = useCallback(
    (guess: CoordinatePair) => {
      if (!submitted && currentPickingPlayer >= 0) {
        setUserGuesses((prevGuesses) => {
          const newGuesses = [...prevGuesses];
          newGuesses[currentPickingPlayer] = {
            latitude: guess.latitude,
            longitude: guess.longitude,
            color: PLAYER_IDX_TO_COLOR[currentPickingPlayer],
          };
          // Multiplayer: Switch to next player that hasn't submitted a guess
          if (players.length > 1) {
            const nextPlayerIdx =
              newGuesses.length < players.length
                ? newGuesses.length
                : newGuesses.findIndex(
                    (guess) => guess === null || guess === undefined
                  );
            if (nextPlayerIdx >= 0) setCurrentPickingPlayer(nextPlayerIdx); // Switch to next player, unless everyone has guessed, in which case stay
          }
          return newGuesses;
        });
      }
    },
    [currentPickingPlayer, players.length, submitted]
  );

  const handleSubmitted = () => {
    // ensure that all players have submitted guesses (no nulls)
    if (userGuesses.every((guess) => guess !== null)) {
      onSubmitGuess(userGuesses as Marker[]);
      setSubmitted(true);
      setCurrentPickingPlayer(0);
    }
  };

  useEffect(() => {
    if (completedRoundRecords && completedRoundRecords.length > 0) {
      setSubmitted(true);
    } else {
      setSubmitted(false);
      setUserGuesses([]);
    }
  }, [completedRoundRecords]);

  return (
    <>
      <div className="w-full flex justify-center mb-2">
        {!submitted && (
          <Button
            onClick={handleSubmitted}
            disabled={
              userGuesses.filter((g) => g).length < players.length || submitted
            }
            className="mb-2"
            {...(hiddenGemsMode && {
              startIcon: (
                <img
                  src={hiddenGemsLogoStatic}
                  alt="Hidden Gems logo"
                  className="w-12 h-12"
                />
              ),
            })}
          >
            Pin it!
          </Button>
        )}
        {submitted && completedRoundRecords && (
          <div className="flex flex-col items-center space-y-2">
            {hiddenGemsMode ? (
              <HiddenGemsOneLiner puzzle={completedRoundRecords[0].puzzle} />
            ) : (
              <ArtistOneLiner puzzle={completedRoundRecords[0].puzzle} />
            )}
            <div className="flex flex-row space-x-4">
              {players.map((player, i) => (
                <div key={player} className="flex flex-col items-center">
                  <NeonText color={PLAYER_IDX_TO_COLOR[i]}>{player}</NeonText>
                  <span className="text-lg">
                    +{completedRoundRecords[i].points} points
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {/* Player guess indicator
        The player's name is clickable to switch to that player's turn
      */}
      {!submitted && players.length > 1 && (
        <div className="flex flex-row space-x-4 items-start mb-1">
          {players.map((player, i) => (
            <Button
              key={player}
              color="transparent"
              onClick={() => setCurrentPickingPlayer(i)}
              className={`h-full ${
                i === currentPickingPlayer ? "border border-gray-400" : ""
              }`}
            >
              <div className="flex flex-col items-center">
                <NeonText color={PLAYER_IDX_TO_COLOR[i]}>{player}</NeonText>
                {userGuesses[i] && <CheckIcon size="lg" />}
              </div>
            </Button>
          ))}
        </div>
      )}
      <MapComponent
        gmapsKey={gmapsKey}
        markers={
          completedRoundRecords
            ? completedRoundRecords.map((record, idx) => ({
                latitude: record.guess.latitude,
                longitude: record.guess.longitude,
                color: PLAYER_IDX_TO_COLOR[idx],
              }))
            : [...(userGuesses.filter((guess) => guess !== null) as Marker[])]
        }
        answerMarker={
          completedRoundRecords
            ? {
                latitude: completedRoundRecords[0].puzzle.latitude,
                longitude: completedRoundRecords[0].puzzle.longitude,
                color: NeonColor.GREEN,
              }
            : undefined
        }
        handleGuess={handleGuess}
      />
    </>
  );
};

// Define props type if you need to pass any props to the component
interface MapComponentProps {
  gmapsKey: string;
  markers: Marker[];
  answerMarker?: Marker;
  handleGuess: (guess: Marker) => void;
}

const MapComponent: React.FC<MapComponentProps> = ({
  gmapsKey,
  markers,
  answerMarker,
  handleGuess,
}) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map | null>(null);
  const markersRef: MutableRefObject<
    Array<google.maps.Marker | google.maps.Polyline>
  > = useRef([]);

  const clearMarkers = () => {
    markersRef.current.forEach((marker) => marker.setMap(null));
    markersRef.current = [];
  };

  /* initialize map */
  useEffect(() => {
    const loader = new Loader({
      apiKey: gmapsKey,
      version: "weekly",
    });

    loader.load().then(async () => {
      if (mapRef.current) {
        const _map = new google.maps.Map(mapRef.current, {
          gestureHandling: "greedy",
          zoom: 2,
          center: { lat: 0, lng: 0 },
          disableDefaultUI: true, // a way to quickly hide all controls
          // You can design styles at https://mapstyle.withgoogle.com/
          styles: [
            {
              elementType: "geometry",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#212121",
                },
              ],
            },
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "administrative.country",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#9e9e9e",
                },
              ],
            },
            {
              featureType: "administrative.land_parcel",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "on",
                },
              ],
            },
            {
              featureType: "administrative.locality",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#bdbdbd",
                },
              ],
            },
            {
              featureType: "administrative.neighborhood",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "poi.business",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "geometry",
              stylers: [
                {
                  color: "#181818",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "poi.park",
              elementType: "labels.text.stroke",
              stylers: [
                {
                  color: "#1b1b1b",
                },
              ],
            },
            {
              featureType: "road",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "geometry.fill",
              stylers: [
                {
                  color: "#2c2c2c",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#8a8a8a",
                },
              ],
            },
            {
              featureType: "road.arterial",
              elementType: "geometry",
              stylers: [
                {
                  color: "#373737",
                },
              ],
            },
            {
              featureType: "road.highway",
              elementType: "geometry",
              stylers: [
                {
                  color: "#3c3c3c",
                },
              ],
            },
            {
              featureType: "road.highway.controlled_access",
              elementType: "geometry",
              stylers: [
                {
                  color: "#4e4e4e",
                },
              ],
            },
            {
              featureType: "road.local",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#616161",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#757575",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "geometry",
              stylers: [
                {
                  color: "#000000",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "water",
              elementType: "labels.text.fill",
              stylers: [
                {
                  color: "#3d3d3d",
                },
              ],
            },
          ],
        });

        _map.addListener("click", (e: any) => {
          handleGuess({
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
            color: NeonColor.PINK,
          });
        });

        setMap(_map);
      }
    });
  }, [gmapsKey, handleGuess]);

  /* add location pins to map */
  useEffect(() => {
    const addMarkersAndDrawLine = (markers: Marker[]) => {
      clearMarkers();
      let bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        if (!marker) return;
        const svgIcon = {
          url:
            "data:image/svg+xml;charset=UTF-8," +
            encodeURIComponent(getCustomSVG(marker.color)),
          scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
        };

        const position = new google.maps.LatLng(
          marker.latitude,
          marker.longitude
        );
        const mapMarker = new google.maps.Marker({
          position,
          map,
          icon: svgIcon,
        });

        markersRef.current.push(mapMarker);
        bounds.extend(position);
      });

      // add answer marker if provided
      if (answerMarker) {
        const svgIcon = {
          url:
            "data:image/svg+xml;charset=UTF-8," +
            encodeURIComponent(getCustomSVG(answerMarker.color)),
          scaledSize: new google.maps.Size(30, 30), // Adjust size as needed
        };

        const position = new google.maps.LatLng(
          answerMarker.latitude,
          answerMarker.longitude
        );
        const mapMarker = new google.maps.Marker({
          position,
          map,
          icon: svgIcon,
        });

        markersRef.current.push(mapMarker);
        bounds.extend(position);
      }

      if (answerMarker) {
        // Auto-zoom and center map
        map?.fitBounds(bounds);

        // Draw a white dotted line between each marker and the answerMarker
        markers.map((marker) => {
          const polyline = new google.maps.Polyline({
            path: [
              {
                lat: marker.latitude,
                lng: marker.longitude,
              },
              {
                lat: answerMarker.latitude,
                lng: answerMarker.longitude,
              },
            ],
            geodesic: true,
            strokeColor: "#FFFFFF",
            strokeOpacity: 0.0,
            strokeWeight: 2,
            map: map,
            icons: [
              {
                icon: {
                  path: "M 0,-1 0,1",
                  strokeOpacity: 1,
                  scale: 4,
                },
                offset: "0",
                repeat: "20px",
              },
            ],
          });
          markersRef.current.push(polyline);
          return polyline;
        });
      }
    };

    if (map && markers) {
      addMarkersAndDrawLine(markers);
    }
  }, [answerMarker, map, markers]);

  return <div className="w-full h-full" ref={mapRef} />;
};

/* Helper function to get the custom SVG code for a given color */
function getCustomSVG(color: NeonColor) {
  const neonColorToHex: { [key in NeonColor]: string } = {
    pink: "#ec4899",
    blue: "#2563eb",
    green: "#bbf7d0",
    orange: "#fdba74",
    red: "#dd3333",
    yellow: "#ffff99",
    white: "#ffffff",
  };

  const customSVG = `
<svg width="30" height="30" viewBox="-10 -10 313.334 313.334" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">
    <defs>
        <filter id="shadow" x="-100%" y="-100%" width="300%" height="300%">
            <feDropShadow dx="0" dy="0" stdDeviation="10" flood-color="#ffffff" flood-opacity="0.9"/>
        </filter>
    </defs>
    <g filter="url(#shadow)">
        <path style="fill:none; stroke:${neonColorToHex[color]}; stroke-width:10; stroke-linecap:round; stroke-linejoin:round;" d="M146.667,0C94.903,0,52.946,41.957,52.946,93.721c0,22.322,7.849,42.789,20.891,58.878
            c4.204,5.178,11.237,13.331,14.903,18.906c21.109,32.069,48.19,78.643,56.082,116.864c1.354,6.527,2.986,6.641,4.743,0.212
            c5.629-20.609,20.228-65.639,50.377-112.757c3.595-5.619,10.884-13.483,15.409-18.379c6.554-7.098,12.009-15.224,16.154-24.084
            c5.651-12.086,8.882-25.466,8.882-39.629C240.387,41.962,198.43,0,146.667,0z M146.667,144.358
            c-28.892,0-52.313-23.421-52.313-52.313c0-28.887,23.421-52.307,52.313-52.307s52.313,23.421,52.313,52.307
            C198.98,120.938,175.559,144.358,146.667,144.358z"/>
    </g>
</svg>
`;
  return customSVG;
}

export default GeoguessMap;
