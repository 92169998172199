import continentsDataRaw from "./rumble_classic_continents.json";

export enum Continent {
  Africa = "Africa",
  Asia = "Asia",
  Europe = "Europe",
  NorthAmerica = "North America",
  SouthAmerica = "South America",
  Oceania = "Oceania",
}

interface Continents {
  [key: string]: Continent;
}

export const continentsData = continentsDataRaw as Continents;

// Create a mapped type for the continent counts
type ContinentCounts = {
  [key in Continent]: number;
};

// Initialize the continent counts dictionary
const continentCounts: ContinentCounts = {
  [Continent.Africa]: 0,
  [Continent.Asia]: 0,
  [Continent.Europe]: 0,
  [Continent.NorthAmerica]: 0,
  [Continent.SouthAmerica]: 0,
  [Continent.Oceania]: 0,
};

// Iterate through the JSON data and count the occurrences of each continent
for (const id in continentsData) {
  if (continentsData.hasOwnProperty(id)) {
    const continent = continentsData[id];
    continentCounts[continent]++;
  }
}

export const continentTotals = continentCounts;
