import React, { useState, useEffect } from "react";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { spotifyApi } from "../services/spotify";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../components/basic/themes";
import SpotifyTrackPlayer from "../components/SpotifyTrackPlayer";
import Button from "@mui/material/Button";
import { worldCities } from "../data/world_cities";
import { addPuzzleInput } from "../services/puzzle_admin";

interface Artist {
  id: string;
  name: string;
  images: { url: string }[];
}

interface Track {
  id: string;
  name: string;
}

const PuzzlePicker: React.FC = () => {
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFetching, setSearchFetching] = useState(false);
  const [artists, setArtists] = useState<Artist[]>([]);
  const [selectedArtist, setSelectedArtist] = useState<Artist | null>(null);
  const [topTracks, setTopTracks] = useState<Track[]>([]);
  const [currentSongId, setCurrentSongId] = useState<string | null>(null); // New state for tracking the selected song ID

  // state variables for artist information
  const [artistLocation, setArtistLocation] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [biography, setBiography] = useState("");
  const [youtubeChannel, setYoutubeChannel] = useState("");
  const [artistStyle, setArtistStyle] = useState("");
  const [artistCuriosity, setArtistCuriosity] = useState("");
  const [authorNote, setAuthorNote] = useState("");
  const [addToDaily, setAddToDaily] = useState(true);
  const [addToRumble, setAddToRumble] = useState(false);

  // Function to check if all required fields are filled
  const allRequiredFieldsFilled = () => {
    return (
      artistLocation &&
      latitude &&
      longitude &&
      biography &&
      currentSongId &&
      selectedArtist
    );
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentSongId || !selectedArtist) return;
    // Submit to DDB
    addPuzzleInput({
      artist_spotify_id: selectedArtist?.id,
      artist_image: selectedArtist?.images[0].url,
      artist_name: selectedArtist?.name,
      artist_bio: biography,
      artist_style: artistStyle,
      artist_curiosity: artistCuriosity,
      authors_note: authorNote,
      artist_location: artistLocation,
      artist_youtube_link: youtubeChannel,
      song_spotify_id: currentSongId,
      latitude: parseFloat(latitude),
      longitude: parseFloat(longitude),
      add_to_daily: addToDaily,
      // in_rumble_classic_set: addToRumble, // classic set is 100 songs for fundraiser
      in_rumble_full_set: addToRumble,
    })
      .then(() => {
        setStatusMessage(`Artist "${selectedArtist?.name}" submitted!`);
        // Reset the form
        setArtistLocation("");
        setLatitude("");
        setLongitude("");
        setBiography("");
        setYoutubeChannel("");
        setArtistStyle("");
        setArtistCuriosity("");
        setAuthorNote("");
        setSelectedArtist(null);
      })
      .catch((err) => {
        console.error("Error submitting puzzle input: ", err);
        setStatusMessage(`${err}`);
        // Scroll to the top of the page to ensure the user sees the error message
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      });
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm) {
        spotifyApi({
          path: "/search",
          method: "GET",
          queryParams: { q: searchTerm, type: "artist" },
        }).then((data: any) => {
          setArtists(data?.artists?.items || []);
          setSearchFetching(false);
          setStatusMessage("");
        });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (selectedArtist) {
      spotifyApi({
        path: `/artists/${selectedArtist.id}/top-tracks`,
        method: "GET",
        queryParams: { country: "US" }, // Adjust country code as needed
      }).then((data: any) => {
        setTopTracks(data?.tracks || []);
        setCurrentSongId(null); // Reset the current song ID when a new artist is selected
      });
    }
  }, [selectedArtist]);

  /* props for city selector */
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 10,
  });
  const cities = worldCities.map((city) => ({
    label: `${city.city_ascii}, ${
      city.country === "United States" && city.admin_name
        ? `${city.admin_name}, `
        : ""
    }${city.country}`,
    latitude: city.lat,
    longitude: city.lng,
  }));

  return (
    <div className="p-4">
      {statusMessage && (
        /* Color the font red if it has "error" in the message, otherwise white */
        <div
          className={`font-semibold text-lg pb-8
          ${
            statusMessage.toLowerCase().includes("error")
              ? "text-red-400"
              : "text-white"
          }
        `}
        >
          {statusMessage}
        </div>
      )}
      <div className="flex flex-row items-center space-x-8">
        <ThemeProvider theme={darkTheme}>
          <Autocomplete
            id="artist-search"
            options={artists}
            getOptionLabel={(option) => option.name}
            style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Artists"
                variant="outlined"
              />
            )}
            onInputChange={(_, newInputValue) => {
              setSearchTerm(newInputValue);
              setSearchFetching(true);
            }}
            onChange={(_, newValue) => {
              setSelectedArtist(newValue);
            }}
            loading={searchFetching}
            loadingText={"Searching..."}
          />
        </ThemeProvider>
        <div>
          <SpotifyTrackPlayer
            width={"400"}
            height={"100"}
            uri={currentSongId ? `spotify:track:${currentSongId}` : undefined}
            onReady={(controller: any) => {
              setTimeout(() => {
                controller?.play();
              }, 500); // autoplay on load
            }}
          />
        </div>
      </div>
      {selectedArtist && (
        <div>
          <div className="flex flex-row items-center space-x-12">
            <div>
              <h3 className="text-lg font-bold">{selectedArtist.name}</h3>
              {selectedArtist.images[0] && (
                <img
                  src={selectedArtist.images[0].url}
                  alt={selectedArtist.name}
                  className="w-32 h-32"
                />
              )}
            </div>
            <div className="mt-2">
              <h4 className="text-md font-semibold">Top Tracks:</h4>
              <ul>
                {topTracks.map((track) => (
                  <li
                    key={track.id}
                    className="cursor-pointer"
                    onClick={() => setCurrentSongId(track.id)}
                  >
                    <span
                      className={`${
                        currentSongId === track.id
                          ? "text-blue-200 font-semibold"
                          : ""
                      }`}
                    >
                      {track.name}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      {selectedArtist && (
        <form onSubmit={handleSubmit} className="mt-4">
          <ThemeProvider theme={darkTheme}>
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row items-center space-x-4">
                <label>
                  <input
                    type="checkbox"
                    checked={addToDaily}
                    onChange={(e) => setAddToDaily(e.target.checked)}
                  />
                  &nbsp;Add to Daily Puzzles
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={addToRumble}
                    onChange={(e) => setAddToRumble(e.target.checked)}
                  />
                  &nbsp;Add to Rumble Puzzles
                </label>
              </div>
              <Autocomplete
                disablePortal
                id="city-selector"
                filterOptions={filterOptions}
                options={cities} // make user have to put in at least 1 char
                sx={{
                  width: {
                    xs: 250,
                    sm: 300,
                  },
                }}
                renderInput={(params) => <TextField {...params} label="City" />}
                onChange={(e, newValue: any) => {
                  setArtistLocation(newValue.label);
                  setLatitude(newValue.latitude);
                  setLongitude(newValue.longitude);
                }}
              />
              <TextField
                label="Latitude"
                variant="outlined"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                required
              />
              <TextField
                label="Longitude"
                variant="outlined"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                required
              />
              <TextField
                label="YouTube Channel"
                variant="outlined"
                value={youtubeChannel}
                onChange={(e) => setYoutubeChannel(e.target.value)}
              />
              <TextField
                label="Biography"
                variant="outlined"
                value={biography}
                onChange={(e) => setBiography(e.target.value)}
                required
                multiline
              />
              <TextField
                label="Artist Style"
                variant="outlined"
                value={artistStyle}
                onChange={(e) => setArtistStyle(e.target.value)}
                multiline
              />
              <TextField
                label="Artist Curiosity"
                variant="outlined"
                value={artistCuriosity}
                onChange={(e) => setArtistCuriosity(e.target.value)}
                multiline
              />
              <TextField
                label="Author's Note"
                variant="outlined"
                value={authorNote}
                onChange={(e) => setAuthorNote(e.target.value)}
              />
              <Button
                type="submit"
                variant="contained"
                disabled={!allRequiredFieldsFilled()}
              >
                Submit
              </Button>
            </div>
          </ThemeProvider>
        </form>
      )}
    </div>
  );
};

export default PuzzlePicker;
