export interface CoordinatePair {
  latitude: number;
  longitude: number;
}

// Check distance from guess to country
export const getDistanceAndBearing = (
  guess: CoordinatePair,
  answer: CoordinatePair
): { distance: number; bearing: number } => {
  // Calculate distance between both points
  const distance = getDistanceFromLatLonInKm(
    guess.latitude,
    guess.longitude,
    answer.latitude,
    answer.longitude
  );

  // Calculate bearing between both points
  const bearing = getBearing(
    guess.latitude,
    guess.longitude,
    answer.latitude,
    answer.longitude
  );

  return { distance, bearing };
};

// Degrees to radians
const deg2rad = (deg: number): number => {
  return deg * (Math.PI / 180);
};

// Radians to degrees
const rad2deg = (radians: number): number => {
  return (radians * 180) / Math.PI;
};

// Calculate distance of two coordinates, using rhumb line (loxodrome) distance,
// which is a straight line on a Mercator projection. Better aligns with user
// expectations on a 2D map.
const getDistanceFromLatLonInKm = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): number => {
  const R = 6371; // Radius of the earth in km
  const lat1Rad = deg2rad(lat1);
  const lat2Rad = deg2rad(lat2);
  const dLatRad = deg2rad(lat2 - lat1);
  const dLonRad = deg2rad(lon2 - lon1);

  // Correct for crossing 180° meridian
  let dLonAdjusted = dLonRad;
  if (Math.abs(dLonAdjusted) > Math.PI) {
    dLonAdjusted =
      dLonAdjusted > 0
        ? -(2 * Math.PI - dLonAdjusted)
        : 2 * Math.PI + dLonAdjusted;
  }

  // Rhumb line distance calculation
  const dPhi = Math.log(
    Math.tan(lat2Rad / 2 + Math.PI / 4) / Math.tan(lat1Rad / 2 + Math.PI / 4)
  );
  let q = Math.abs(dPhi) > 1e-10 ? dLatRad / dPhi : Math.cos(lat1Rad); // E-W course becomes ill-conditioned with 0/0

  // Calculate the total distance
  const distance =
    Math.sqrt(dLatRad * dLatRad + q * q * dLonAdjusted * dLonAdjusted) * R;

  return distance;
};

// Calculate bearing of two coordinates, using rhumb line (loxodrome) distance,
// which is a straight line on a Mercator projection. Better aligns with user
// expectations on a 2D map.
const getBearing = (
  startLat: number,
  startLng: number,
  destLat: number,
  destLng: number
): number => {
  // Convert latitudes and longitudes from degrees to radians
  const startLatRad = deg2rad(startLat);
  const startLngRad = deg2rad(startLng);
  const destLatRad = deg2rad(destLat);
  const destLngRad = deg2rad(destLng);

  // Difference in longitudes
  let dLngRad = destLngRad - startLngRad;

  // Correct for crossing 180° meridian
  if (Math.abs(dLngRad) > Math.PI)
    dLngRad = dLngRad > 0 ? -(2 * Math.PI - dLngRad) : 2 * Math.PI + dLngRad;

  // Calculate the rhumb line distance
  const dPhi = Math.log(
    Math.tan(destLatRad / 2 + Math.PI / 4) /
      Math.tan(startLatRad / 2 + Math.PI / 4)
  );

  // Calculate the bearing, convert from radians to degrees, and normalize
  let bearing = rad2deg(Math.atan2(dLngRad, dPhi));
  if (bearing < 0) {
    bearing += 360; // Normalize the bearing
  }

  return bearing;
};

// EDIT: Below distance & bearing functions a spherical model of the Earth which is confusing to users when
// it tells you to go over/near the poles.

// Haversine formula to get distance between two points (Retrieved from stack overflow https://stackoverflow.com/questions/27928/calculate-distance-between-two-latitude-longitude-points-haversine-formula)
// const getDistanceFromLatLonInKm = (
//   lat1: number,
//   lon1: number,
//   lat2: number,
//   lon2: number
// ): number => {
//   const R = 6371; // Radius of the earth in km
//   const dLat = deg2rad(lat2 - lat1);
//   const dLon = deg2rad(lon2 - lon1);
//   const a =
//     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
//     Math.cos(deg2rad(lat1)) *
//       Math.cos(deg2rad(lat2)) *
//       Math.sin(dLon / 2) *
//       Math.sin(dLon / 2);
//   const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
//   const d = R * c; // Distance in km
//   return d;
// };

// Calculate bearing of two coordinates (Retrieved from stack overflow https://stackoverflow.com/questions/46590154/calculate-bearing-between-2-points-with-javascript)
// const getBearing = (
//   startLatTmp: number,
//   startLngTmp: number,
//   destLatTmp: number,
//   destLngTmp: number
// ): number => {
//   const destLat = deg2rad(startLatTmp);
//   const destLng = deg2rad(startLngTmp);
//   const startLat = deg2rad(destLatTmp);
//   const startLng = deg2rad(destLngTmp);

//   const y = Math.sin(destLng - startLng) * Math.cos(destLat);
//   const x =
//     Math.cos(startLat) * Math.sin(destLat) -
//     Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
//   let brng = Math.atan2(y, x);

//   brng = rad2deg(brng);
//   return (brng + 360) % 360;
// };
