import React from "react";
import styled, { keyframes } from "styled-components";
import { COMPLETIONS_TO_DOLLARS } from "./utils";

interface FundraiserThermometerProps {
  value: number;
}

const ThermometerContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  height: 10px;
  background-color: #333333;
  border-radius: 15px;
  overflow: hidden;
`;

const fillAnimation = (value: number) => keyframes`
  from {
    width: 0;
  }
  to {
    width: ${value}%;
  }
`;

const startHue = 220;
const endHue = 370;
const calculateHue = (value: number) => {
  return startHue + ((endHue - startHue) * value) / 100;
};

const ThermometerFill = styled.div<{ value: number }>`
  height: 100%;
  background: linear-gradient(
    to right,
    hsla(${startHue}, 80%, 50%, 0.7),
    hsla(${(props) => calculateHue(props.value)}, 60%, 50%, 0.7)
  );
  width: ${(props) => props.value}%;
  animation: ${(props) => fillAnimation(props.value)} 2s ease-out forwards;
`;

// const ThermometerFill = styled.div<{ value: number }>`
//   height: 100%;
//   background: linear-gradient(
//     to right,
//     rgba(0, 0, 255, 0.5),
//     rgba(255, 0, 0, 0.5)
//   );
//   width: ${(props) => props.value}%;
//   animation: ${(props) => fillAnimation(props.value)} 2s ease-out forwards;
// `;

const MarkerContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Marker = styled.div`
  position: relative;
  text-align: center;
  width: 0;
`;

const FundraiserThermometer: React.FC<FundraiserThermometerProps> = ({
  value,
}) => {
  const getColor = (value: number) => {
    const red = Math.min(255, Math.floor((value / 100) * 255));
    const blue = 255 - red;
    return `rgb(${red}, 0, ${blue})`;
  };

  return (
    <div className="w-80 sm:w-[500px] h-12 relative">
      <ThermometerContainer>
        <ThermometerFill
          value={value}
          style={{ backgroundColor: getColor(value) }}
        />
      </ThermometerContainer>
      <MarkerContainer>
        {COMPLETIONS_TO_DOLLARS.map((pair) => (
          <Marker key={pair.completions}>
            <div className="flex flex-col items-center">
              <div className="text-sm">{pair.completions}</div>
              <div className="text-sm text-gray-400 italic">
                ${pair.dollars}
              </div>
            </div>
          </Marker>
        ))}
      </MarkerContainer>
    </div>
  );
};

export default FundraiserThermometer;
