import React from "react";

interface DividerProps {
  className?: string;
}

const Divider: React.FC<DividerProps> = ({ className = "" }) => {
  const defaultClasses = "w-full border-t border-gray-600";

  return <div className={`${defaultClasses} ${className}`}></div>;
};

export default Divider;
