import React from "react";
import { NeonColor, neonColors } from "../basic/NeonText";

export interface StrokeIconProps {
  size: "2xl" | "xl" | "lg" | "md" | "sm" | "xs" | "2xs";
  className?: string;
  neonColor?: NeonColor;
  path: string;
}

export const sizeClasses = {
  "2xl": "h-12 w-12",
  xl: "h-10 w-10",
  lg: "h-8 w-8",
  md: "h-6 w-6",
  sm: "h-4 w-4",
  xs: "h-3 w-3",
  "2xs": "h-2 w-2",
};

const StrokeIcon: React.FC<StrokeIconProps> = ({
  size,
  className = "",
  neonColor,
  path,
}) => {
  const sizeClass = sizeClasses[size];
  const neonClass = neonColor
    ? `drop-shadow-neon ${neonColors[neonColor].tailwindColorClass}`
    : "";

  return (
    <svg
      className={`${sizeClass} ${neonClass} ${className} stroke-current`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={
        neonColor
          ? ({
              "--tw-drop-shadow-color": neonColors[neonColor].shadowColorHex,
            } as React.CSSProperties)
          : {}
      }
    >
      <path
        d={path}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StrokeIcon;
