import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface PieChartProps {
  perfect: number;
  total: number;
  color: string;
  label: string;
  width: number;
  height: number;
}

const PieChart: React.FC<PieChartProps> = ({
  perfect,
  total,
  color,
  label,
  width,
  height,
}) => {
  const chartData = {
    labels: [label, "Remaining"],
    datasets: [
      {
        data: [perfect, total - perfect],
        backgroundColor: [color, "#111111"],
        borderWidth: 10, // Thinner ring line
        borderRadius: 5, // Rounded end of the ring line
        borderColor: "transparent", // Remove the white outline/stroke
      },
    ],
  };

  const options = {
    cutout: "70%", // This creates the ring effect
    plugins: {
      tooltip: {
        enabled: false, // Disable tooltips
      },
      legend: {
        display: false, // Hide the legend
      },
    },
  };

  return (
    <div style={{ position: "relative", width, height }}>
      <Pie data={chartData} options={options} />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          fontSize: "0.9rem",
        }}
      >
        {perfect} / {total}
      </div>
    </div>
  );
};

export default PieChart;
