import React, { useState, useRef, useEffect } from "react";
import MenuIcon from "./Icon/MenuIcon";

interface MenuItem {
  label: string;
  onClick: () => void;
}

interface HamburgerMenuProps {
  items: MenuItem[][];
  size: "2xl" | "xl" | "lg" | "md" | "sm" | "xs" | "2xs";
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ items, size }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={menuRef}>
      <button onClick={toggleMenu} className="text-gray-500">
        <MenuIcon size={size} />
      </button>
      {isOpen && (
        <div className="absolute left-0 w-48 mt-0 bg-color-black-gradient border border-gray-200 rounded-sm shadow-lg z-10">
          {items.map((section, sectionIndex) => (
            <div key={sectionIndex}>
              {section.map((item, itemIndex) => (
                <button
                  key={itemIndex}
                  onClick={() => {
                    item.onClick();
                    setIsOpen(false);
                  }}
                  className="block w-full px-4 py-2 text-left text-gray-400 hover:bg-gray-100"
                >
                  {item.label}
                </button>
              ))}
              {sectionIndex < items.length - 1 && (
                <div className="border-t border-gray-400 mx-2"></div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
