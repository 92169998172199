import { Spotify } from "react-spotify-embed";

function isValidSpotifyId(id: string) {
  const regex = /^[0-9a-zA-Z]{22}$/;
  return regex.test(id);
}

/*
Spotify oEmbed player that can load a song and also provide
options to blur or not blur out the song info. Always leaves
the play/pause control unblurred and usable. Automatically
fills to full-width and is approximately 80px tall.

We have this component b/c the iFrame player is too buggy.
*/
interface SpotifyEmbedPlayerProps {
  trackUri: string;
  blurred: boolean;
}
const SpotifyEmbedPlayer: React.FC<SpotifyEmbedPlayerProps> = ({
  trackUri,
  blurred,
}) => {
  if (!isValidSpotifyId(trackUri)) {
    console.log(`Invalid Spotify ID: ${trackUri}`);
    return null;
  }

  return (
    <div className="flex flex-col space-y-2 min-w-[254px] max-w-[320px] mx-auto">
      <div className="relative w-full h-20 flex-grow">
        <div className="absolute inset-0">
          <Spotify wide link={`https://open.spotify.com/track/${trackUri}`} />
        </div>
        {blurred && (
          <div className="absolute inset-0 w-[85%] blurry-overlay rounded-lg overflow-hidden" />
        )}
      </div>
      {/* Option to switch to neon controller */}
      {/* <Button
        onClick={() => {
          setPlayerToUseCookie("iframe");
        }}
        color="transparent"
        startIcon={<RefreshIcon size="sm" />}
      >
        Switch to neon controller
      </Button> */}
    </div>
  );
};

export default SpotifyEmbedPlayer;
