// Return appropriate color emoji based on km distance
// Green: 0-10km
// Yellow: 10-1000km
// Orange: 1000-5000km

import { NeonColor } from "../basic/NeonText";

// Red: 3000+km
export const distanceToEmoji = (distance: number) => {
  if (distance <= 10) {
    return "🟩";
  } else if (distance <= 1000) {
    return "🟨";
  } else if (distance <= 5000) {
    return "🟧";
  } else {
    return "🟥";
  }
};

// Return appropriate color Neon Sign Square based on km distance
export const distanceToNeonColor = (distance: number) => {
  if (distance <= 10) {
    return NeonColor.GREEN;
  } else if (distance <= 1000) {
    return NeonColor.YELLOW;
  } else if (distance <= 5000) {
    return NeonColor.ORANGE;
  } else {
    return NeonColor.RED;
  }
};

// Return appropriate arrow emoji based on degrees bearing
export const bearingToEmoji = (bearing: number) => {
  if (bearing >= 337.5 || bearing < 22.5) {
    return "⬆️";
  } else if (bearing >= 22.5 && bearing < 67.5) {
    return "↗️";
  } else if (bearing >= 67.5 && bearing < 112.5) {
    return "➡️";
  } else if (bearing >= 112.5 && bearing < 157.5) {
    return "↘️";
  } else if (bearing >= 157.5 && bearing < 202.5) {
    return "⬇️";
  } else if (bearing >= 202.5 && bearing < 247.5) {
    return "↙️";
  } else if (bearing >= 247.5 && bearing < 292.5) {
    return "⬅️";
  } else if (bearing >= 292.5 && bearing < 337.5) {
    return "↖️";
  } else {
    return "❓";
  }
};

// Convert bearing to tailwind rotation class for ArrowUp icon
// Classnames start with `rotate` or `-rotate`, e.g. `-rotate-45` or `rotate-90`, up to 180
export const bearingToRotationClass = (bearing: number) => {
  if (bearing >= 337.5 || bearing < 22.5) {
    return "rotate-0";
  } else if (bearing >= 22.5 && bearing < 67.5) {
    return "rotate-45";
  } else if (bearing >= 67.5 && bearing < 112.5) {
    return "rotate-90";
  } else if (bearing >= 112.5 && bearing < 157.5) {
    return "rotate-135";
  } else if (bearing >= 157.5 && bearing < 202.5) {
    return "rotate-180";
  } else if (bearing >= 202.5 && bearing < 247.5) {
    return "-rotate-135";
  } else if (bearing >= 247.5 && bearing < 292.5) {
    return "-rotate-90";
  } else if (bearing >= 292.5 && bearing < 337.5) {
    return "-rotate-45";
  } else {
    return "";
  }
};

// Convert digits 0-9 to emoji
// Convert multiple digits to multiple emojis
export const numberToEmoji = (number: number) => {
  const digits = number.toString().split("");
  return digits
    .map((digit) => {
      switch (digit) {
        case "0":
          return "0️⃣";
        case "1":
          return "1️⃣";
        case "2":
          return "2️⃣";
        case "3":
          return "3️⃣";
        case "4":
          return "4️⃣";
        case "5":
          return "5️⃣";
        case "6":
          return "6️⃣";
        case "7":
          return "7️⃣";
        case "8":
          return "8️⃣";
        case "9":
          return "9️⃣";
        default:
          return "❓";
      }
    })
    .join("");
};
