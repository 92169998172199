import { useEffect, useState } from "react";
import {
  getHiddenGemsLeaderboard,
  RumbleLeaderboardEntry,
} from "../../services/rumble_scores";
import { useUsername } from "../../state/UsernameContext";
import Spinner from "../../components/Spinner";

const HiddenGemsLeaderboardPage = () => {
  const [entries, setEntries] = useState<RumbleLeaderboardEntry[]>([]);

  const fetchEntries = async () => {
    try {
      const _entries = await getHiddenGemsLeaderboard();
      setEntries(_entries);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <div className="flex flex-col items-center mt-8">
      <HiddenGemsLeaderboard entries={entries} />
    </div>
  );
};

interface HiddenGemsLeaderboardProps {
  entries: RumbleLeaderboardEntry[];
  editableIndices?: number[];
  onNameChange?: (index: number, newName: string) => void;
}

export const HiddenGemsLeaderboard = ({
  entries,
  editableIndices = [],
  onNameChange,
}: HiddenGemsLeaderboardProps) => {
  const { username } = useUsername();
  // for each of the editable indices, store a temporary state for the name
  // on blur, we update the name in the parent component
  const [editableNames, setEditableNames] = useState<string[]>([]);
  useEffect(() => {
    setEditableNames(entries.map((entry) => entry.display_name));
  }, [entries]);

  return (
    <div className="flex flex-col items-center w-80 sm:w-[500px]">
      <div className="text-2xl mb-4">Leaderboard</div>
      {editableIndices.length > 0 && onNameChange && (
        <div className="text-sm text-gray-400 mb-2">
          You made it to the leaderboard! Enter your name below to save your
          score.
        </div>
      )}
      {!entries?.length && <Spinner />}
      <table>
        <tbody>
          {entries.map((player, index) => (
            <tr
              key={player.display_name + player.date_utc.toString()}
              className={
                player.username && player.username === username
                  ? "font-semibold"
                  : ""
              }
            >
              <td
                className={`w-8 ${
                  player.username && player.username === username
                    ? "text-white"
                    : "text-gray-400"
                }`}
              >
                {index + 1}
              </td>
              <td
                className={`min-w-48 ${
                  player.username && player.username === username
                    ? "text-white"
                    : "text-gray-400"
                }`}
              >
                {editableIndices.includes(index) && onNameChange ? (
                  <input
                    type="text"
                    value={editableNames[index]}
                    onChange={(e) =>
                      setEditableNames((prev) => {
                        const newNames = [...prev];
                        newNames[index] = e.target.value;
                        return newNames;
                      })
                    }
                    onBlur={(e) => onNameChange(index, e.target.value)}
                    className="bg-transparent border-b border-white focus:outline-none font-semibold text-white"
                  />
                ) : (
                  player.display_name
                )}
              </td>
              <td>{player.score}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HiddenGemsLeaderboardPage;
